/* eslint-disable */
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { appId, apiUrl, baseUrl, stripeCompany } from "../Helpers/Config";
import { validated } from "react-custom-validation";
import validator from "validator";
import cookie from "react-cookies";

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) || email === "" || email === null
    ? null
    : "This is not a valid email.";

const minLength = (pin, length) =>
  pin.length >= length ? null : "Password must be at least 6 characters.";

const areSame = (pin, rePin) =>
  pin === rePin ? null : "Password do not match.";

const isChecked = (terms) =>
  terms ? null : "Please accept terms and conditions";

function validationConfigSignup(props) {
  const {
    firstname,
    email,
    pin,
    rePin,
    mobile,
    terms,
    check_ref_code,
    terms1,
  } = props.fields;

  return {
    fields: [
      "firstname",
      "email",
      "pin",
      "rePin",
      "mobile",
      "terms",
      "check_ref_code",
    ],

    validations: {
      firstname: [[isEmpty, firstname]],
      email: [
        [isEmpty, email],
        [isEmail, email],
        /* [isUnique, email]*/
      ],
      pin: [[minLength, pin, 6]],
      rePin: {
        rules: [[areSame, pin, rePin]],
        fields: ["pin", "rePin"],
      },
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
        /* [isPhonenumerExist, mobile]*/
      ],
      terms: [[isChecked, terms]],
    },
  };
}

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Mobile Number.";

const isUnique = (email) => {
  var qs = require("qs");
  var postObject = {
    app_id: appId,
    type: "web",
    customer_email: email,
  };

  axios
    .post(apiUrl + "customer/email_exist", qs.stringify(postObject))
    .then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("spn-email-error").innerHTML = "";
      } else {
        document.getElementById("spn-email-error").innerHTML =
          '<span class="error">Email already exists</span>';
      }
    });
};

const isPhonenumerExist = (mobile) => {
  var qs = require("qs");
  var postObject = {
    app_id: appId,
    type: "web",
    customer_phone: mobile,
  };
  axios
    .post(apiUrl + "customer/phoneno_exist", qs.stringify(postObject))
    .then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("spn-mobile-error").innerHTML = "";
      } else {
        document.getElementById("spn-mobile-error").innerHTML =
          '<span class="error">Mobile number already exists</span>';
      }
    });
};

class Signup extends Component {
  constructor(props) {
    super(props);
  }

  closepopup(path) {
    window.open(
      baseUrl + path,
      "_blank" // <- This is what makes it open in a new window.
    );
    // const { history } = this.props;
    //history.push(path);
    //$.magnificPopup.close();
  }

  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  
  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    const spanStyle = {
      clear: "both",
    };
    let errMsgFirstName,
      errMsgEmail,
      errMsgPin,
      errMsgrePin,
      errMsgMobile,
      errMsgTerms;
    // errMsgTerms1;

    if (
      $validation.firstname.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if (
      $validation.email.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if (
      $validation.pin.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgPin = $validation.pin.show && (
        <span className="error">{$validation.pin.error.reason}</span>
      );
    }
    if (
      $validation.rePin.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgrePin = $validation.rePin.show && (
        <span className="error">{$validation.rePin.error.reason}</span>
      );
    }
    if (
      $validation.mobile.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }
    if (
      $validation.terms.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgTerms = $validation.terms.show && (
        <span className="error">{$validation.terms.error.reason}</span>
      );
    }
    // if (
    //   $validation.terms1.error.reason !== undefined &&
    //   fields.completeReg === "N"
    // ) {
    //   errMsgTerms1 = $validation.terms1.show && (
    //     <span className="error">{$validation.terms1.error.reason}</span>
    //   );
    // }

    return (
      <div className="popup-body">
        <h4>Enter Your Information</h4>
        <div className="form-group">
          <div
            className={
              fields.firstname !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>Name</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.firstname}
              {...$field("firstname", (e) =>
                onChange("firstname", e.target.value)
              )}
            />
            {errMsgFirstName}
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.mobile !== "" ? "focus-out focused" : "focus-out"}
          >
            <label>Enter Mobile Number</label>
            <input
              type="tel"
              className="form-control input-focus"
              pattern="\d*"
              value={fields.mobile}
              maxLength="8"
              onKeyPress={(e) => this.validateIntval(e)}
              {...$field("mobile", (e) => onChange("mobile", e.target.value))}
            />
            <div id="spn-mobile-error">{errMsgMobile}</div>
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.email !== "" ? "focus-out focused" : "focus-out"}
          >
            <label>Email Address</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            <div id="spn-email-error">{errMsgEmail}</div>
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.pin !== "" ? "focus-out focused" : "focus-out"}
          >
            <label>Password</label>
            <input
              type="password"
              className="form-control input-focus"
              value={fields.pin}
              {...$field("pin", (e) => onChange("pin", e.target.value))}
            />
            {errMsgPin}
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.rePin !== "" ? "focus-out focused" : "focus-out"}
          >
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control input-focus"
              value={fields.rePin}
              {...$field("rePin", (e) => onChange("rePin", e.target.value))}
            />
            {errMsgrePin}
          </div>
        </div>

        <div className="form-group check_refcode_cls">
          <div
            className={
              fields.check_ref_code != "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>Referral code ( Optional )</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.check_ref_code}
              {...$field("check_ref_code", (e) =>
                onChange("check_ref_code", e.target.value)
              )}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="custom_checkbox custom_checkbox_content check-left">
            {fields.terms}
            <input
              type="checkbox"
              id="terms"
              checked={fields.terms}
              {...$field(
                "terms",
                (e) => onChange("terms", e.target.value),
                null,
                false
              )}
            />{" "}
            <span></span>
            <div className="sign_reg">
              <p>
                By creating an account, I agree to Tigga Folks,&nbsp;
                <a
                  className="sign_reg_uline"
                  onClick={(e) => {
                    e.preventDefault();
                    this.closepopup("terms-and-conditions");
                  }}
                  href="terms-of-use"
                >
                  Terms &amp; Conditions.
                </a>
              </p>
            </div>
          </div>
          {errMsgTerms}
        </div>

        <div className="form-group">
          <div className="custom_checkbox custom_checkbox_content sec-checkbox check-left">
            <input
              type="checkbox"
              id="terms1"
              checked={fields.terms1}
              {...$field(
                "terms1",
                (e) => onChange("terms1", e.target.value),
                null,
                false
              )}
            />{" "}
            <span></span>
            <div className="sign_reg">
              <p>
                By signing up, i agree to receive communications about Food
                Barn’s services store events and matters via email and i have
                also acknowledge &amp; agreed to Food Barn’s{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="home-newletter-para-link"
                >
                  {" "}
                  Privacy Policy{" "}
                </a>{" "}
                and{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  className="home-newletter-para-link"
                >
                  {" "}
                  Terms &amp; Conditions{" "}
                </a>
              </p>
            </div>
          </div>
          {/* {errMsgTerms1} */}
        </div>

        <div className="form-group">
          <div className="login_pop_sub">
            <button
              type="button"
              className="button btn_black btn_minwid signup_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="form-group-11">
          <div className="controls single-link">
            <a href="#login-popup" className="open-popup-link">
              Back to login
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Signup = validated(validationConfigSignup)(Signup);

export default withRouter(Signup);
