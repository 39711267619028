/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import {
  reservationId,
  mediaUrl,
  pickupId,
  deliveryId,
  cateringId,
} from "../Helpers/Config";
import { timeToConv12 } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import noimage from "../../common/images/noimg-470x240.jpg";
import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";
var Parser = require("html-react-parser");
class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
    };
  }

  componentDidMount() {
    var availability =
      pickupId + ";" + deliveryId + ";" + reservationId + ";" + cateringId;
    // var availability = "";
    this.props.getAllOutlets(availability);
    this.props.getGlobalSettings();
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: nextProps.outlets[0].result_set,
        });
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  tConvert(time) {
    if (
      time !== "" &&
      typeof time !== undefined &&
      typeof time !== "undefined"
    ) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    }
  }

  allInOnepopup() {
    cookie.save("defaultAvilablityId", reservationId, { path: "/" });
    this.props.history.push("/reservation");
  }

  gotoContacus(outletId) {
    cookie.save("contusOutletId", outletId, { path: "/" });
    this.props.history.push("/contact-us");
  }

  callPhoneOptn(phoneTxt) {
    var resultTxt = "";
    if (phoneTxt !== "" && phoneTxt !== null) {
      if (phoneTxt.indexOf("+65") !== -1) {
        resultTxt = "tel:" + phoneTxt;
      } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
        resultTxt = "tel:+" + phoneTxt;
      } else {
        resultTxt = "tel:+65" + phoneTxt;
      }
    } else {
      resultTxt = "javascript:void(0);";
    }

    return resultTxt;
  }

  timingCnt() {
    return <span>Timing: 7 days a week | 10:30am - 7:00pm</span>;
  }

  getOutletData = (dataProp) => {
    if (dataProp) {
      var existOutlet = [];
      return dataProp.map((item, index) => {
        if (
          existOutlet.indexOf(item.outlet_id) < 0 &&
          item.outlet_id !== "321"
        ) {
          existOutlet.push(item.outlet_id);
          return (
            <li key={index}>
              <div className="ourrest_row">
                <div className="ourrest_img">
                <div className="res-mapdiv">
                 <div className="container res_container">
                  <iframe src={encodeURI(item.outlet_map_link).replace("&","%26")}></iframe>
                 </div>
                 </div>
                </div>
                <div className="ourrest_info">
                  <h4>{stripslashes(item.outlet_name)}</h4>
                  <p className="ourrest_info_address">
                    {item.outlet_address_line1}
                    {item.outlet_unit_number2 !== ""
                      ? " #" +
                        item.outlet_unit_number1 +
                        "-" +
                        item.outlet_unit_number2
                      : item.outlet_unit_number1 !== ""
                      ? " #" + item.outlet_unit_number1
                      : ""}{" "}
                    Singapore - {item.outlet_postal_code}
                  </p>
                  <p>
                    <div className="outlet_unit_phone">
                      <strong>
                        <i className="fa fa-phone" aria-hidden="true"></i> Tel :
                      </strong>{" "}
                      <a href={"tel:" + item.outlet_phone}>
                        +65 {item.outlet_phone}
                      </a>
                    </div>{" "}
                    <div className="outlet_unit_fax">
                      <strong>
                        <i className="fa fa-fax" aria-hidden="true"></i> Fax :
                      </strong>{" "}
                      +65 {item.outlet_fax_no}
                    </div>{" "}
                  </p>

                  <p className="ourrest_info_ohours">
                    {item.outlet_email !== "" && (
                      <div className="outlet_unit_mail">
                        <strong>
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          Email :
                        </strong>
                        <span>
                          <a href={"mailto:" + item.outlet_email}>
                            {item.outlet_email}
                          </a>
                        </span>
                      </div>
                    )}
                  </p>

                  <p>
                    {" "}
                    <strong>Operation Hours : </strong>
                    {item.da_monday_end_time !== null &&
                    item.da_monday_start_time !== null ? (
                      <>
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        Mon:{" "}
                        {timeToConv12(item.da_monday_start_time) +
                          " - " +
                          timeToConv12(item.da_monday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_tuesday_end_time !== null &&
                    item.da_tuesday_start_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Tue:{" "}
                        {timeToConv12(item.da_tuesday_start_time) +
                          " - " +
                          timeToConv12(item.da_tuesday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_wednesday_start_time !== null &&
                    item.da_wednesday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Wed:{" "}
                        {timeToConv12(item.da_wednesday_start_time) +
                          " - " +
                          timeToConv12(item.da_wednesday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_thursday_start_time !== null &&
                    item.da_tuesday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Thu:{" "}
                        {timeToConv12(item.da_thursday_start_time) +
                          " - " +
                          timeToConv12(item.da_tuesday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_friday_start_time !== null &&
                    item.da_monday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Fri:{" "}
                        {timeToConv12(item.da_friday_start_time) +
                          " - " +
                          timeToConv12(item.da_monday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_saturday_start_time !== null &&
                    item.da_saturday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Sat:{" "}
                        {timeToConv12(item.da_saturday_start_time) +
                          " - " +
                          timeToConv12(item.da_saturday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_sunday_start_time !== null &&
                    item.da_sunday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Sun:{" "}
                        {timeToConv12(item.da_sunday_start_time) +
                          " - " +
                          timeToConv12(item.da_sunday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                  </p>

                  <ul className="ourrest_infolinks">
                    <li className="media-links-b li-full-width">
                      <a
                        href="/menu"
                        rel="nofollow"
                        className="readmore font-headings"
                      >
                        Order Now{" "}
                      </a>
                    </li>
                  </ul>
                  {item.outlet_informations !== "" &&
                  item.outlet_informations !== null
                    ? Parser(item.outlet_informations)
                    : ""}
                </div>
              </div>
            </li>
          );
        }
      });
    }
  };
  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Our Locations</p>
          </div>
        </div>
        <div className="outlet-lst-page">
          <div className="container-one cms-content">
            <div className="container">
              <ul className="outletul">
                {this.getOutletData(this.state.outlets)}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
