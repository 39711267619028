export const GET_GLOBAL_SETTINGS = "getGlobalSettings";
export const SET_GLOBAL_SETTINGS = "setGlobalSettings";
export const GET_NORMAL_POPUP = "getNormalPopup";
export const SET_NORMAL_POPUP = "setNormalPopup";
export const GET_LOGINDATA = "getLoginData";
export const SET_LOGINDATA = "setLoginData";
export const GET_FBLOGINDATA = "getFbLoginData";
export const SET_FBLOGINDATA = "setFbLoginData";
export const GET_ALLUSERSECADDRDATA = "getAllUserSecAddrData";
export const SET_ALLUSERSECADDRDATA = "setAllUserSecAddrData";
export const ADD_USERSECADDRDATA = "addUserSecAddrData";
export const GET_UPDATECUSTOMERPROFILE = "getUpdateCustomerProfile";
export const SET_UPDATECUSTOMERPROFILE = "setUpdateCustomerProfile";
export const GET_CUSTOMER_DETAIL = "getCustomerDetail";
export const SET_CUSTOMER_DETAIL = "setCustomerDetail";
export const GET_FORGET_PASSWORD = "getForgetPassword";
export const SET_FORGET_PASSWORD = "setForgetPassword";
export const GET_REGISTRATION = "getRegistration";
export const SET_REGISTRATION = "setRegistration";
export const GET_CHANGEPASSWORD = "getChangePassword";
export const SET_CHANGEPASSWORD = "setChangePassword";
export const GET_CORDERDETAIL = "getCorderDetail";
export const SET_CORDERDETAIL = "setCorderDetail";
export const GET_PORDERDETAIL = "getPorderDetail";
export const SET_PORDERDETAIL = "setPorderDetail";
export const GET_ORDER_DETAIL = "getOrderDetail";
export const SET_ORDER_DETAIL = "setOrderDetail";
export const GET_PRINTORDER = "getPrintOrder";
export const SET_PRINTORDER = "setPrintOrder";
export const GET_ORDERHISTORY = "getOrderHistory";
export const SET_ORDERHISTORY = "setOrderHistory";
export const GET_PROMOTIONLIST = "getPromotionList";
export const SET_PROMOTIONLIST = "setPromotionList";
export const GET_CARTLIST = "getCartList";
export const SET_CARTLIST = "setCartList";
export const GET_PROMOTIONRECEIPT = "getPromotionReceipt";
export const SET_PROMOTIONRECEIPT = "setPromotionReceipt";
export const GET_APPLYPROMOTION = "getApplyPromotion";
export const SET_APPLYPROMOTION = "setApplyPromotion";
export const GET_ACTIVITYCOUNT = "getActivityCount";
export const SET_ACTIVITYCOUNT = "setActivityCount";
export const GET_REWARDEARNED = "getRewardEarned";
export const SET_REWARDEARNED = "setRewardEarned";
export const GET_REWARDREDEEM = "getRewardRedeem";
export const SET_REWARDREDEEM = "setRewardRedeem";
export const GET_REQUESTPAGEDATA = "getRequestpage";
export const SET_REQUESTPAGEDATA = "setRequestpage";
export const GET_MENUDATA = "getMenuData";
export const SET_MENUDATA = "setMenuData";
export const GET_CATERINGCORDER = "getCateringOrder";
export const SET_CATERINGCORDER = "setCateringOrder";
export const GET_CATERINGPORDER = "getCateringPastorder";
export const SET_CATERINGPORDER = "setCateringPastorder";
export const GET_CATERINGHISTORY = "getCateringHistory";
export const SET_CATERINGHISTORY = "setCateringHistory";
export const GET_CONTACTDATA = "getContactData";
export const SET_CONTACTDATA = "setContactData";
export const GET_STATIC_BLOCK = "getStaticBlock";
export const SET_STATIC_BLOCK = "setStaticBlock";
export const GET_PICKUP_OUTLETS = "getPickupOutlets";
export const SET_PICKUP_OUTLETS = "setPickupOutlets";
export const GET_ALL_OUTLETS = "getAllOutlets";
export const SET_ALL_OUTLETS = "setAllOutlets";
export const GET_ZONE_DETAIL = "getZoneDetail";
export const SET_ZONE_DETAIL = "setZoneDetail";
export const GET_CART_DETAIL = "getCartDetail";
export const UPDATE_CART_DETAIL = "updateCartDetail";
export const DELETE_CART_DETAIL = "deleteCartDetail";
export const DESTROY_CART_DETAIL = "destroyCartDetail";
export const SET_CART_DETAIL = "setCartDetail";
export const GET_BANNER_LIST = "getBannerList";
export const SET_BANNER = "setBanner";
export const GET_FEATUREPRO = "getFeatureProList";
export const SET_FEATUREPRO = "setFeaturePro";
export const GET_MENU_NAVIGATION = "getMenuNavigation";
export const SET_MENU_NAVIGATION = "setMenuNavigation";
export const GET_PRODUCT_LIST = "getProductList";
export const SET_PRODUCT = "setProduct";
export const GET_PRODUCT_DETAIL = "getProductDetail";
export const SET_PRODUCT_DETAIL = "setProductDetail";
export const GET_ADDONPRODUCT = "getAddonProList";
export const SET_ADDONPRODUCT = "setAddonPro";
export const GET_NEWSDATA = "getNewsData";
export const SET_NEWSDATA = "setNewsData";
export const GET_CATERINGPRINT = "getCateringPrint";
export const SET_CATERINGPRINT = "setCateringPrint";
export const GET_HOLIDAYS = "getHolidays";
export const SET_HOLIDAYS = "setHolidays";
export const GET_CATEGORY_LIST = "getCategoryList";
export const SET_CATEGORY_LIST = "setCategoryList";
export const GET_OWNVENUE_CATEGORY = "getOwnvenueCategory";
export const SET_OWNVENUE_CATEGORY = "setOwnvenueCategory";
export const GET_HALL_CATEGORY = "getHallCategory";
export const SET_HALL_CATEGORY = "setHallCategory";
export const GET_CATERING_HALL = "getCateringHalls";
export const SET_CATERING_HALL = "setCateringHalls";
export const GET_AVAILABLE_DATE = "getAvailabileDateList";
export const SET_AVAILABLE_DATE = "setAvailabileDateList";
export const GET_AVAILABLE_TIME = "getAvailabileTimeList";
export const SET_AVAILABLE_TIME = "setAvailabileTimeList";
export const GET_DEFAULTAVL_DATE = "getDefaultAvlDateList";
export const SET_DEFAULTAVL_DATE = "setDefaultAvlDateList";
export const GET_DEFAULTAVL_TIME = "getDefaultAvlTimeList";
export const SET_DEFAULTAVL_TIME = "setDefaultAvlTimeList";
export const GET_CATERING_PRODCUTLIST = "getCateringProductList";
export const SET_CATERING_PRODCUTLIST = "setCateringProductList";
export const GET_CATERING_PRODCUTDETAIL = "getCateringProductDetail";
export const SET_CATERING_PRODCUTDETAIL = "setCateringProductDetail";
export const GET_CATERINGCART_DETAIL = "getCateringCartDetail";
export const SET_CATERINGCART_DETAIL = "setCateringCartDetail";
export const UPDATE_CATERINGCART_DETAIL = "updateCateringCartDetail";
export const DELETE_CATERINGCART_DETAIL = "deleteCateringCartDetail";
export const DELETE_INVCATERINGCART_DETAIL = "deleteInvCateringCartDetail";
export const DESTROY_CATERINGCART_DETAIL = "destroyCateringCartDetail";
export const GET_ORDERRQUESTLIST = "getOrderRequestlist";
export const SET_ORDERRQUESTLIST = "setOrderRequestlist";

export const GET_RES_AVAILABLE_DATE = "getResAvailabileDateList";
export const SET_RES_AVAILABLE_DATE = "setResAvailabileDateList";
export const GET_RES_AVAILABLE_TIME = "getResAvailabileTimeList";
export const SET_RES_AVAILABLE_TIME = "setResAvailabileTimeList";
export const GET_RES_ORDER_SUBMIT = "getResOrdersubmit";
export const SET_RES_ORDER_SUBMIT = "setResOrdersubmit";
export const GET_RESERVATIONHISTORY = "getReservationHistory";
export const SET_RESERVATIONHISTORY = "setReservationHistory";

/* Catering */
export const GET_CATERING_SUBCAT = "getCateringSubCat";
export const SET_CATERING_SUBCAT = "setCateringSubCat";
export const GET_CARTTIMEDATA = "getCarttimedata";
export const SET_CARTTIMEDATA = "setCarttimedata";
export const GET_UPLOAD_IMAGE = "getUploadImage";
export const SET_UPLOAD_IMAGE = "setUploadImage";
export const GET_DELETE_IMAGE = "getDeleteImage";
export const SET_DELETE_IMAGE = "setDeleteImage";
export const GET_CARTDATEDATA = "getCartdatedata";
export const SET_CARTDATEDATA = "setCartdatedata";
export const GET_CATERINPAYMENTDATA = "getCateringPaymentDetails";
export const SET_CATERINPAYMENTDATA = "setCateringPaymentDetails";
export const GET_DELETE_CATERING_CART = "getDeleteCateringCart";
export const SET_DELETE_CATERING_CART = "setDeleteCateringCart";

export const GET_BLOG = "getBlogList";
export const SET_BLOG = "setBlogList";
export const GET_TESTIMONIAL = "getTestimonial";
export const SET_TESTIMONIAL = "setTestimonial";
export const GET_OUTLETCATEGORIES = "getOutletCategories";
export const SET_OUTLETCATEGORIES = "setOutletCategories";
export const GET_MAINCATEGORIES = "getMainCategories";
export const SET_MAINCATEGORIES = "setMainCategories";

export const GET_HIGHLIGHTPROLIST = "getHighlightproductslist";
export const SET_HIGHLIGHTPROLIST = "setHighlightproductslist";

export const GET_TABLEAVAILABILITY = "getTableAvailability";
export const SET_TABLEAVAILABILITY = "setTableAvailability";
export const GET_INSTA_FEED = "getInstaFeed";
export const SET_INSTA_FEED = "setInstaFeed";
export const GET_EVENTS = "getEvents";
export const SET_EVENTS = "setEvents";
export const GET_EVENTDATA = "getEventData";
export const SET_EVENTDATA = "setEventData";

export const GET_RESERVATIONCOMPHISTORY = "getReservationCompHistory";
export const SET_RESERVATIONCOMPHISTORY = "setReservationCompHistory";

export const GET_FAVOURITE = "getFavouriteproducts";
export const SET_FAVOURITE = "setFavouriteproducts";
export const GET_FAVOURITELIST = "getFavouriteproductslist";
export const SET_FAVOURITELIST = "setFavouriteproductslist";
