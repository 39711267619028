/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
// import { stripslashes } from "../Helpers/SettingHelper";
import Slider from "react-slick";
import { appId,apiUrlV2, reservationId, CountryTxt, mediaUrl,  pickupId,  cateringId,} from "../Helpers/Config";
import { timeToConv12 } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import OrderdatetimeSlot from "../Layout/OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import noimage from "../../common/images/noimg-470x240.jpg";
import eventBannerImg from "../../common/images/tiga_event_img.jpg";
import axios from 'axios';
import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS,GET_RES_AVAILABLE_DATE,GET_RES_AVAILABLE_TIME,GET_RES_ORDER_SUBMIT,GET_STATIC_BLOCK } from "../../actions";
import moment from 'moment';
import { stripslashes, showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";

var Parser = require("html-react-parser");
class Reservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
      timeSlotHtmlDrwn: "",
			slctTimeValue: "", 
			
			reservation_outlet_id:'',
			reservation_outlet_txt:'',
			reservation_selected_outlet:'',
			reservation_outlet_postalcode:'',
			reservation_outlet_name:'',
			reservation_outletaddress_line:'',
			reservation_outlet_address:'',
			adultValue:0,
			childValue:0,
			datePicker:1,
			timePicker:1,
			reservation_notes:'',
			reservation_eventtype:'',
      datePickershow:0,
			timePickershow:0,
			reservation_flag_key:'',
			reservation_flag_date:'',
			reservation_date_pre:'',
			reservation_date_post:'',
			dateListArr:'',
			current_day:'',
			user_reservation_date:'',
			dineInfo:[],
			staticblacks: [],
			eventdata: '',
			seleted_ord_date: '',
			seleted_ord_time: '',
      user_reservation_time:'',
			user_reservation_show_date:'',
			user_reservation_time_type:'',
			reservation_date:'',
      reservation_show_date:'',
    };

  	this.props.getStaticBlock();
  //console.log('state',cookie.load('reservation_show_date'));
  }

  componentDidMount() {

    this.props.getGlobalSettings();
    $('.error_div_cls_limit').hide();	
  
    if(typeof cookie.load('reservation_adult')!='undefined' && typeof cookie.load('reservation_adult')!='') {
      this.setState({adultValue:cookie.load('reservation_adult')});
    }
    if(typeof cookie.load('reservation_child')!='undefined' && typeof cookie.load('reservation_child')!='') {
      this.setState({childValue:cookie.load('reservation_child')});
    }
    if(typeof cookie.load('reservation_selected_outlet')!='undefined' && typeof cookie.load('reservation_selected_outlet')!='') {
      this.setState({reservation_selected_outlet:cookie.load('reservation_selected_outlet')});
     }
     if(typeof cookie.load('reservation_show_date')!='undefined' && typeof cookie.load('reservation_show_date')!='') {
      this.setState({reservation_show_date:moment(cookie.load('reservation_show_date'), ["h:mm A"]).format("DD-MM-YYYY")});
      this.setState({seleted_ord_date:cookie.load('reservation_show_date')});
      //console.log('state',this.state.reservation_show_date);
    }
    if(typeof cookie.load('reservation_show_time')!='undefined' && typeof cookie.load('reservation_show_time')!='') {
      this.setState({reservation_show_time:cookie.load('reservation_show_time')});
    }
    if(typeof cookie.load('reservation_end_time')!='undefined' && typeof cookie.load('reservation_end_time')!='') {
      this.setState({reservation_end_time:cookie.load('reservation_end_time')});
    }
    if(typeof cookie.load('reservation_notes')!='undefined' && typeof cookie.load('reservation_notes')!='') {
      this.setState({reservation_notes:cookie.load('reservation_notes')});
    }
    if(typeof cookie.load('reservation_eventtype')!='undefined' && typeof cookie.load('reservation_eventtype')!='') {
      this.setState({reservation_eventtype:cookie.load('reservation_eventtype')});
    }
    

    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  
  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
    //  this.state.outlets.length>0 && this.handleChange1({"target":{"name":"reservation_outlet_id", "value":this.state.outlets[0]}})

        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: nextProps.outlets[0].result_set,
        }, function(){
	      	this.handleChange1({"target":{"name":"reservation_outlet_id", "value":this.state.outlets[0]}})
	      });
      
      }
    }

    if (nextProps.staticblack !== this.state.staticblacks) {
      var eventdataPars = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data) => {
          if (data.staticblocks_slug === "event-banner") {
            eventdataPars = data;
            return "";
          }
        });
        //$("#dvLoading").fadeOut(2000);
      }

      this.setState({
        staticblacks: nextProps.staticblack,
        eventdata: eventdataPars,
      });
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (nextProps.globalsettings && nextProps.globalsettings[0].status == "ok") {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
        //  console.log('nxtprps',this.state);
      }
    }

    if(nextProps.RerservationStatus !== this.state.RerservationStatus) {
		
      hideLoader('res-cont-div','class');
    
    if(nextProps.RerservationStatus === 'success' && nextProps.RerservationOrderlocalId !== '') {
      this.props.history.push("/reservation-thankyou");
    }
    
    if(nextProps.RerservationStatus === 'failure') {
      showAlert('error', 'Sorry!. Try Again');
      $.magnificPopup.open({
        items: {
        src: '.alert_popup'
        },
        type: 'inline'
      });
    }
    
    this.setState({ RerservationStatus: nextProps.RerservationStatus });
  }

  }

  tConvert(time) {
    if (
      time !== "" &&
      typeof time !== undefined &&
      typeof time !== "undefined"
    ) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    }
  }


 
  sateValChange = (field, value) => {};


  openConfirmPopup(){

      var adultValue = this.state.adultValue;
			var childValue = this.state.childValue;
			
		    if(this.state.reservation_eventtype === '') {
				document.getElementById('error_info_div').style.display = 'block';
				return false;
			 }else{
			 	document.getElementById('error_info_div').style.display = 'none';
			 }

		   if(parseInt(adultValue) === 0){
				document.getElementById('error_info_paxerror').style.display = 'block';
				return false;
			}else{
				document.getElementById('error_info_paxerror').style.display = 'none';
			}

			if(parseInt(childValue) === 0) {
			document.getElementById('error_info_paxerror').style.display = 'block';
				return false;
			}else{
				document.getElementById('error_info_paxerror').style.display = 'none';
			}


			var seleted_ord_date = moment(this.state.seleted_ord_date, ["h:mm A"]).format("DD-MM-YYYY");
			var seleted_ord_time_view = moment(this.state.seleted_ord_time, ["h:mm A"]).format("h:mm A");
			var seleted_ord_time = moment(this.state.seleted_ord_time, ["h:mm A"]).format("HH:mm:ss")+'-'+moment(this.state.seleted_ord_time, ["h:mm A"]).add(1, 'hours').format("HH:mm:ss");	
			cookie.save("reservation_date", seleted_ord_date);
			cookie.save("reservation_show_date", seleted_ord_date);
			cookie.save("reservation_show_time", seleted_ord_time_view);
			cookie.save("reservation_time",this.state.seleted_ord_slot_str) ;
			cookie.save("reservation_end_time",this.state.seleted_ord_slot_end) ;			
			cookie.save("reservation_time_type", '1');
			cookie.save("reservation_adult", this.state.adultValue);
			cookie.save("reservation_child", this.state.childValue);
			cookie.save("reservation_outlet_id", this.state.reservation_outlet_id);
			cookie.save("reservation_outlet_name", this.state.reservation_outlet_name);
			cookie.save("reservation_selected_outlet", this.state.reservation_selected_outlet);
			cookie.save("reservation_notes", this.state.reservation_notes);
			cookie.save("reservation_eventtype", this.state.reservation_eventtype);
			cookie.save("reservation_date_array", this.state.user_reservation_date_array);
			cookie.save("reservation_time_flag_next", this.state.time_flag_next);
			cookie.save("reservation_time_flag", this.state.flag_time);
			cookie.save("reservation_date_flag_keySet", this.state.flag_keySet);
			cookie.save("reservation_date_flag_dateSet", this.state.flag_dateSet);

	
      $.magnificPopup.open({
        items: {
          src: "#reservation-confirmation-popup",
        },
        type: "inline",
      });
  
    
  }
  
  closeconfirmpopup =()=>{
	$.magnificPopup.close()
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: tmSltArr["sldordtime"],
        seleted_ord_slot_end: ordTime,
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    }
  };


  handleChange1 = (event) =>
  {
    //console.log('handlechange',event);
    
		if(event.target.name === 'reservation_outlet_id') {
		
			this.setState({reservation_date: '', reservation_time: ''});
			
      		const {outlet_id,outlet_postal_code,outlet_name,outlet_address_line1,outlet_adult_limit,outlet_child_limit,outlet_max_pax_limit,outlet_delivery_tat}= event.target.value;
			this.setState({reservation_outlet_id:outlet_id,reservation_outlet_postalcode:outlet_postal_code,
				reservation_outlet_name:outlet_name,reservation_outletaddress_line:outlet_address_line1,reservation_outlet_address:outlet_address_line1,datePickershow:1,outletadultLimit: outlet_adult_limit,outletchildLimit: outlet_child_limit,outletpaxLimit: outlet_max_pax_limit
				});

		var seletedOutletId = outlet_id;


		var orderHandled = outlet_name;

		var dineInfo = [];
			  dineInfo['orderOutletId'] = outlet_id;
			  dineInfo['orderOutletName'] = stripslashes(outlet_name);
			  dineInfo['orderPostalCode'] = outlet_postal_code;
			  dineInfo['orderHandled'] = outlet_name;
			  dineInfo['orderTAT'] = outlet_delivery_tat;
			  dineInfo['orderHandledByText'] = outlet_name;
			  dineInfo['defaultAvilablityId'] = reservationId;	
		
		  this.setState({dineInfo: dineInfo, seletedAvilablityId: reservationId, seletedOutletId: outlet_id,  order_tat_time: outlet_delivery_tat, orderHandled:orderHandled});

		
		if((seletedOutletId !== '')){
			
			axios.get(apiUrlV2 + 'settings/chkTimeslotIsAvaiable?app_id=' + appId + "&availability_id=" + reservationId + "&outletId=" + seletedOutletId)
			  .then(res => {				  				  
				  if (res.data.status === "success") {
					    this.setState({getDateTimeFlg: 'yes'});						
				  } else {
					    $(document).find('.order_date_value,.order_time_value').val('');
					    $(document).find('.ordr-datetime-cls').val('');	 	
				  }
			});	  
			
		} 
	
		}

		if(event.target.name === 'reservation_eventtype') {
			this.setState({reservation_eventtype:event.target.value});
		}

		if(event.target.name === 'reservation_notes') {
			this.setState({reservation_notes:event.target.value});
		}
	
	  
  }

  updateAdultQty(type){
		
		var currentAdult = this.state.adultValue;
		var currentChild = this.state.childValue;		
		var maxAdult = this.state.outletadultLimit;	
		var paxlimit = this.state.outletpaxLimit;					
		var newValue = '';

		if(type === 'increment') {
			if(maxAdult != 0 && paxlimit !=0){			
				if(parseInt(currentAdult) < maxAdult && parseInt(currentAdult)+parseInt(currentChild) < paxlimit){
					$('.error_div_cls_limit').hide();				
					newValue = parseInt(currentAdult) + 5;
					}else{
					$('.error_div_cls_limit').show();								
					newValue = parseInt(currentAdult);
				}
			}else{
				newValue = parseInt(currentAdult) + 5;
			}
		}else if(type === 'decrement'){
			$('.error_div_cls_limit').hide();											
			if(parseInt(currentAdult) > 0){
				newValue = parseInt(currentAdult) - 5;
			}else{
				newValue = 0;
			}
		}
		$('.error_div_cls').hide();
		this.setState({'adultValue':newValue});
		setTimeout(function () {
			$('.error_div_cls_limit').hide();											
    }, 2000);
	}

  updateChildtQty(type){
		
		var currentChild = this.state.childValue;
		var currentAdult = this.state.adultValue;		
		var maxChild = this.state.outletchildLimit;	
		var paxlimit = this.state.outletpaxLimit;		
		var newValue = '';
		if(type === 'increment') {
			if(maxChild != 0 && paxlimit !=0){			
				if(parseInt(currentChild) < maxChild && parseInt(currentAdult)+parseInt(currentChild) < paxlimit){
					$('.error_div_cls_limit').hide();								
					newValue = parseInt(currentChild) + 5;
				}else{
				$('.error_div_cls_limit').show();								
				newValue = parseInt(currentChild);
				}
			}else{
				newValue = parseInt(currentChild) + 1;
			}
		}else if(type === 'decrement'){
			$('.error_div_cls_limit').hide();											
			if(parseInt(currentChild) > 0){
				newValue = parseInt(currentChild) - 5;
			}else{
				newValue = 0;
			}
		}
		$('.error_div_cls').hide();
		this.setState({'childValue':newValue});
		setTimeout(function () {
			$('.error_div_cls_limit').hide();											
    }, 2000);
	}

  submit_reservation(){
		
			var adultValue = this.state.adultValue;
			var childValue = this.state.childValue;
			
		    if(this.state.reservation_eventtype === '') {
				document.getElementById('error_info_div').style.display = 'block';
				$("#error_info_div").show();
				return false;
			}

		   if(parseInt(adultValue) === 0 && parseInt(childValue) === 0) {
				document.getElementById('error_info_paxerror').style.display = 'block';
				return false;
			}
		
		
			var seleted_ord_date = moment(this.state.seleted_ord_date, ["h:mm A"]).format("DD-MM-YYYY");
			var seleted_ord_time_view = moment(this.state.seleted_ord_time, ["h:mm A"]).format("h:mm A");
			var seleted_ord_time = moment(this.state.seleted_ord_time, ["h:mm A"]).format("HH:mm:ss")+'-'+moment(this.state.seleted_ord_time, ["h:mm A"]).add(1, 'hours').format("HH:mm:ss");	
			cookie.save("reservation_date", seleted_ord_date);
			cookie.save("reservation_show_date", seleted_ord_date);
			cookie.save("reservation_show_time", seleted_ord_time_view);
			cookie.save("reservation_time",this.state.seleted_ord_slot_str) ;
			cookie.save("reservation_end_time",this.state.seleted_ord_slot_end) ;			
			cookie.save("reservation_time_type", '1');
			cookie.save("reservation_adult", this.state.adultValue);
			cookie.save("reservation_child", this.state.childValue);
			cookie.save("reservation_outlet_id", this.state.reservation_outlet_id);
			cookie.save("reservation_outlet_name", this.state.reservation_outlet_name);
			cookie.save("reservation_selected_outlet", this.state.reservation_selected_outlet);
			cookie.save("reservation_notes", this.state.reservation_notes);
			cookie.save("reservation_eventtype", this.state.reservation_eventtype);
			cookie.save("reservation_date_array", this.state.user_reservation_date_array);
			cookie.save("reservation_time_flag_next", this.state.time_flag_next);
			cookie.save("reservation_time_flag", this.state.flag_time);
			cookie.save("reservation_date_flag_keySet", this.state.flag_keySet);
			cookie.save("reservation_date_flag_dateSet", this.state.flag_dateSet);

	}
  reservation_order_submit(){
    showLoader("showLoader", "class");
    var UserId = (cookie.load('UserId') != '' && cookie.load('UserId') != undefined) ? cookie.load('UserId') : '';	
    if(UserId === '') {
      cookie.save("loginpopupTrigger", 'Yes');
      cookie.save("redirectReservatin", 'Yes');
      this.props.history.push('/');
    }else{
      
      //this.props.history.push("/reservation-summary",{data:{...this.state}});
   
  var reservation_order = Array();
  reservation_order['app_id'] = appId;
  reservation_order['order_source'] = 'Web';
  reservation_order['time_slot_type_advanced'] = '2';
  reservation_order['reservation_status'] = 0;
  reservation_order['availability_id'] = reservationId;
  reservation_order['customer_fname'] = cookie.load('UserFname');
  reservation_order['customer_lname'] = cookie.load('UserLname');
  reservation_order['customer_email'] = cookie.load('UserEmail');
  reservation_order['customer_mobile_no'] = cookie.load('UserMobile');
  reservation_order['customer_id'] = cookie.load('UserId');
  reservation_order['reservation_datetime'] = this.state.reservation_date;
  reservation_order['reservation_outlet_id'] = this.state.reservation_outlet_id;
  reservation_order['reservation_outlet_time'] = cookie.load('reservation_time_type')+'-'+this.state.seleted_ord_slot_str;
  reservation_order['reservation_time_slot'] = cookie.load('reservation_time_type');
  reservation_order['reservation_start_time'] = this.state.seleted_ord_slot_str;
  reservation_order['reservation_end_time'] = this.state.seleted_ord_slot_end;
  reservation_order['reservation_outlet_name'] = this.state.reservation_outlet_name;
  reservation_order['reservation_no_of_adult_pax'] =this.state.reservation_adult;
  reservation_order['reservation_no_of_children_pax'] = this.state.reservation_child;
  reservation_order['reservation_instruction'] = this.state.reservation_notes;
  reservation_order['reservation_event_type'] = this.state.reservation_eventtype;

  showLoader('res-cont-div','class');
  this.props.getResOrdersubmit(reservation_order);
     this.closeconfirmpopup();
    
    }
}	
  render() {

  var settingsReservation = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
    };

    var settingsArr = this.props.globalsettings;
		var advancedTimeslotEnable = "0";
		if (Object.keys(settingsArr).length > 0) {
		  if (Object.keys(settingsArr[0].result_set).length > 0) {
			advancedTimeslotEnable =
			  settingsArr[0].result_set.client_advanced_timeslot_enable;
		  }
		}

    return (
      <div className="reservation-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Events</p>
          </div>
        </div>

        <div className="reservation-inner-div">
          <div className="container">
            <div className="reservation-inner-block">
              <div className="reservation-inner-top">
                <div className="reservation-heading-div">
                {/* {console.log('outlet',this.state.reservation_eventtype)} */}
				{this.state.outlets.length>0 && <> 
				   <h5>Events for {this.state.outlets[0].outlet_name}</h5>
                   <p>{this.state.outlets[0].outlet_address_line1}-{CountryTxt}-{this.state.outlets[0].outlet_postal_code}</p>
                   <p>telephone : <a href= {`tel: +65 ${this.state.outlets[0].outlet_phone}`}>{"+65 " + this.state.outlets[0].outlet_phone}</a> </p>
                  
                  </>}
                  
                  
                </div>
                {Object.keys(this.state.eventdata).length > 0 && ( 
                <div className="reservation-banner-div">
                  <Slider {...settingsReservation}>
                  	{this.state.eventdata.gallery_images.map((eventImage, index) => {
	                    return(<div>
	                    	<img src={this.state.eventdata.gallery_image_path+eventImage} alt="Reservation Banner" />
	                    	</div>
											);
											})}
                    {/*<div>
                    <img src="https://ccpl.ninjaos.com/media/dev_team/banners/45fb367b3558d3cbc569448a0665a4cf.jpg" alt="Reservation Banner" />
                    </div>*/}
                  </Slider>
                </div>
                )}
              </div>

              <div class="reservation-inner-bottom">
                <div class="reservation-bottom-lhs">
                  <div class="reservation-bottom-left">
                    <div className="reservation-left-info choose-datetime-full" >
                       {advancedTimeslotEnable === "1" ? (
					                        <OrderAdvancedDatetimeSlot
					                          {...this.props} 
					                          hdrState={this.state}
					                          setdateTimeFlg={this.setdateTimeFlg}
					                        />
					                      ) : (
					                        <OrderdatetimeSlot
					                          {...this.props}
					                          hdrState={this.state}
					                          setdateTimeFlg={this.setdateTimeFlg}
					                        />
					                      )}
                      </div>

                      <div className="reservation-right-info">
                        <h5 className="res_hading">choose pax</h5>
                        <div className="reservation-right-block">
                          <div className="form-group">
                            <div className="res_bty_main">
                              <p>Adults</p>
                              <div className="qty_bx">
                                <span className="qty_minus" onClick={this.updateAdultQty.bind(this,'decrement')}>  -  </span>
                                <input type="text" name="adultValue" value={this.state.adultValue} className="proqty_input" readOnly="1" />
                                <span  className="qty_plus" onClick={this.updateAdultQty.bind(this,'increment')}  >  +  </span>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="res_bty_main res_kids_main">
                              <p>Kids</p>
                              <div className="qty_bx">
                                <span className="qty_minus" onClick={this.updateChildtQty.bind(this,'decrement')}>  -  </span>
                                <input type="text" name="childValue"  value={this.state.childValue} className="proqty_input" readOnly="1"/>
                                <span  className="qty_plus" onClick={this.updateChildtQty.bind(this,'increment')}>  +  </span>
                              </div>
                            </div>
                          </div>
                        </div>

                          {/*<div id='error_info_paxerrorlimit' className="errorInfo-div">
                            Max Limit Reached
                          </div>*/}

                          <div className="error_div_cls_limit">
                          	Max Limit Reached
                          </div>
                          <div id='error_info_paxerror' className="errorInfo-div">
                              Please Add Adult OR Child Count.
                          </div>
                      </div>
                  </div>
                </div>

                <div className="reservation-bottom-rhs">
                  <div className="form-group">
                    <h5 className="res_hading">choose event type</h5>
                    <div className="focus-out">
                      <select name="reservation_eventtype" value={this.state.reservation_eventtype} onChange={this.handleChange1.bind(this)} id="tims">
                          <option value="">Please Select Event</option>
                          <option value="birthday">Birthday Event</option>
                          <option value="marriage">Marriage Event</option>
                          <option value="wedding">Wedding Event</option>
                      </select>
					  							<div id='error_info_div' className="errorInfo-div">
                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true">
                                                    </i>
                                                    <span id="error_span"> Please select Event type.</span>
                                                </div>
                      <div className="form-group">
                        <textarea placeholder="Let us know your special request including seating area you prefer.." id="feedback" className="reservation_notes form-control input-focus" maxLength="500" rows ="3" name="reservation_notes" value={this.state.reservation_notes} onChange={this.handleChange1.bind(this)}></textarea>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

              <div className="res-btn">
                {/* <a href="javascript:void(0)" className="button"  onClick={this.submit_reservation.bind(this)}>confirm & book</a> */}
                <button type="button" class="button" onClick={this.openConfirmPopup.bind(this)}>confirm & book</button>
              </div>

            </div>
          </div>
        </div>
        


        {/* Order popup - start */}
        <div  id="reservation-confirmation-popup"  className="white-popup mfp-hide popup_sec reservation_popup">
            <div className="reservation_popupmain">
                <div className="reservation_popupinner">
                  <div className="reservation_popuptopheading">
                    <h3>Reservation summary</h3>
                  </div>
				
				  {this.state.outlets.length>0 && <>
					<div className="reservation_popupcontent">
                    <p>Event location</p>
                    <h5>{this.state.reservation_outlet_name}</h5>
                  
                    <p>Event Date</p>
                    <h5>{moment(this.state.seleted_ord_date, ["h:mm A"]).format("DD-MM-YYYY")}</h5>

                    <p>Event Time</p>
                    <h5>{moment(this.state.seleted_ord_time, ["h:mm A"]).format("h:mm A")}- {moment(this.state.seleted_ord_slot_end, ["h:mm A"]).format("h:mm A")}</h5>

                    <p>Event Type</p>
                    <h5>{this.state.reservation_eventtype}</h5>

                    <p>{this.state.reservation_notes}</p>

                  </div>
				  
				  
				  </>}
                 

                </div>

              <div className="reservation_popupbutton">
			  <a href="javascript:void(0)" className="button"  onClick={this.reservation_order_submit.bind(this)}>confirm & book</a>
              </div>
            </div>
        </div>
        {/* Order popup - end */}


        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('stateprops',state);
  var dateListArr = Array();
	var current_day = '';
	if(Object.keys(state.reservationdate).length > 0) {
		 if(state.reservationdate[0].status === 'success') {
			 dateListArr = state.reservationdate[0].result_set;
			 current_day = state.reservationdate[0].current_day;
		 }
	}
	
	var timeListArr = Array();
	var times_divide_pre = '';
	var times_divide_next = '';
	if(Object.keys(state.reservationtime).length > 0) {
		
		 if(state.reservationtime[0].status === 'success') {
			 timeListArr = state.reservationtime[0].result_set.time_set;
			 times_divide_pre = state.reservationtime[0].result_set.pre;
			 times_divide_next = state.reservationtime[0].result_set.next;
		 }
	}

  var RerservationOrderlocalId = '';
	var RerservationOrderprimaryId = '';
	var RerservationOrderOn = '';
	var RerservationStatus = '';
	
	if(Object.keys(state.reservationordersubmit).length > 0) {
		
		 if(state.reservationordersubmit[0].status === 'ok' && Object.keys(state.reservationordersubmit[0].common).length > 0) {
			
			 RerservationStatus = 'success';
			 RerservationOrderlocalId = state.reservationordersubmit[0].common['local_order_no'];
			 RerservationOrderprimaryId = state.reservationordersubmit[0].common['order_primary_id'];
			 RerservationOrderOn = moment(new Date()).format("DD-MM-YYYY h:m A");
			
			 cookie.save("RerservationOrderlocalId",RerservationOrderlocalId, { path: "/" }) ;
			 cookie.save("RerservationOrderprimaryId",RerservationOrderprimaryId, { path: "/" }) ;
			 cookie.save("RerservationOrderOn",RerservationOrderOn, { path: "/" }) ;
		 } else {
			 RerservationStatus = 'failure';
		 }
	}

	var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
	
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
    dateListArr: dateListArr,
	  current_day: current_day,
    timeListArr: timeListArr,
    times_divide_pre: times_divide_pre,
    times_divide_next: times_divide_next,
    pagedata: state.pagedata,
    RerservationOrderlocalId: RerservationOrderlocalId,
    RerservationOrderprimaryId: RerservationOrderprimaryId,
    RerservationOrderOn: RerservationOrderOn,
    RerservationStatus: RerservationStatus,
    staticblack: blacksArr,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },

    getResAvailabileDateList: (avlDateFlg) => {
			dispatch({ type: GET_RES_AVAILABLE_DATE, avlDateFlg });
		},
		
		getResAvailabileTimeList: (avlTimeFlg) => {
			dispatch({ type: GET_RES_AVAILABLE_TIME, avlTimeFlg });
		},

    getResOrdersubmit: (resOrderFlg) => {
			dispatch({ type: GET_RES_ORDER_SUBMIT, resOrderFlg });
		},

		getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};
Reservation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reservation));
