/* eslint-disable */
import React from "react";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import Modal_Popup from "../Modal-Popup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import crossImg from "../../common/images/cross1.png";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import search from "../../common/images/search.png";
import delete_dark from "../../common/images/delete-dark.png";
import Filter from "../../common/images/package-filter.png";
import Close from "../../common/images/close.png";
import delete_white from "../../common/images/delete-white.png";
import edit_dark from "../../common/images/edit-dark.png";
import warning from "../../common/images/warning.png";
import cart_blue from "../../common/images/cart-blue.png";
import close_light from "../../common/images/close-light.png";
import noimage from "../../common/images/no-img-product.png";
import {
  appId,
  apiUrlCtrng,
  apiUrl,
  apiUrlV2,
  cateringId,
  CountryTxt,
} from "../Helpers/Config";
import moment from "moment";
import {
  GET_CATEGORY_LIST,
  GET_CATERING_PRODCUTLIST,
  GET_STATIC_BLOCK,
  UPDATE_CART_DETAIL,
  GET_AVAILABLE_TIME,
  GET_AVAILABLE_DATE,
  GET_CARTLIST,
  GET_UPLOAD_IMAGE,
  GET_DELETE_IMAGE,
} from "../../actions";
import Category from "./Category";
import Product from "./Product";

import {
  stripslashes,
  getReferenceID,
  showPriceValue,
  getGstValue,
  getSurchargesplitup,
  removeOrderDateTime,
} from "../Helpers/SettingHelper";
var Parser = require("html-react-parser");
var qs = require("qs");
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
};
class Index extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      modalPopup: false,
      event_start_time: null,
      event_end_time: null,
      cart_display: "block",
      breaktime_index: 0,
      total_cart_price: 0,
      cart_grand_total: 0,
      catering_postal_code: "",
      catering_address_line: cookie.load("catering_address_line")
        ? cookie.load("catering_address_line")
        : "",
      catering_floor_no: cookie.load("catering_floor_no")
        ? cookie.load("catering_floor_no")
        : "",
      catering_unit_no: cookie.load("catering_unit_no")
        ? cookie.load("catering_unit_no")
        : "",
      zone_delivery_charge:
        cookie.load("zone_delivery_charge") !== "" &&
        typeof cookie.load("zone_delivery_charge") !== undefined
          ? cookie.load("zone_delivery_charge")
          : "",
      zone_additional_delivery_charge:
        cookie.load("zone_additional_delivery_charge") !== "" &&
        typeof cookie.load("zone_additional_delivery_charge") !== undefined
          ? cookie.load("zone_additional_delivery_charge")
          : "",
      startDate: date,
      loadcart: true,
      cartcount: 0,
      common_datalist: [],
      time_flag_key: "START",
      orderTime: "",
      delivery_blocks: "",
      loading: true,
      existSelectedTimes: [],
      productsdetail: [],
      productfeilsdetail: [],
      product_addons: [],
      product_setup: [],
      product_modifiers: [],
      product_modifiers_price: 0,
      product_id: "",
      selected_pakageIds: [],
      checkclick: false,
      start_validate: false,
      end_validate: false,
      open: true,
      settupType: "",
      currentSettup: "",
      product_setup_selfcollecdction: [],
      equipmentSetup: [],
      CurrentEquipment: [],
      surcharge: 0,
      individualModPrice: 0,
      custom_logo_banner: [],
      specialInstruction: "",
      productTotalPrice: 0,
      newproductTotalPrice: 0,
      newproductUnitPrice: 0,
    };
    this.loadCartDet();
    //this.getSurCharge();
    this.loadcartitems = this.loadcartitems.bind(this);

    if (
      typeof cookie.load("defaultAvilablityId") !== "undefined" &&
      cookie.load("defaultAvilablityId") !== "" &&
      cookie.load("defaultAvilablityId") !== cateringId
    ) {
      removeOrderDateTime();
      cookie.remove("order_date", { path: "/" });
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
    } else {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
    }

    if (
      typeof cookie.load("orderOutletId") === "undefined" ||
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") === ""
    ) {
      cookie.remove("catering_postal_code");
    }

    if (cookie.load("order_date")) {
      this.props.getCartdatedata({
        dateFlagkey: "START",
        outletId: cookie.load("orderOutletId"),
      });
    }

    this.togglePopup = this.togglePopup.bind(this);
    this.closeToggle = this.closeToggle.bind(this);
  }

  handleChange = (date) => {
    // if(this.state.catering_postal_code === ''){
    //   swal('Error!','Please enter valid Postal code!','error');
    //   cookie.save("order_date", '');
    //   this.setState({ orderDate: '' });
    // }
    if (date === null) {
      date = "";
      this.setState({ date });
      $(".order_date_error").hide();
      cookie.save("order_date", "");
      this.setState({ orderDate: date }, () => {
        this.getAvailableTime();
      });
    } else {
      this.setState({ date });
      $(".order_date_error").hide();
      cookie.save("order_date", moment(date).format("MM/DD/YYYY"));
      this.setState({ orderDate: date }, () => {
        this.getAvailableTime();
      });
    }

    // $(".add-breaktime").trigger( "click" );
  };

  async componentDidMount() {
    await this.props.getCategory();
    this.props.getStaticblock();
    var current_object = this;
    if (
      typeof cookie.load("catering_postal_code") !== "undefined" ||
      cookie.load("catering_postal_code") !== ""
    ) {
      this.getCommonSetting(cookie.load("catering_postal_code"));
    }

    if (
      typeof cookie.load("orderOutletId") !== "undefined" ||
      cookie.load("orderOutletId") !== ""
    ) {
      this.props.getCartdatedata({
        dateFlagkey: "START",
        outletId: cookie.load("orderOutletId"),
      });
    }

    /*accordion
    $(document).on("click", ".accordion_title", function() {
      $(this).toggleClass("active");
      $(this)
        .next(".accordion_body")
        .slideToggle();
    });*/

    $(document)
      .off("click", ".accordion_title")
      .on("click", ".accordion_title", function (e) {
        $(this).toggleClass("active");
        $(this).next(".accordion_body").slideToggle();
      });

    /*file upload */
    $("#upload-file").change(function (e) {
      for (var i = 0; i < this.files.length; i++) {
        var file_name = this.files[i].name;
        $(this).parent().find(".file-name").html(file_name);
      }
    });
    /*package modal */
    $(document).on("click", ".popup_link", function () {
      $("html").addClass("modal-open-html");
      $(".det_inner_content .package_checkitem").hide();
      $(".custom_text_logo").hide();
    });

    $(document).on("click", ".modal-header .cat-modal .close", function () {
      $("html").removeClass("modal-open-html");
    });
    /*responsive filter */
    $(document).on("click", ".package_filter", function () {
      $(this).toggleClass("close_filter");
      $(".products_sidebar_inner").slideToggle("open");
    });
    /* responsive cart*/
    $(document).on("click", ".card_icon", function () {
      if ($(window).width() <= 780) {
        $(".product_orders").fadeIn();
      }
    });
    $(document).on("click", ".close_product_orders", function () {
      $(".product_orders").fadeOut();
    });
    /*sticky package button*/
    $(window).scroll(function () {
      if ($(".fixed_checkout").length > 0) {
        let btnHeight = $(".fixed_checkout .btn").outerHeight();
        $(".fixed_checkout").css("height", btnHeight);
        if ($(window).width() <= 780) {
          var scroll = $(window).scrollTop() + $(window).height() - btnHeight;
          let fixTop = $(".fixed_checkout").offset().top;
          if (scroll > fixTop) {
            $(".fixed_checkout .btn").addClass("remove_sticky");
          } else {
            $(".fixed_checkout .btn").removeClass("remove_sticky");
          }
        }
      }
    });

    $(document).on("click", ".add-breaktime", function () {
      $(".order_card").removeClass("dark").addClass("light");
      $(this).hide();
      $(".addition-breaktime").addClass("dark");
      $(".addition-breaktime").removeClass("light");
      $(".addition-breaktime").show();
      $(".addition-breaktime").find("#event_start_timer").val("");
      $(".addition-breaktime").find("#event_end_timer").val("");
    });

    $(document).on("click", ".order_card", function (e) {
      $(".event_start").removeClass("required");
      $(".event_end").removeClass("required");
      $(".order_card").removeClass("dark");
      $(".order_card").addClass("light");
      $(".light").find(".order_card_head").find("img").attr("src", delete_dark);
      $(this).removeClass("light");
      $(this).addClass("dark");
      $(".dark").find(".order_card_head").find("img").attr("src", delete_white);
      // $('.light').find('img').attr('src',delete_dark)
    });

    $(document).on("click", ".cart_qty_plus,.cart_qty_minus", function () {
      var roots = $(this).closest(".products_info_item");
      var amount = $(this).parent().attr("data-unitprice");
      var product_quantity = roots.find(".product_quantity").text();
      var prd_qty;

      var Minqty = roots.find(".cart-counter").attr("data-minqty");
      /* if ($(this).data("type") === "plus") {
        prd_qty = parseInt(product_quantity) + 10;
      } else {
        prd_qty = parseInt(product_quantity) - 10;
      }*/

      let pax_increase = 10;

      if ($(this).data("type") === "minus") {
        prd_qty =
          parseInt(product_quantity) > Minqty
            ? parseInt(product_quantity) - pax_increase
            : 0;
        if (prd_qty >= pax_increase) {
          prd_qty = parseInt(product_quantity) - pax_increase;
        }
      } else {
        prd_qty = parseInt(product_quantity) + pax_increase;
      }

      var total_price_update = parseFloat(amount) * parseInt(prd_qty);
      var productId = roots.find(".cart-counter").attr("data-productid");
      var cartItemId = roots.find(".cart-counter").attr("data-itemid");

      if (Minqty <= prd_qty) {
        roots.find(".product_quantity").text(parseInt(prd_qty));
        //console.log("Minqty", Minqty, prd_qty);
        var postObject = {};
        postObject = {
          app_id: appId,
          cart_item_id: cartItemId,
          product_id: productId,
          product_qty: prd_qty,
          cartAction: "update",
        };

        if (typeof cookie.load("UserId") === "undefined") {
          postObject["reference_id"] = getReferenceID();
        } else {
          postObject["customer_id"] = cookie.load("UserId");
        }
        axios
          .post(apiUrlCtrng + "cart/update", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "ok") {
              $(".total_cart_price").text(
                showPriceValue(res.data.contents.cart_details.cart_grand_total)
              );
              $(".products_total_amount")
                .find("span")
                .text(
                  showPriceValue(
                    res.data.contents.cart_details.cart_grand_total
                  )
                );
              swal("Success!", res.data.message, "success");
              current_object.loadCartDet();
              // window.location.reload(true);
            } else if (res.data.status === "error") {
              swal("Error!", res.data.message, "error");
            }
          });

        roots
          .find(".cart_indvl_price")
          .text(showPriceValue(parseFloat(total_price_update)));
      }
    });
    // $('body').on('click', ".swal-button", function(){
    //   $(".close_product_orders").trigger( "click" );
    // });

    $(".order_date").focus(function (e) {
      var postal_code = $("#postal_code").val();
      if (postal_code === "") {
        swal("Error!", "Please enter valid Postal code!", "error");
      }
    });
  }

  handleUpdateChange(e) {
    /* var start_validate = this.state.start_validate;
    var end_validate = this.state.end_validate; */

    if (e.target.name === "start_time") {
      if (e.target.value === "") {
        this.setState(
          { start_validate: false },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").show();
      } else {
        this.setState(
          { start_validate: true, event_start: e.target.value },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").hide();
      }
    }

    if (e.target.name === "end_time") {
      if (e.target.value === "") {
        this.setState(
          { end_validate: false },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").show();
      } else {
        this.setState(
          { end_validate: true, event_end: e.target.value },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").hide();
      }
    }
    var itemid = e.target.attributes.getNamedItem("data-itemid");
    if (itemid !== "" && itemid !== undefined && itemid !== null) {
      itemid = e.target.attributes.getNamedItem("data-itemid").value;
      var rootid = e.target.attributes.getNamedItem("data-rootid").value;
      var CartItemList = itemid.split(",");
      var start_time = $("#" + rootid + " .start_time").val();
      var end_time = $("#" + rootid + " .end_time").val();
      if (start_time < end_time) {
        var currents = this;
        CartItemList.map(function (item) {
          if (item !== "") {
            currents.updateBreakTime(item, start_time, end_time);
          }
        });
      }
    }

    // itemid = itemid.replace('data-itemid"', "");
  }

  updateBreakTime(cartItmId, startTime, endTime) {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
      cart_item_id: cartItmId,
      break_time: startTime,
      break_time_end: endTime,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlCtrng + "cart/updateBreakTime", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          this.loadCartDet();
          // swal("Success!", "Nice! Products updated to your cart", "success");
        } else {
          //swal("Error!", "Sorry! Products can`t update your cart", "error");
        }
      })
      .catch(function (error) {
        swal("Error!", "something went wrong", "error");
      });
  }

  showSuccess() {
    if (this.state.start_validate && this.state.end_validate) {
      var startTime = this.state.event_start;
      var endTime = this.state.event_end;
      if (
        startTime !== "" &&
        endTime !== "" &&
        startTime !== undefined &&
        endTime !== undefined
      ) {
        if (startTime >= endTime) {
          swal("Error!", "Invalid start & end time!", "error");
          //$("#event_start_timer").prop("selectedIndex", "");
          //$("#event_end_timer").prop("selectedIndex", "");
          this.setState({ start_validate: false, end_validate: false });
        } else {
          this.setState({ event_start: startTime, event_end: endTime });
          swal(
            "Success!",
            "All Set! Please click Order Now to continue.",
            "success"
          );
        }
      }
    }
  }

  componentWillMount() {
    /*sticky product*/
    $(document).on("click", ".card_edit", function () {
      setTimeout(() => {
        if ($(".popup_product_details").length) {
          var change = document.getElementsByClassName("modal");
          change[0].addEventListener("scroll", function () {
            let productHeight = $(".package_details_right_inner").outerHeight();

            let winTop = $(".modal").scrollTop();
            let csvalue = ($(window).width() - 1610) / 2 + 31;
            let product;
            if ($(window).width() <= 991) {
              product = $(".package_details_right").position().top + 5;
            } else {
              product = $(".package_details_right").position().top + 120;
            }

            if (winTop > product) {
              $(".package_details_right").addClass("mobileSticky");
              $(".package_details_right").css("height", productHeight);
              $(".package_details_right_inner").css({
                right: csvalue + "px",
                left: csvalue + "px",
              });
              $(".package_details_right_inner .pdr_left p").slideUp(50);
            } else {
              $(".package_details_right").removeClass("mobileSticky");
              $(".package_details_right").css("height", "inherit");
              $(".package_details_right_inner .pdr_left p").slideDown(50);
            }
          });
        }
      }, 500);
    });
  }

  componentWillReceiveProps(nextProps) {
    /*Get date list*/
    if (
      this.props.cartdate !== nextProps.cartdate &&
      typeof nextProps.cartdate !== "undefined"
    ) {
      if (nextProps.cartdate.status === "success") {
        let dateArrList = [];
        let commonArrLt = [];
        let timeslotArrLt = [];
        let interval_time = 15;
        let mindate = new Date();
        let maxdate,
          orderDate = "";

        dateArrList = nextProps.cartdate.result_set;
        commonArrLt = nextProps.cartdate.common_data;
        timeslotArrLt = nextProps.cartdate.timeslot_data;
        interval_time = nextProps.cartdate.interval_time;

        let data_max = Object.keys(dateArrList).length;
        let ind = parseInt(data_max) - parseInt(1);
        maxdate = new Date(nextProps.cartdate.result_set[ind].fulldate);

        this.setState({ available_datelist: dateArrList });
        this.setState({ common_datalist: commonArrLt });
        this.setState({ time_slotlist: timeslotArrLt });
        this.setState({ interval_time_sat: interval_time });
        var catergDate = [];
        var disable_dates = [];
        if (dateArrList.length > 0) {
          dateArrList.map((date, index) => {
            if (date.isclose === "No") {
              catergDate.push(new Date(date.fulldate));
              return false;
            } else {
              disable_dates.push(new Date(date.fulldate));
              return false;
            }
          });
        }
        mindate = new Date(catergDate[0]);
        var errorHtml = "";
        if (Object.keys(dateArrList).length > 0) {
          this.setState({ date_errorHtml: "" });
        } else {
          this.setState({ date_errorHtml: errorHtml });
        }

        this.setState(
          {
            catTimeLoading: false,
            catering_date: catergDate,
            disable_dates: disable_dates,
            mindate: mindate,
            orderDate: orderDate,
            maxdate: maxdate,
          },
          () => {
            this.getAvailableTime();
          }
        );
      }
    }

    /*Get time list*/

    if (typeof nextProps.carttime != "undefined") {
      this.setState({ catTimeLoading: true });

      let timeArrList = [];
      let comnTmArrLt = [];

      if (nextProps.carttime.status === "success") {
        timeArrList = nextProps.carttime.result_set;
        comnTmArrLt = nextProps.carttime.common_data;
      }

      let cateringTime =
        Object.keys(timeArrList).length > 0 ? timeArrList[0].timetext : "";
      this.setState({ timeslot_arr: timeArrList });
      this.setState({ common_timelist: comnTmArrLt });

      if (this.state.orderDate === "") {
        this.setState({ catering_time: cateringTime });
        // this.props.sateValChangefun('catering_time', cateringTime);
      }

      if (Object.keys(timeArrList).length > 0) {
        var catergTime = [];
        this.setState({ time_errorHtml: "", catergTime: catergTime });
      }
    }

    if (
      this.props.staticblockData &&
      nextProps.staticblockData &&
      this.props.staticblockData.status === "ok"
    ) {
      nextProps.staticblockData.result_set.map((data, index) => {
        if (data.staticblocks_slug === "delivery-charge-calculation") {
          var delivery_block = data.staticblocks_description;
          this.setState({ delivery_blocks: delivery_block });
        }
      });
    }

    if (
      nextProps.deleteDetail &&
      this.props.deleteDetail !== nextProps.deleteDetail
    ) {
      if (nextProps.deleteDetail.data.status === "ok") {
        swal("Success!", nextProps.deleteDetail.data.message, "success");
        this.loadCartDet();
        // $(".add-breaktime").trigger( "click" );
      } else {
        swal("Error!", nextProps.deleteDetail.data.message, "error");
      }
    }
    if (this.props.productData != nextProps.productData) {
      if (
        nextProps.productData.result_set &&
        nextProps.productData.status == "ok"
      ) {
        this.setState({ productsdetail: nextProps.productData });
        $(".no-recrds-found").hide();
        $(".products_list_content").show();
      } else {
        $(".no-recrds-found").show();
      }
    }
  }

  getSurCharge() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    axios
      .get(apiUrlCtrng + "orders/getSurCharge?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          var surcharge = 0;
          if (
            typeof res.data.total_charge !== "undefined" &&
            res.data.total_charge > 0
          ) {
            surcharge = res.data.total_charge;
          }
          this.setState({ surcharge: surcharge, surchargeLog: res.data.logs });
        }
      });
  }
  getAvailableTime() {
    var timeSlotList = this.state.time_slotlist;
    var cateringDate = this.state.orderDate
      ? this.state.orderDate
      : cookie.load("order_date"); /*
    var errorHtml = "";
    this.setState({'time_errorHtml': errorHtml});*/
    var breakTimeHtml = "";
    this.setState({ break_timeHtml: breakTimeHtml });

    /*&& this.state.selected_outletid !== ''*/
    if (
      timeSlotList &&
      Object.keys(timeSlotList).length > 0 &&
      cateringDate !== ""
    ) {
      var timeSlotArr = this.getdayBasedTimeList();
      if (Object.keys(timeSlotArr).length > 0) {
        timeSlotArr.map(
          (timeLt, index) =>
            (breakTimeHtml +=
              "<option value='" + timeLt + "'>" + timeLt + "</option>")
        );
      }

      var breakTimeDropDown = Parser(breakTimeHtml);
      this.setState({ break_timeHtml: breakTimeDropDown });
      /*this.props.sateValChangefun('break_timeHtml', breakTimeDropDown);*/

      var time_flagkey = this.state.time_flag_key;
      var intervalTime = this.state.interval_time_sat;

      var nextTime = "";
      var prevTime = "";
      var commonTimeList = this.state.common_timelist
        ? this.state.common_timelist
        : "";
      if (
        commonTimeList !== "undefined" &&
        Object.keys(commonTimeList).length > 0
      ) {
        nextTime = commonTimeList.next_time;
        prevTime = commonTimeList.prev_time;
      }
      var postObject = {
        time_slotdata: timeSlotArr,
        interval_time: intervalTime,
        time_flagkey: time_flagkey,
        next_time: nextTime,
        prev_time: prevTime,
      };
      this.props.getCarttimedata({
        time_slotdata: timeSlotArr,
        interval_time: intervalTime,
        time_flagkey: time_flagkey,
        next_time: nextTime,
        prev_time: prevTime,
      });
    }
  }

  getdayBasedTimeList() {
    var timeSltList = this.state.time_slotlist;
    var caterngDate = cookie.load("order_date");
    var selectedDate = new Date(caterngDate);
    var dayintt = selectedDate.getDay();
    var currentslotData = [];
    if (dayintt === 0) {
      currentslotData = timeSltList.sun;
    }
    if (dayintt === 1) {
      currentslotData = timeSltList.mon;
    }
    if (dayintt === 2) {
      currentslotData = timeSltList.tue;
    }
    if (dayintt === 3) {
      currentslotData = timeSltList.wed;
    }
    if (dayintt === 4) {
      currentslotData = timeSltList.thu;
    }
    if (dayintt === 5) {
      currentslotData = timeSltList.fri;
    }
    if (dayintt === 6) {
      currentslotData = timeSltList.sat;
    }
    return currentslotData;
  }

  getCommonSetting(pstCode) {
    var pstCode = pstCode === "" ? this.state.catering_postal_code : "";
    var postCdVal = pstCode !== "" ? "&postal_code=" + pstCode : "";
    axios
      .get(apiUrl + "settings/get_common_settings?app_id=" + appId + postCdVal)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set !== "undefined") {
            this.setState({ settings: res.data.result_set });
          }
        }
      });
  }

  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  changePostalCode(event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;
    var outletIdVl = cookie.load("cateringOutletId");
    cookie.remove("catering_postal_code");
    cookie.remove("catering_address_line");
    if (postalCode !== "" && parseInt(postCdLth) >= 6 && outletIdVl !== "") {
      var urlShringTxt =
        apiUrlV2 +
        "outlets/findOutletZone?app_id=" +
        appId +
        "&availability_id=" +
        cateringId +
        "&postal_code=" +
        postalCode +
        "&postalcode_basedoutlet=yes";

      axios.get(urlShringTxt).then((res) => {
        var custAddress = "";
        if (res.data.status === "ok") {
          var outltResulSet = res.data.result_set;
          if (Object.keys(outltResulSet.postal_code_information).length > 0) {
            var outletAddress =
              outltResulSet.postal_code_information.zip_buno !== ""
                ? outltResulSet.postal_code_information.zip_buno +
                  ", " +
                  outltResulSet.postal_code_information.zip_sname
                : outltResulSet.postal_code_information.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
          }
          cookie.save("orderOutletId", outltResulSet.outlet_id);
          cookie.save(
            "zone_delivery_charge",
            outltResulSet.zone_delivery_charge
          );
          cookie.save(
            "zone_additional_delivery_charge",
            outltResulSet.zone_additional_delivery_charge
          );
          cookie.save("zoneDetailData", outltResulSet);

          this.setState({
            zone_delivery_charge: outltResulSet.zone_delivery_charge,
            zone_additional_delivery_charge:
              outltResulSet.zone_additional_delivery_charge,
          });
          this.props.getCartdatedata({
            dateFlagkey: "START",
            outletId: cookie.load("orderOutletId"),
          });
          $(".postal_code_error").hide();
          this.getCommonSetting(postalCode);
          cookie.save("catering_postal_code", postalCode);
          cookie.save("catering_address_line", custAddress);
          this.setState({ catering_postal_code: postalCode });
          this.setState({ catering_address_line: custAddress });
        } else {
          swal("Error!", "Pleae enter valid Postal code!", "error");
          cookie.save("order_date", "");
          this.setState({ orderDate: "" });
          cookie.save("catering_postal_code", "");
          this.setState({ catering_postal_code: "" });
          cookie.save("catering_address_line", "");
          this.setState({ catering_address_line: "" });
        }
      });
    } else {
      cookie.save("catering_postal_code", postalCode);
      this.setState({
        catering_postal_code: postalCode,
        catering_address_line: "",
      });
    }
  }

  loadcartitems = (loadvalue) => {
    if (loadvalue) {
      this.loadCartDet();
      $(".order_card").first().removeClass("light").addClass("dark");
    }
  };
  loadCartDet() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }

    axios
      .get(apiUrlCtrng + "cart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set === "undefined") {
          } else {
            var cartDetailsMain = res.data.result_set.cart_details;
            var cartItems = res.data.result_set.cart_items;
            var cartcount = res.data.result_set.cart_details.cart_total_items;
            cookie.save(
              "orderOutletId",
              res.data.result_set.cart_details.outlet_id
            );
            cookie.save(
              "order_date",
              moment(
                res.data.result_set.cart_details.cart_catering_order_date
              ).format("YYYY/MM/DD")
            );

            this.setState({
              orderDate: new Date(
                res.data.result_set.cart_details.cart_catering_order_date
              ),
            });
          }
        } else {
          this.setState({ cart_display: "none" });
          //this.props.history.push('/package');
        }

        this.setState(
          {
            cartDetailsMain: cartDetailsMain,
            cartItems: cartItems,
            cartcount: cartcount,
          },
          function () {
            this.makeBrkTimeList();
            this.getSurCharge();
          }.bind(this)
        );
      });
  }

  getCartDetList() {
    var cartDetailsSate = this.state.cartDetailsMain;
    cookie.save("cartDetail", cartDetailsSate);
    var cartBrktimeLst = this.state.cart_brktime_list;
    if (
      cartDetailsSate !== undefined &&
      cartDetailsSate !== null &&
      Object.keys(cartDetailsSate).length > 0 &&
      cartBrktimeLst !== undefined &&
      cartBrktimeLst !== null
    ) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      if (breaktimeEnable === "Yes" && cartBrktimeLst.length > 0) {
        return this.cartBrkTimeList();
      } else {
        return this.cartListOnly();
      }
    }
  }

  handleAddrChange(event) {
    if (event.target.name === "address_line2") {
      this.setState({ catering_address_line2: event.target.value });
    } else if (event.target.name === "floor_no") {
      cookie.save("catering_floor_no", event.target.value);
      this.setState({ catering_floor_no: event.target.value });
    } else if (event.target.name === "unit_no") {
      cookie.save("catering_unit_no", event.target.value);
      this.setState({ catering_unit_no: event.target.value });
    } else if (event.target.name === "specil_note") {
      this.setState({ catering_specil_note: event.target.value });
    } else if (event.target.name === "reward_point") {
      this.setState({ reward_point_val: event.target.value });
    } else if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    }
  }

  cartListOnly() {
    var cartItems = this.state.cartItems;
    return (
      <div className="order_card_body">{this.cartItemlist(cartItems)}</div>
    );
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.cartDetailsMain;
    var cartItems = this.state.cartItems;
    var cartBrkTmList = [];
    if (
      cartDetailsSate !== undefined &&
      cartDetailsSate !== null &&
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      var breaktimeCount = cartDetailsSate.cart_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = [];
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].cart_item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = [];
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].cart_item_breaktime_indexflag,
              break_time_start: cartItems[key].cart_item_breaktime_started,
              break_time_end: cartItems[key].cart_item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }
    this.setState({ cart_brktime_list: cartBrkTmList });
  }

  getbreaktimelist(selcted, currentSelection) {
    var breakTimeHtml = "";
    let existSelectedTimes = [];
    if (
      this.state.cart_brktime_list !== "" &&
      typeof this.state.cart_brktime_list !== "undefined"
    ) {
      this.state.cart_brktime_list.map(function (cart, index) {
        existSelectedTimes.push(
          cart.cart_detaile.break_time_start +
            "-" +
            cart.cart_detaile.break_time_end
        );
      });
    }

    if (this.state.timeslot_arr) {
      breakTimeHtml += "<option value=''>Select</option>";

      this.state.timeslot_arr.map((option, index) => {
        if (option.isclose === "No") {
          //   breakTimeHtml +=
          //     "<option value='" +
          //     option.timetext +
          //     "' disabled>" +
          //     option.timetext +
          //     "</option>";
          // } else {
          if (selcted !== "" && selcted === option.timetext) {
            breakTimeHtml +=
              "<option value='" +
              option.timetext +
              "' selected='selected'>" +
              option.timetext +
              "</option>";
          } else {
            let diableTime = this.timevalidataion(
              option.timetext,
              existSelectedTimes,
              currentSelection
            );
            diableTime = diableTime !== undefined ? diableTime : "";
            breakTimeHtml +=
              "<option value='" +
              option.timetext +
              "' " +
              diableTime +
              ">" +
              option.timetext +
              "</option>";
          }
        }
      });
    }
    return breakTimeHtml;
  }

  timevalidataion(timetext, existSelectedTimes, currentSelection) {
    var TodayDate = new Date();
    var currentTime = timetext.split(":");
    TodayDate.setHours(currentTime[0]);
    TodayDate.setMinutes(currentTime[1]);
    if (currentSelection !== "" && typeof currentSelection !== "undefined") {
      const index = existSelectedTimes.indexOf(currentSelection);
      if (index > -1) {
        existSelectedTimes.splice(index, 1);
      }
    }
    var disabled = "";
    existSelectedTimes.map(function (cartTime) {
      var cartTimesplit = cartTime.split("-");
      var fromTime = cartTimesplit[0].split(":");
      var toTime = cartTimesplit[1].split(":");

      var ExistDateFrom = new Date();
      ExistDateFrom.setHours(fromTime[0]);
      ExistDateFrom.setMinutes(fromTime[1]);

      var ExistDateTo = new Date();
      ExistDateTo.setHours(toTime[0]);
      ExistDateTo.setMinutes(toTime[1]);
      if (currentSelection !== "" && typeof currentSelection !== "undefined") {
      }
      if (TodayDate >= ExistDateFrom && TodayDate <= ExistDateTo) {
        disabled = 1;
      }
    });
    if (disabled === 1) {
      return "disabled";
    }
  }

  cartBrkTimeList() {
    ReactTooltip.rebuild();
    var cartBrktimeLst = this.state.cart_brktime_list;

    var index = 0;
    let existSelectedTimes = [];
    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => {
      if (indx === 0) {
        var classes = "dark";
      } else {
        var classes = "light";
      }
      var order_no = parseInt(indx) + 1;
      existSelectedTimes.push(
        cartBrk["cart_detaile"].break_time_start +
          "-" +
          cartBrk["cart_detaile"].break_time_end
      );
      var currentSelection =
        cartBrk["cart_detaile"].break_time_start +
        "-" +
        cartBrk["cart_detaile"].break_time_end;
      const imageName = this.getImageNameAlt();
      var CartItemList = "";
      cartBrk["cart_items"].map(function (item) {
        CartItemList = CartItemList + item.cart_item_id + ",";
      });

      return (
        <div
          className={"order_card " + classes}
          key={indx}
          data-index=""
          id={"tileSlot_" + indx}
        >
          <div className="order_card_head">
            Order Details {"-"} {order_no}
            <Link
              onClick={this.deleteCartItm.bind(
                this,
                cartBrk["cart_items"][0]["cart_item_id"],
                "alert"
              )}
              to="#"
            >
              <img src={imageName} alt="delete" />
            </Link>
          </div>
          <div className="order_card_body">
            <div className="event_timing">
              <div className="form-group">
                <div className="input_field">
                  <label>Event Start Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control start_time"
                      id="event_start_timer"
                      name="start_time"
                      data-itemid={CartItemList}
                      data-rootid={"tileSlot_" + indx}
                      onChange={this.handleUpdateChange.bind(this)}
                    >
                      {Parser(
                        this.getbreaktimelist(
                          cartBrk["cart_detaile"].break_time_start,
                          currentSelection
                        )
                      )}
                    </select>
                    <span
                      className="error start_time_error"
                      style={{ display: "none" }}
                    >
                      This Field is required!
                    </span>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warning} alt="warning" />
                </span>
              </div>
              <div className="form-group">
                <div className="input_field">
                  <label>Event End Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control end_time"
                      value={this.props.reason}
                      id="event_end_timer"
                      name="end_time"
                      data-itemid={CartItemList}
                      data-rootid={"tileSlot_" + indx}
                      onChange={this.handleUpdateChange.bind(this)}
                    >
                      {Parser(
                        this.getbreaktimelist(
                          cartBrk["cart_detaile"].break_time_end,
                          currentSelection
                        )
                      )}
                    </select>
                    <span
                      className="error end_time_error"
                      style={{ display: "none" }}
                    >
                      This Field is required!
                    </span>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warning} alt="warning" />
                </span>
              </div>
            </div>

            <div className="products_info">
              {this.cartItemlist(cartBrk["cart_items"], index)}
            </div>
          </div>
        </div>
      );
      index++;
    });
    // this.setState({existSelectedTimes:existSelectedTimes})
    return cartBrktmhtml;
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1}>
          <div className="products_info_item" id={indx1}>
            <label>
              {" "}
              <span
                className="cart_indvl_price"
                data-carttotl={cartLst.cart_item_unit_price}
              >
                {showPriceValue(cartLst.cart_item_total_price)}
              </span>
              <Link
                to="#"
                onClick={this.togglePopup.bind(
                  this,
                  cartLst.cart_item_product_id,
                  cartLst,
                  indx1
                )}
                className="card_edit"
              >
                <img src={edit_dark} alt="edit" width="19px" />
              </Link>
              {stripslashes(cartLst.cart_item_product_name)}
            </label>

            {this.loadModifierItems(
              cartLst.cart_item_type,
              cartLst.modifiers,
              cartLst.set_menu_component,
              cartLst.cart_item_qty,
              cartLst.cart_item_unit_price,
              cartLst.cart_item_id,
              cartLst.cart_item_product_id,
              cartLst.cart_item_min_qty
            )}

            {/* <p>
              {Object.keys(cartLst.setup).length > 0
                ? cartLst.setup[0].cart_setup_name +
                  ":" +
                  showPriceValue(cartLst.setup[0].cart_setup_price)
                : ""}
            </p> */}
          </div>

          <div className="pkgslist">
            {this.checkSettup(cartLst.setup, "Buffet") && (
              <div className="pkgsli_row row">
                <div className="col-sm-8 col-7">
                  <label>Buffet:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Buffet")}
                  </div>
                </div>
                <div className="col-sm-4 col-5 pkgsli_right text-right">
                  <span className="price">
                    {showPriceValue(
                      this.settuptotalPrice(cartLst.setup, "Buffet")
                    )}
                  </span>
                  <a
                    className="remove"
                    href="javascript:void(0)"
                    onClick={this.removeCartIndividual.bind(
                      this,
                      cartLst.cart_item_id,
                      "",
                      "setup",
                      "Buffet"
                    )}
                  >
                    <img src={crossImg} alt="" />
                  </a>
                </div>
              </div>
            )}
            {this.checkSettup(cartLst.setup, "Delivery") && (
              <div className="pkgsli_row row">
                <div className="col-sm-8 col-7">
                  <label>Delivery:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Delivery")}
                  </div>
                </div>
                <div className="col-sm-4 col-5 pkgsli_right text-right">
                  <span className="price">
                    {showPriceValue(
                      this.settuptotalPrice(cartLst.setup, "Delivery")
                    )}
                  </span>
                  <a
                    className="remove"
                    href="javascript:void(0)"
                    onClick={this.removeCartIndividual.bind(
                      this,
                      cartLst.cart_item_id,
                      "",
                      "setup",
                      "Delivery"
                    )}
                  >
                    <img src={crossImg} alt="" />
                  </a>
                </div>
              </div>
            )}
            {this.checkSettup(cartLst.setup, "Self Collection") && (
              <div className="pkgsli_row row">
                <div className="col-sm-8 col-7">
                  <label>Self Collection:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Self Collection")}
                  </div>
                </div>
                <div className="col-sm-4 col-5 pkgsli_right text-right">
                  <span className="price">
                    {showPriceValue(
                      this.settuptotalPrice(cartLst.setup, "Self Collection")
                    )}
                  </span>
                  <a
                    className="remove"
                    href="javascript:void(0)"
                    onClick={this.removeCartIndividual.bind(
                      this,
                      cartLst.cart_item_id,
                      "",
                      "setup",
                      "Self Collection"
                    )}
                  >
                    <img src={crossImg} alt="" />
                  </a>
                </div>
              </div>
            )}
            {cartLst.equipment !== "" &&
              cartLst.equipment !== undefined &&
              cartLst.equipment.length > 0 && (
                <div className="pkgsli_row row">
                  <div className="col-sm-8 col-7">
                    <label>Equipment:</label>
                    <div className="pkgslist_addition">
                      {this.loadSettup(cartLst.equipment, "Equipment")}
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 pkgsli_right text-right">
                    <span className="price">
                      {showPriceValue(
                        this.settuptotalPrice(cartLst.equipment, "Equipment")
                      )}
                    </span>
                    <a
                      className="remove"
                      href="javascript:void(0)"
                      onClick={this.removeCartIndividual.bind(
                        this,
                        cartLst.cart_item_id,
                        "",
                        "equipment",
                        "equipment"
                      )}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
              )}
            {cartLst.addons_setup !== "" &&
              cartLst.addons_setup !== undefined &&
              cartLst.addons_setup.length > 0 && (
                <div className="pkgsli_row">
                  <label>ADD-ONS:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.addons_setup, "Addons")}
                  </div>
                </div>
              )}
          </div>
          {/* package div end */}

          {cartLst.cart_item_special_notes !== ""
            ? this.specialNotes(cartLst)
            : ""}
        </div>
      ));
      return cartlsthtml;
    }
  }

  loadSettup(setup, type) {
    let check_settup = this.checkSettup(setup, type);
    if (type === "Equipment") {
      return setup.map((settup, index) => {
        return (
          <p key={index}>
            {settup.cart_equipment_qty} X {settup.cart_equipment_description} (+
            {settup.cart_equipment_price})
          </p>
        );
      });
    } else if (type === "Addons") {
      return setup.map((settup, index) => {
        return (
          <div className="row pkgslist_additionrow" key={index}>
            <div className="col-sm-8 col-7">
              <label>{settup.cart_addon_setup_title}</label>
              <div className="pkgslist_subaddition">
                {this.loadAddonValues(settup.addons_setup_values)}
              </div>
            </div>
            <div className="col-sm-4 col-5 pkgsli_right text-right">
              <span className="price">
                {showPriceValue(settup.cat_addon_setup_amount)}
              </span>
              <a
                className="remove"
                href="javascript:void(0)"
                onClick={this.removeCartIndividual.bind(
                  this,
                  settup.cart_addon_setup_cart_item_id,
                  settup.cart_addon_setup_primary_key,
                  "addons_setup",
                  "addons_setup"
                )}
              >
                <img src={crossImg} alt="" />
              </a>
            </div>
          </div>
        );
      });
    } else {
      if (check_settup) {
        return setup.map((settup, index) => {
          if (settup.cart_setup_type === type) {
            return (
              <p key={index}>
                {settup.cart_setup_name} ({settup.cart_setup_price}){" "}
              </p>
            );
          }
        });
      }
    }
  }
  checkSettup(setup, type) {
    let count = 0;
    setup.map((settup) => {
      if (settup.cart_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkSettupList(setup, type) {
    let count = 0;
    setup.map((settup) => {
      if (settup.cart_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }
  settuptotalPrice(setup, type) {
    let totalPrice = 0;
    if (type === "Equipment") {
      setup.map((settup) => {
        totalPrice += parseFloat(settup.cart_equipment_totalprice);
      });
    } else {
      setup.map((settup) => {
        if (settup.cart_setup_type === type) {
          totalPrice += parseFloat(settup.cart_setup_tatalprice);
        }
      });
    }
    return totalPrice;
  }
  removeCartIndividual(cartItmId, cartPryId, type, setupType = "") {
    if (
      cartItmId !== "" &&
      (cartPryId !== "" ||
        type === "setup" ||
        type === "equipment" ||
        type === "addons_setup") &&
      type !== ""
    ) {
      // showLoader("cartlist_main_divcls", "class");
      var postCrtObject = {};
      postCrtObject = {
        app_id: appId,
        cart_item_id: cartItmId,
        p_id: cartPryId,
        type: type,
        setupType: setupType,
      };
      if (typeof cookie.load("UserId") === "undefined") {
        postCrtObject["reference_id"] = getReferenceID();
      } else {
        postCrtObject["customer_id"] = cookie.load("UserId");
      }

      axios
        .post(
          apiUrlCtrng + "cart/delete_individual",
          qs.stringify(postCrtObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.loadCartDet();
            // showCustomAlert("success", "Nice! Products updated to your cart");
            swal("Success!", "Nice! Products updated to your cart", "success");
          } else {
            // showCustomAlert("error", "Sorry! Products can`t update your cart");
            swal("Error!", "Sorry! Products can`t update your cart", "error");
            /* this.handleShowAlertFun(
              "error",
              "Sorry! Products can`t update your cart"
            ); */
          }
          // hideLoader("cartlist_main_divcls", "class");
        })
        .catch(function (error) {
          console.log(error);
          swal("Error!", "something went wrong", "error");
          // showCustomAlert("error", "something went wrong");
          //  this.handleShowAlertFun("error", "something went wrong");
          // hideLoader("cartlist_main_divcls", "class");
        });
    }
  }
  loadAddonValues(AddonValues) {
    return AddonValues.map((addonval, index) => {
      return (
        <p key={index}>
          {addonval.cart_addon_setup_val_title} (
          {addonval.cart_addon_setup_val_qty}X)
        </p>
      );
    });
  }

  loadModifierItems(
    itemType,
    modifiers,
    combo,
    cart_item_qty,
    cart_item_unit_price,
    cart_item_id,
    cart_item_product_id,
    cart_item_min_qty
  ) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html =
      '<div class="products_info_options"><div class="products_info_options_wrap">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p> <p>" +
          stripslashes(modval) +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            stripslashes(comboName) +
            ":</b> </p><p>" +
            stripslashes(comboVal) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            stripslashes(comboName) +
            ":</b> </p><p>" +
            stripslashes(comboVal) +
            "</p> ";
        }
      }
      html +=
        "</div><span class='counter cart-counter'  data-unitprice=" +
        cart_item_unit_price +
        " data-productid=" +
        cart_item_product_id +
        " data-itemid=" +
        cart_item_id +
        " data-minqty=" +
        cart_item_min_qty +
        " ><button class='qty_minus cart_qty_minus' data-type='minus'>-</button><span class='product_quantity'>" +
        cart_item_qty +
        "</span><button class='qty_plus cart_qty_plus' data-type='plus'>+</button></span></div>";
      var reactElement = Parser(html);
      return reactElement;
    } else {
      html +=
        "</div><span class='counter cart-counter'  data-unitprice=" +
        cart_item_unit_price +
        " data-productid=" +
        cart_item_product_id +
        " data-itemid=" +
        cart_item_id +
        " data-minqty=" +
        cart_item_min_qty +
        " ><button class='qty_minus cart_qty_minus' data-type='minus'>-</button><span class='product_quantity'>" +
        cart_item_qty +
        "</span><button class='qty_plus cart_qty_plus' data-type='plus'>+</button></span>";
      var reactElement = Parser(html);
      return reactElement;
    }
    this.setState({ selectpaxcount: cart_item_qty });
  }

  showComboModifiersValues(product_details) {
    var mod_length = product_details.modifiers.length;
    var html = " ";
    for (var m = 0, leng = mod_length; m < leng; m++) {
      var cart_mod_name = product_details.modifiers[m]["cart_modifier_name"];
      var mod_val_leng = product_details.modifiers[m].modifiers_values.length;
      html +=
        "<div class='modifier_values_list_wrap'><p>" +
        stripslashes(cart_mod_name) +
        "</p>";
      html += "<div class='modifier_values_list'>";
      for (var v = 0, lenth = mod_val_leng; v < lenth; v++) {
        var cart_modifier_name =
          product_details.modifiers[m].modifiers_values[v].cart_modifier_name;
        var cart_modifier_price =
          product_details.modifiers[m].modifiers_values[v].cart_modifier_price;
        var modifier_value_price =
          parseFloat(cart_modifier_price) > 0
            ? "&nbsp;(" + cart_modifier_price + ")"
            : "";
        html +=
          "<p>" +
          stripslashes(cart_modifier_name) +
          modifier_value_price +
          "</p>";
      }
      html += "</div></div>";
    }

    return html;
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
          if (combos[r].modifiers.length > 0) {
            html += this.showComboModifiersValues(combos[r]);
          }
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p class='modName'><b>" +
          stripslashes(modName) +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }
  checkPostalcode() {
    var catering_postal_code = $("#postal_code").val();
    var order_date = this.state.orderDate;
    if (catering_postal_code === "") {
      $("body, html").animate(
        {
          scrollTop: $("#postal_code").offset().top - 80,
        },
        300
      );
      $(".postal_code_error").show();
    } else {
      if (
        typeof cookie.load("UserId") === "undefined" ||
        cookie.load("UserId") === ""
      ) {
        window.$.magnificPopup.open({
          items: {
            src: "#login-popup",
          },
          type: "inline",
        });
        cookie.save("loginpopupTrigger", "fromchecatering", { path: "/" });
        // cookie.save("redirect_page", "/package/checkout");
      } else {
        //this.updateCartPackage(this.state.productsdetail.result_set, 0, 0);
        this.props.history.push("/package/checkout");
        // this.loadCartDet();
      }
    }
  }

  deleteCartItm(cart_item_id, checkalert) {
    if (checkalert === "alert") {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this item?",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (cart_item_id) {
            // this.props.deleteCartDetail(cart_item_id);
            var postCrtObject = {
              app_id: appId,
              cart_item_id: cart_item_id,
              cartAction: "Delete",
            };
            if (typeof cookie.load("UserId") === "undefined") {
              postCrtObject["reference_id"] = getReferenceID();
            } else {
              postCrtObject["customer_id"] = cookie.load("UserId");
            }

            axios
              .post(apiUrlCtrng + "cart/delete", qs.stringify(postCrtObject))
              .then((res) => {
                if (res.data.status === "ok") {
                  // if($('.order_card').length <= 1) { this.changeImage() };

                  this.loadCartDet();
                  swal(
                    "Success!",
                    "Nice! Products updated to your cart",
                    "success"
                  );
                } else {
                  swal(
                    "Error!",
                    "Sorry! Products can`t update your cart",
                    "error"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                swal("Error!", "something went wrong", "error");
              });
          } else {
            swal("Error!", "You haven’t added any items yet !", "error");
          }
        }
      });
    } else {
      if (cart_item_id) {
        // this.props.deleteCartDetail(cart_item_id);
        var postCrtObject = {
          app_id: appId,
          cart_item_id: cart_item_id,
          cartAction: "Delete",
        };
        if (typeof cookie.load("UserId") === "undefined") {
          postCrtObject["reference_id"] = getReferenceID();
        } else {
          postCrtObject["customer_id"] = cookie.load("UserId");
        }

        axios
          .post(apiUrlCtrng + "cart/delete", qs.stringify(postCrtObject))
          .then((res) => {
            if (res.data.status === "ok") {
              this.loadCartDet();
              swal(
                "Success!",
                "Nice! Products updated to your cart",
                "success"
              );
            } else {
              swal("Error!", "Sorry! Products can`t update your cart", "error");
            }
          })
          .catch(function (error) {
            swal("Error!", "something went wrong", "error");
          });
      } else {
        swal("Error!", "You haven’t added any items yet !", "error");
      }
    }
  }

  downloadCartItem() {
    var customerId = cookie.load("UserId");
    var customerEmail = cookie.load("UserEmail");
    var promotionAmount = this.state.promotion_amount;
    if (!customerEmail) {
      swal("Error", "Please login to download Quotation", "error").then(
        (value) => {
          window.$.magnificPopup.open({
            items: {
              src: "#login-popup",
            },
            type: "inline",
          });
        }
      );
      cookie.save("downloadQuote", "yes");
      return false;
    }
    var comSettings = this.state.settings;
    var gstPar = 0;
    if (Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
    }
    var outletIdVl = cookie.load("orderOutletId");

    var urlParam =
      "&customer_id=" +
      customerId +
      "&request_email=" +
      customerEmail +
      "&client_gst_charge=" +
      gstPar +
      "&promotion=" +
      promotionAmount +
      "&outlet_id=" +
      outletIdVl;
    $(".download_quote").addClass("loading loading-20");
    axios
      .get(apiUrlCtrng + "catering/downloadQtnItems?app_id=" + appId + urlParam)
      .then((res) => {
        if (res.data.status === "ok") {
          swal("Success", res.data.message, "success");
        } else {
          swal("Error", res.data.message, "error");
        }
        cookie.remove("downloadQuote");
        $(".download_quote").removeClass("loading loading-20");
      });
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.cart_item_special_notes;

    return (
      <div className="products_info_item">
        <label className="title">Special Notes</label>
        <div className="products_info_options">
          <p>{special_not}</p>
        </div>
      </div>
    );
  }

  togglePopup(product_id, cartLst) {
    this.loadCartDet();
    this.assignExistSettup(cartLst);
    var valid = true;
    var order_card = $(".order_card");
    var length = $(".order_card").length;
    for (var i = 0; i <= length; i++) {
      order_card.eq(i).attr("data-index", i);
    }

    var postal_code = $("#postal_code").val();
    if (postal_code === "") {
      $(".product_orders").fadeIn();
      valid = false;
      $(".postal_code_error").show();
      $("body, html").animate(
        {
          scrollTop: $("#postal_code").offset().top - 80,
        },
        300
      );
      return false;
    } else {
      valid = true;
    }

    var length = $(".order_card").length;
    for (var i = 0; i <= length; i++) {
      order_card.eq(i).attr("data-index", i);
    }
    if (cookie.load("order_date")) {
      $(".order_date_error").hide();
      valid = true;
    } else {
      $(".product_orders").fadeIn();
      $(".order_date_error").show();
      $("body, html").animate(
        {
          scrollTop: $(".order_date").offset().top - 80,
        },
        300
      );
      valid = false;
      return false;
    }

    if (
      $(".dark").find("#event_start_timer").val() === "" ||
      $(".dark").find("#event_start_timer").val() === null
    ) {
      $(".start_time_error").show();
      valid = false;

      $("body, html").animate(
        {
          scrollTop: $(".addition-breaktime").offset().top - 80,
        },
        300
      );
    } else {
      $(".start_time_error").hide();
    }
    if (
      $(".dark").find("#event_end_timer").val() === "" ||
      $(".dark").find("#event_end_timer").val() === null
    ) {
      $(".end_time_error").show();
      $(".product_orders").fadeIn();
      $("body, html").animate(
        {
          scrollTop: $(".addition-breaktime").offset().top - 80,
        },
        300
      );
      valid = false;
    } else {
      $(".end_time_error").hide();
    }

    if (valid) {
      $(".dark").find(".required").removeClass("required");
      this.setState((prevState) => ({
        modalPopup: !prevState.modalPopup,
      }));
      const parameter = "&product_id=" + product_id + "&product_is_combo='Yes'";
      if (cartLst) {
        var cart_item_ids = cartLst.cart_item_id;
        this.setState({
          productGrandTotal: cartLst.cart_item_total_price,
          cartLst: cartLst,
          currentSettup: this.currentSettup(cartLst),
          CurrentEquipment: cartLst.equipment,
          cart_item_ids: cart_item_ids,
          product_id: product_id,
          //product_setup: cartLst.setup
        });
      }
      this.props.getProduct(parameter);
      var event_start = $(".dark").find("#event_start_timer").val();
      var event_end = $(".dark").find("#event_end_timer").val();
      this.setState({
        event_start: event_start,
        event_end: event_end,
        productsdetail: this.props.productData,
        selectpaxcount: cartLst.cart_item_qty,
        productGrandTotal: cartLst.cart_item_total_price,
        cartLst: cartLst,
        cart_item_ids: cart_item_ids,
        product_id: product_id,
        product_modifiers_price: 0,
      });
    }
  }

  assignExistSettup(cartLst) {
    var indKey = "Index_0";
    var deliveryArray = [];
    var buffetArray = [];
    var selfcollectionArr = [];
    var equipmentArray = [];
    var AddonsArr = [];
    cartLst.setup.map(function (item) {
      var values =
        item.cart_setup_id +
        "~~" +
        item.cart_setup_name +
        "~~" +
        item.cart_setup_description +
        "~~" +
        item.cart_setup_price +
        "~~" +
        item.cart_setup_qty +
        "~~" +
        item.cart_setup_type;
      if (item.cart_setup_type === "Delivery") {
        deliveryArray.push(values);
      } else if (item.cart_setup_type === "Buffet") {
        buffetArray.push(values);
      } else if (item.cart_setup_type === "Self Collection") {
        var values =
          item.cart_setup_id +
          "~~" +
          item.cart_setup_name +
          "~~" +
          item.cart_setup_description +
          "~~" +
          item.cart_setup_price +
          "~~" +
          item.cart_setup_qty +
          "~~" +
          item.cart_setup_type;
        selfcollectionArr.push(values);
      }
    });
    cartLst.equipment.map(function (item) {
      var values =
        item.cart_equipment_id +
        "~~" +
        item.cart_equipment_name +
        "~~" +
        item.cart_equipment_description +
        "~~" +
        item.cart_equipment_price +
        "~~1" +
        "~~Equipment~~" +
        item.cart_equipment_qty;
      equipmentArray.push(values);
    });

    if (
      cartLst.addons_setup !== "" &&
      typeof cartLst.addons_setup !== undefined
    ) {
      cartLst.addons_setup.map(function (item, index) {
        AddonsArr["addon_" + index] = [];
        item.addons_setup_values.map(function (itemValue) {
          var values =
            item.cart_addon_setup_id +
            "~~" +
            item.cart_addon_setup_title +
            "~~" +
            item.cart_addon_setup_selection +
            "~~" +
            itemValue.cart_addon_setup_val_id +
            "~~" +
            itemValue.cart_addon_setup_val_title +
            "~~" +
            itemValue.cart_addon_setup_val_desc +
            "~~" +
            itemValue.cart_addon_setup_val_price +
            "~~" +
            itemValue.cart_addon_setup_val_qty;
          AddonsArr["addon_" + index].push(values);
        });
      });
    }

    this.setState({ product_setup: [] }, function () {
      var sateProductSetup = this.state.product_setup;
      sateProductSetup[indKey] = [];
      sateProductSetup[indKey]["delivery"] = deliveryArray;
      sateProductSetup[indKey]["buffet"] = buffetArray;

      var sateProductAddon = this.state.product_addons;
      sateProductAddon[indKey] = [];
      sateProductAddon[indKey] = AddonsArr;
      this.setState({
        product_setup: sateProductSetup,
        equipmentSetup: equipmentArray,
        product_setup_selfcollecdction: selfcollectionArr,
        product_addons: sateProductAddon,
      });
    });
  }

  currentSettup(cartLst) {
    let current = "";
    if (
      cartLst !== "" &&
      typeof cartLst !== undefined &&
      cartLst.setup !== "" &&
      typeof cartLst.setup !== undefined
    ) {
      cartLst.setup.map(function (item) {
        if (
          item.cart_setup_type === "Buffet" ||
          item.cart_setup_type === "Delivery"
        ) {
          current = item.cart_setup_type;
        }
      });
    }
    return current;
  }
  closeToggle() {
    this.setState({
      modalPopup: false,
      productGrandTotal: 0,
    });
    const parameter =
      "&product_id=" + this.state.product_id + "&product_is_combo='Yes'";
    this.props.getProduct(parameter);
  }
  handleSpecialInstruction(event) {
    var remarks = event.target.value;
    this.setState({ specialInstruction: remarks });
  }

  updateProductPax(indFlg, type, selectpaxcount, minpaxcount) {
    minpaxcount = parseInt(minpaxcount) > 0 ? parseInt(minpaxcount) : 5;
    selectpaxcount = parseInt(selectpaxcount);

    let pax_increase = 10;

    if (type === "minus") {
      var tempVl =
        selectpaxcount > minpaxcount ? selectpaxcount - pax_increase : 0;
      if (tempVl >= pax_increase) {
        selectpaxcount = selectpaxcount - pax_increase;
      }
    } else {
      selectpaxcount = selectpaxcount + pax_increase;
    }

    var indKey = "Index_" + indFlg;
    var proFieldArr = [];
    var stateProFldDet = this.state.productfeilsdetail;
    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet) ? [] : stateProFldDet[indKey];
    }
    stateProFldDet[indKey] = proFieldArr;
    this.setState(
      { productfeilsdetail: stateProFldDet, selectpaxcount: selectpaxcount },
      function () {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }
  calculatedOverAllAmount(indFlg) {
    var proFieldArr = [];
    var proDetailArr = [];
    var AddonsArr = [];
    var SetupArr = [];
    var proMdfPrice = 0;
    var stateProDet = this.state.productsdetail;
    var stateProMdfPrice = this.state.product_modifiers_price;

    var stateindividualModPrice = parseFloat(this.state.individualModPrice);
    let excludepricemultiplypax_flag = parseInt(
      this.state.excludepricemultiplypax_flag
    );

    //console.log(stateProMdfPrice,stateindividualModPrice,excludepricemultiplypax_flag,'stateProMdfPrice,stateindividualModPrice,excludepricemultiplypax_flag');

    var stateProFldDet = this.state.productfeilsdetail;
    var productAddons = this.state.product_addons;
    var productSetup = this.state.product_setup;
    var selfCollection = this.state.product_setup_selfcollecdction;
    var equipmentSetup = this.state.equipmentSetup;
    var indKey = "Index_" + indFlg;
    var imageSource = "";
    var productImage = "";

    if (Object.keys(stateProDet).length > 0) {
      //var temArr = (!(indKey in stateProDet)) ? [] : stateProDet[indKey];

      // if(Object.keys(temArr).length > 0) {
      proDetailArr = stateProDet["result_set"];
      // imageSource = (Object.keys(temArr['commonurl']).length > 0) ? temArr['commonurl'].image_source:'';

      // }
    }

    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet) ? [] : stateProFldDet[indKey];
    }

    if (Object.keys(productAddons).length > 0) {
      AddonsArr = !(indKey in productAddons) ? [] : productAddons[indKey];
    }

    if (Object.keys(productSetup).length > 0) {
      SetupArr = !(indKey in productSetup) ? [] : productSetup[indKey];
    }

    if (stateProMdfPrice !== undefined && stateProMdfPrice !== null) {
      proMdfPrice = parseFloat(stateProMdfPrice);
    }

    var minpaxcount = 0;
    var minPaxAmount = 0;
    var selectpaxcount = 0;
    var productPrice = 0;
    var productSubTotal = 0;
    var productAddonsAmount = 0;
    var productSetupAmount = 0;
    var productEquipmentAmount = 0;
    var productGrandTotal = 0;
    if (Object.keys(proDetailArr).length > 0) {
      productImage =
        proDetailArr.product_thumbnail === ""
          ? noimage
          : imageSource + "/" + proDetailArr.product_thumbnail;

      minpaxcount = proDetailArr.product_min_pax;
      minPaxAmount = proDetailArr.product_min_amount;

      selectpaxcount = !("selectpaxcount" in proFieldArr)
        ? proDetailArr.product_min_pax
        : proFieldArr["selectpaxcount"];

      /*productPrice = ((parseFloat(this.state.productGrandTotal) / parseInt(this.state.selectpaxcount)) + parseFloat(proMdfPrice));*/
      productPrice =
        parseFloat(proDetailArr[0].product_price) + parseFloat(proMdfPrice);

      productSubTotal =
        parseFloat(this.state.selectpaxcount) * parseFloat(productPrice);

      for (var key in AddonsArr) {
        var AddonsValue = AddonsArr[key];
        for (var key2 in AddonsValue) {
          var ExpVarbl = AddonsValue[key2];
          var mytemparr = ExpVarbl.split("~~");
          if (parseInt(mytemparr[7]) > 0) {
            productAddonsAmount +=
              parseInt(mytemparr[7]) * parseFloat(mytemparr[6]);
          }
        }
      }

      for (var key in SetupArr) {
        var SetupValue = SetupArr[key];
        if (Object.keys(SetupValue).length > 0) {
          for (var key2 in SetupValue) {
            var ExpVarbl = SetupValue[key2];
            var mytemparr = ExpVarbl.split("~~");
            if (parseInt(mytemparr[4]) > 0) {
              productSetupAmount +=
                parseInt(mytemparr[4]) * parseFloat(mytemparr[3]);
            }
          }
        }
      }

      if (
        selfCollection !== "" &&
        typeof selfCollection !== undefined &&
        selfCollection.length > 0
      ) {
        selfCollection.forEach(function (collection) {
          var collectionValue = collection.split("~~");
          if (parseInt(collectionValue[4]) > 0) {
            productSetupAmount +=
              parseInt(collectionValue[4]) * parseFloat(collectionValue[3]);
          }
        });
      }

      if (
        equipmentSetup !== "" &&
        typeof equipmentSetup !== undefined &&
        equipmentSetup.length > 0
      ) {
        equipmentSetup.forEach(function (equipment) {
          var equipmentValue = equipment.split("~~");
          if (parseInt(equipmentValue[6]) > 0) {
            productEquipmentAmount +=
              parseInt(equipmentValue[6]) * parseFloat(equipmentValue[3]);
          }
        });
      }

      productGrandTotal =
        parseFloat(productSubTotal) +
        parseFloat(this.state.individualModPrice) +
        parseFloat(productAddonsAmount) +
        parseFloat(productSetupAmount) +
        parseFloat(productEquipmentAmount);

      proFieldArr["product_id"] = proDetailArr.product_id;
      proFieldArr["product_name"] = stripslashes(proDetailArr.product_name);
      proFieldArr["product_sku"] = stripslashes(proDetailArr.product_sku);
      proFieldArr["product_slug"] = proDetailArr.product_slug;
      proFieldArr["product_disable_cart"] = proDetailArr.product_disable_cart;
      proFieldArr["productImage"] = productImage;
      proFieldArr["minpaxcount"] = minpaxcount;
      proFieldArr["minPaxAmount"] = minPaxAmount;
      proFieldArr["selectpaxcount"] = selectpaxcount;
      proFieldArr["productPrice"] = productPrice;
      proFieldArr["productSubTotal"] = productSubTotal;
      proFieldArr["productAddonsAmount"] = productAddonsAmount;
      proFieldArr["productSetupAmount"] = productSetupAmount;
      proFieldArr["productGrandTotal"] = productGrandTotal;
      stateProFldDet[indKey] = proFieldArr;

      this.setState({
        productfeilsdetail: stateProFldDet,
        productGrandTotal: productGrandTotal,
        product_modifiers_price: 0,
        newproductTotalPrice:
          parseFloat(productSubTotal) +
          parseFloat(this.state.individualModPrice),
        newproductUnitPrice: productSubTotal,
      });
    }
    return false;
  }

  checkMinMaxMod(
    menuCmpId,
    proId,
    type,
    productId,
    modId,
    custom_logo,
    countt
  ) {
    var errorFlg = "No";
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    $_this.find(".mdfr_list_divlcs").each(function () {
      var checked_count = 0;
      var modDivId = $(this).attr("id");
      var modMin = $(this).attr("data-mdfmin");
      var modMax = $(this).attr("data-mdfmax");
      $(this)
        .find(".modifiers_valuecheck")
        .each(function () {
          if ($(this).is(":checked")) {
            $_this.removeClass("package-error");
            checked_count += 1;
          }
        });
      if (parseInt(modMin) > parseInt(checked_count)) {
        errorFlg = "Yes";
        var errorTxt = "Please select minimum " + modMin + " item(s).";
        $(this).find(".pkgerror_txt").html(errorTxt);
        $_this.addClass("package-error");
        $(this).find(".pkgerror_txt").show();
        return false;
      }

      if (parseInt(modMax) < parseInt(checked_count)) {
        errorFlg = "Yes";
        var errorTxt =
          "Sorry!. maximum of selected item is " + modMax + " only.";
        $(this).find(".pkgerror_txt").html(errorTxt);
        $_this.removeClass("package-error");
        $(this).addClass("pkgerror_out");
        $(this).find(".pkgerror_txt").show();
        $(this)
          .find(".mod_" + modId)
          .prop("checked", false);
        return false;
      } else {
        $(this).find(".pkgerror_txt").hide();
      }
    });
  }

  incComboQty(menuCmpId, proId, type, productId, modId, custom_logo, countt) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    if (type != "cancel") {
      if (type === "simple") {
        $(".indvcombo" + "-" + menuCmpId + "-" + proId).addClass("selected");
      }
    }

    var intValInc = 1;
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-packageminlimit") != ""
        ? $_this.closest(".main_combo_div").attr("data-packageminlimit")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-packagemaxlimit") != ""
        ? $_this.closest(".main_combo_div").attr("data-packagemaxlimit")
        : "0";

    let componentapplyprice_flag = $_this
      .closest(".main_combo_div")
      .attr("data-componentapplyprice");
    //console.log(componentapplyprice_flag,'componentapplyprice_flag');

    var invCompoQty = this.getInvCompoQty($_this);
    var chk_val = 0;
    if (!isNaN(intValInc) && parseInt(invCompoQty) < parseInt(maxselectcombo)) {
      intValInc = parseInt(intValInc + 1);
      chk_val = 1;
    }

    var comboProPrice = $_this.attr("data-invcomboprice");

    var invCompoQty = this.getInvCompoQty($_this);
    if (invCompoQty > parseInt(minselectcombo) && chk_val === 1) {
      comboProPrice = parseFloat(comboProPrice);
    }

    if (parseInt(minselectcombo) === 0) {
      $_this.find(".combo_pro_price").show();
    }

    $_this.attr("data-invcomboprice", comboProPrice);
    var length = 0;
    var qty_incVal = 0;

    if (type === "cancel") {
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .parent()
        .find(".prd_close")
        .hide();

      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".package_checkitem")
        .hide();

      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".modifier-btn-grp")
        .hide();

      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".custom_text_logo")
        .hide();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".custom_checkbox")
        .show();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".popup_price_show")
        .hide();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".show_details")
        .show();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId).removeClass("selected");
    }

    $("#product_" + menuCmpId)
      .find(".individual_combo_pro")
      .each(function () {
        if ($(this).hasClass("selected")) {
          qty_incVal = $(this).find(".combo_qtycount").attr("data-qtyval");
          if (qty_incVal) {
            length += parseInt(qty_incVal);
          } else {
            length += 1;
          }
        }
      });

    var total_length = length;
    var individualModPrice = this.state.individualModPrice;
    let tempIndividualModPrice = individualModPrice;

    $("#" + menuCmpId).attr("data-selected", total_length);
    comboProPrice = this.state.product_modifiers_price;

    let excludepricemultiplypax_flag;

    $(".indvcombo" + "-" + menuCmpId + "-" + proId).each(function () {
      var modifierapply = $(this).attr("data-modifierapply");
      excludepricemultiplypax_flag = $(this).attr(
        "data-excludepricemultiplypax"
      );
      var mdfcombopro = $(this)
        .find(".modifiers_valuecheck")
        .attr("data-mdfcombopro");
      var componentid = $(this)
        .find(".modifiers_valuecheck")
        .attr("data-componentid");
      var extraqty = parseInt($(this).attr("data-extraqty"));
      var extraamount = parseFloat($(this).attr("data-invcomboprice"));

      if (parseInt(modifierapply) === 1) {
        $_this.find(".mdfr_list_divlcs").each(function () {
          $(this)
            .find(".modifiers_valuecheck")
            .each(function () {
              if ($(this).is(":checked")) {
                var modprice = parseFloat($(this).attr("data-modprice"));
                extraamount += parseFloat(modprice);
              }
            });
        });
      }

      if (total_length > minselectcombo) {
        $(this).find(".det-wrap").show();
        $(this).find(".popup_price").show();
        $(this).attr("data-extraprice", 1);

        var orgQty,
          appendAmnt = "";

        if (
          type === "single_plus" ||
          type === "simple" ||
          type === "modifier"
        ) {
          orgQty = parseInt(extraqty + 1);
          $(this).attr("data-extraqty", orgQty);
          appendAmnt = parseFloat(orgQty * extraamount);
          $(this).attr("data-extraproductprice", appendAmnt);
        } else if (parseInt(modifierapply) === 1 && type === "extraModifier") {
          $(this).attr("data-extraqty", 0);
          $(this).attr("data-extraproductprice", 0);
        } else {
          orgQty = parseInt(extraqty - parseInt(countt));
          appendAmnt = parseFloat(orgQty * extraamount);
          $(this).attr("data-extraqty", orgQty);
          $(this).attr("data-extraproductprice", appendAmnt);
        }
      } else {
        $("#product_" + menuCmpId)
          .find(".popup_price")
          .hide();

        if (type === "cancel" || type === "single_minus") {
          $("#product_" + menuCmpId)
            .find(".individual_combo_pro")
            .each(function () {
              $("#product_" + menuCmpId)
                .find(".individual_combo_pro")
                .attr("data-extraprice", 0);
              $(this).attr("data-extraqty", 0);
            });
        }
      }
      var prodPrice = $(this).attr("data-invcomboprice");

      if (total_length >= minselectcombo) {
        $("#step_" + menuCmpId).addClass("completed");

        if (
          (type === "simple" ||
            type === "single_plus" ||
            type === "modifier") &&
          total_length > minselectcombo
        ) {
          comboProPrice += parseInt(countt) * parseFloat(prodPrice);
        } else if (type === "cancel" || type === "single_minus") {
          comboProPrice =
            parseFloat(comboProPrice) -
            parseInt(countt) * parseFloat(prodPrice);

          if (minselectcombo === 0) {
            $("#step_" + menuCmpId).removeClass("completed");
          }
        }

        if (parseInt(modifierapply) === 1) {
          $(this)
            .find(".compo_mdf_" + mdfcombopro + "_" + componentid)
            .each(function () {
              if ($(this).is(":checked")) {
                var modprice = $(this).attr("data-modprice");
                var isindividual = $(this).attr("data-isindividual");
                if (parseInt(modprice) > 0) {
                  if (type === "cancel") {
                    if (
                      isindividual === "Yes" &&
                      parseFloat(individualModPrice) > 0
                    ) {
                      individualModPrice =
                        parseFloat(individualModPrice) - parseFloat(modprice);
                    } else {
                      comboProPrice =
                        parseFloat(comboProPrice) - parseFloat(modprice);
                    }
                    $(".indvcombo" + "-" + menuCmpId + "-" + proId)
                      .find(".modifiers_valuecheck")
                      .prop("checked", false);
                  } else if (total_length > minselectcombo) {
                    if (isindividual === "Yes") {
                      individualModPrice += parseFloat(modprice);
                    } else {
                      comboProPrice += parseFloat(modprice);
                    }
                  } else if (componentapplyprice_flag) {
                    if (isindividual === "Yes") {
                      individualModPrice += parseFloat(modprice);
                    } else {
                      comboProPrice += parseFloat(modprice);
                    }
                  } else {
                  }
                }
              } else {
              }
            });
        }
      } else {
        $("#step_" + menuCmpId).removeClass("completed");

        /*Before we reach the minimum select we apply price */
        if (parseInt(modifierapply) === 1) {
          $(this)
            .find(".compo_mdf_" + mdfcombopro + "_" + componentid)
            .each(function () {
              if ($(this).is(":checked")) {
                var modprice = $(this).attr("data-modprice");
                var isindividual = $(this).attr("data-isindividual");

                if (parseInt(modprice) > 0) {
                  //console.log(type,individualModPrice,isindividual,comboProPrice,modprice,'type,individualModPrice,isindividual,comboProPrice,modprice');

                  if (type === "cancel") {
                    if (
                      isindividual === "Yes" &&
                      parseFloat(individualModPrice) > 0
                    ) {
                      individualModPrice =
                        parseFloat(individualModPrice) - parseFloat(modprice);
                    } else {
                      comboProPrice =
                        parseFloat(comboProPrice) - parseFloat(modprice);
                    }

                    $(".indvcombo" + "-" + menuCmpId + "-" + proId)
                      .find(".modifiers_valuecheck")
                      .prop("checked", false);
                  } else if (componentapplyprice_flag) {
                    if (isindividual === "Yes") {
                      individualModPrice += parseFloat(modprice);
                    } else {
                      comboProPrice += parseFloat(modprice);
                    }
                  } else {
                  }
                }
              } else {
              }
            });
        }
      }

      let perpax_price = $(this).attr("data-extraproductprice");

      tempIndividualModPrice = individualModPrice - tempIndividualModPrice;

      if (Number(perpax_price)) {
        // console.log(individualModPrice,tempIndividualModPrice,'individualModPrice,tempIndividualModPrice');
        if (tempIndividualModPrice) {
          perpax_price = `$${parseFloat(perpax_price).toFixed(2)}`;
        } else {
          perpax_price = `$${parseFloat(perpax_price).toFixed(2)} / pax`;
        }
      }

      if (parseFloat(perpax_price) == 0) {
        perpax_price = "";
      }

      $_this.find(".popup_price").html(perpax_price);
    });

    if (!isNaN(comboProPrice) && comboProPrice !== undefined) {
      this.setState(
        {
          product_modifiers_price: comboProPrice,
          total_length: total_length,
          individualModPrice: individualModPrice,
          excludepricemultiplypax_flag: excludepricemultiplypax_flag,
          checkclick: true,
        },
        function () {
          this.calculatedOverAllAmount();
        }.bind(this)
      );
    }
    return false;
  }

  singleProductcount(
    menuCmpId,
    proId,
    type,
    productId,
    modId,
    product_type,
    countt,
    max_pax
  ) {
    if (countt === "" || countt === null) {
      countt = 1;
    }
    var product_state_count = $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".combo_qtycount")
      .attr("data-qtyval");
    if (type === "single_minus") {
      var tempVl = parseInt(
        product_state_count > 0 ? product_state_count - parseInt(countt) : 0
      );
      if (tempVl >= 1 && tempVl <= parseInt(max_pax)) {
        product_state_count = product_state_count - parseInt(countt);
        $(".indvcombo-" + menuCmpId + "-" + proId).addClass("selected");
        $("." + productId + "_count_exist_error").hide();
      }
      if (tempVl <= 0) {
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".custom_text_logo")
          .hide();
        $(".indvcombo-" + menuCmpId + "-" + proId).removeClass("selected");
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .parent()
          .find(".prd_close")
          .hide();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".custom_checkbox")
          .show();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".show_details")
          .show();
        product_state_count = 1;
      }
    } else {
      var diff_in_qty = parseInt(countt) - parseInt(product_state_count);
      if (diff_in_qty > 0) {
        product_state_count =
          parseInt(product_state_count) + parseInt(diff_in_qty);
      } else if (
        max_pax !== "" &&
        parseInt(product_state_count) < parseInt(max_pax)
      ) {
        var dif_qty = parseInt(max_pax) - parseInt(product_state_count);
        if (parseInt(dif_qty) < parseInt(countt)) {
          product_state_count =
            parseInt(product_state_count) + parseInt(dif_qty);
        } else {
          product_state_count =
            parseInt(product_state_count) + parseInt(countt);
        }
        $(".indvcombo-" + menuCmpId + "-" + proId).addClass("selected");
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .parent()
          .find(".prd_close")
          .show();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".custom_checkbox")
          .show();
      } else {
        product_state_count = parseInt(product_state_count) + parseInt(countt);
        $(".indvcombo-" + menuCmpId + "-" + proId).addClass("selected");
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .parent()
          .find(".prd_close")
          .show();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".custom_checkbox")
          .show();
      }

      if (parseInt(product_state_count) >= parseInt(max_pax)) {
        $("." + productId + "_count_exist_error").show();
      }
    }
    var dqty = diff_in_qty > 0 ? diff_in_qty : countt;
    $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".combo_qtycount")
      .attr("data-qtyval", product_state_count);
    $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".qty_val")
      .text(product_state_count);
    this.setState(
      {
        single_product_selected: product_state_count,
        single_product_count: product_state_count,
      },
      function () {
        this.incComboQty(
          menuCmpId,
          proId,
          type,
          productId,
          modId,
          product_type,
          dqty
        );
      }.bind(this)
    );
  }

  singleProductcountForModifier(
    menuCmpId,
    proId,
    type,
    productId,
    modId,
    product_type,
    countt,
    max_pax
  ) {
    //console.log('inside log modifier');

    if (countt === "" || countt === null) {
      countt = 1;
    }
    if (max_pax === "") {
      max_pax = 100;
    }

    var product_state_count = $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".combo_qtycount")
      .attr("data-qtyval");
    if (type === "single_minus") {
      var tempVl = parseInt(
        product_state_count > 0 ? product_state_count - parseInt(countt) : 1
      );

      if (tempVl >= 1 && tempVl <= parseInt(max_pax)) {
        product_state_count = product_state_count - parseInt(countt);

        $("." + productId + "_count_exist_error").hide();
      }
    } else {
      var diff_in_qty = parseInt(countt) - parseInt(product_state_count);
      if (diff_in_qty > 0) {
        product_state_count =
          parseInt(product_state_count) + parseInt(diff_in_qty);
      } else if (
        max_pax !== "" &&
        parseInt(product_state_count) < parseInt(max_pax)
      ) {
        var dif_qty = parseInt(max_pax) - parseInt(product_state_count);
        if (parseInt(dif_qty) < parseInt(countt)) {
          product_state_count =
            parseInt(product_state_count) + parseInt(dif_qty);
        } else {
          product_state_count =
            parseInt(product_state_count) + parseInt(countt);
        }
      }
      if (parseInt(product_state_count) >= parseInt(max_pax)) {
        $("." + productId + "_count_exist_error").show();
      }
    }

    var dqty = diff_in_qty > 0 ? diff_in_qty : countt;
    $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".combo_qtycount")
      .attr("data-qtyval", product_state_count);
    $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".qty_val")
      .text(product_state_count);

    this.setState({
      ["mulcompoInner~~" + menuCmpId + "~~" + proId]: [product_state_count],
    });

    //console.log(product_state_count,'product_state_count');
  }

  proMdfmainData(modifiersData) {
    var mdfName = modifiersData.pro_modifier_alias_name
      ? modifiersData.pro_modifier_alias_name
      : modifiersData.pro_modifier_name;
    return modifiersData.pro_modifier_id + "~~" + stripslashes(mdfName);
  }

  checkMinSelect(menuCmpId, proId, type, productId) {
    let $_cur_ths = $("#done-indvcombo-" + menuCmpId + "-" + proId);

    var errorFlg = "No";
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);

    $_this.find(".mdfr_list_divlcs").each(function () {
      var checked_count = 0;
      var modDivId = $(this).attr("id");
      var modMin = $(this).attr("data-mdfmin");
      var modMax = $(this).attr("data-mdfmax");
      $(this)
        .find(".modifiers_valuecheck")
        .each(function () {
          if ($(this).is(":checked")) {
            checked_count += 1;
          }
        });

      if (parseInt(modMin) > parseInt(checked_count)) {
        errorFlg = "Yes";
        var errorTxt = "Please select minimum " + modMin + " item(s).";
        $(this).find(".pkgerror_txt").html(errorTxt);
        $_this.addClass("package-error");
        $(this).find(".pkgerror_txt").show();
        return false;
      }

      if (errorFlg === "Yes") {
        $(this).find(".pkgerror_txt").hide();
      }
    });

    if (errorFlg === "No") {
      $_this.addClass("selected");

      $_this.parent().find(".prd_close").show();

      $_cur_ths.parent().parent().find(".package_checkitem").hide();
      $_cur_ths.parent().hide();
      $_cur_ths.parent().parent().next(".det-wrap").show();

      this.incComboQty(menuCmpId, proId, type, productId, "", "", 1);
    }
  }

  getInvCompoQty($_this) {
    var combolst_qty = 0;
    $_this
      .closest(".main_combo_div")
      .find(".combolst_qty_value")
      .each(function () {
        combolst_qty += parseInt($(this).attr("data-qtyval"));
      });
    return combolst_qty;
  }

  checkProductSelected(id, qtyindex, type, menu_min_select, menuCmpId) {
    var cart_datail_array = [];
    var compoArr = [];
    var ModifierValArr = [];
    if (this.state.cartLst) {
      cart_datail_array = this.state.cartLst;
      cart_datail_array.set_menu_component.map((productcomp, indxs) => {
        var prodtQty = 0;
        var cartProductArr = [];
        productcomp.product_details.map((productdetaile, indxes) => {
          cartProductArr[productdetaile.cart_menu_component_product_id] =
            productdetaile;
          prodtQty += parseInt(productdetaile.cart_menu_component_product_qty);

          //console.log( productdetaile.modifiers,qtyindex,'productdetaile.modifiers,qtyindex');

          productdetaile.modifiers.map((modifierValues, indxes) => {
            if (
              modifierValues.modifiers_values.length > 0 &&
              qtyindex == indxes
            ) {
              modifierValues.modifiers_values.map((modifierVal, indxes) => {
                ModifierValArr.push(modifierVal.cart_modifier_id);
              });
            }
          });
        });
        var selectData = $("#" + productcomp.menu_component_id).attr(
          "data-selected"
        );
        if (selectData) {
        } else {
          $("#" + productcomp.menu_component_id).attr(
            "data-selected",
            prodtQty
          );
          if (prodtQty >= parseInt(menu_min_select)) {
            $("#step_" + productcomp.menu_component_id).addClass("completed");
          }
        }
        compoArr[productcomp.menu_component_id] = [];
        compoArr[productcomp.menu_component_id] = cartProductArr;
      });

      if (type === "product") {
        if (compoArr[menuCmpId] && compoArr[menuCmpId][id]) {
          return compoArr[menuCmpId][id];
        } else {
          return false;
        }
      } else {
        var chkBox = false;
        if ($.inArray(id, ModifierValArr) !== -1) {
          chkBox = true;
        }
        return chkBox;
      }
    }
  }

  /* Update cart */
  updateCartPackage(compoProdDetail, actionFlg, indFlg) {
    var indKey = "Index_" + indFlg;
    var errorFlg = "No";
    $(".product_acc_grp")
      .find(".main_combo_div")
      .each(function () {
        var checked_count = 0;
        var modDivId = $(this).attr("data-id");
        var modMin = $(this).attr("data-packageminlimit");
        var modMax = $(this).attr("data-packagemaxlimit");
        var checked_count = $("#" + modDivId).attr("data-selected");
        var modDiv = $(this).attr("id");

        if (parseInt(modMin) > parseInt(checked_count)) {
          $(this).prev().addClass("active");
          $(this).show();
          errorFlg = "Yes";
          var errorTxt = "Please select minimum " + modMin + " item(s).";
          $(this).find(".pkgerror_txt").html(errorTxt);
          $(this).addClass("pkgerror_out");
          $(this).find(".pkgerror_txt").show();
          $(".modal").animate(
            {
              scrollTop:
                $("#" + modDiv).position().top -
                $(".package_details_right_inner").outerHeight() -
                5,
            },
            300
          );

          return false;
        }

        if (parseInt(modMax) < parseInt(checked_count)) {
          errorFlg = "Yes";
          var errorTxt =
            "Sorry!. maximum of selected item is " + modMax + " only.";
          $(this).find(".pkgerror_txt").html(errorTxt);
          $(this).addClass("pkgerror_out");
          $(this).find(".pkgerror_txt").show();
          $(".modal").animate(
            {
              scrollTop:
                $("#" + modDiv).position().top -
                $(".package_details_right_inner").outerHeight() -
                5,
            },
            300
          );
          return false;
        }
        if (errorFlg === "No") {
          $(this).find(".pkgerror_txt").hide();
        }
      });

    var InvalidMdfrCompo = "No";
    $(".main_combo_div").each(function () {
      if ($(this).find(".mdfr_list_divlcs").attr("data-mismatchpro") === "1") {
        InvalidMdfrCompo = "Yes";
      }
    });

    if (InvalidMdfrCompo === "No" && errorFlg === "No") {
      $(".add_to_cart").addClass("loading-btn");
      if (actionFlg === "initial") {
        $(".compo_add_div").hide();
        $(".compo_update_div").show();
        return false;
      } else {
        var $_react_this = this;
        var menuSet = [];
        var productDetailsMain = [];
        var modifier = [];
        var CompoType = $("#set_menu_component_type").val();
        CompoType = parseInt(CompoType);
        var compo_errors = "0";
        CompoType = 1;
        if (CompoType === 1) {
          $(".main_combo_div").each(function () {
            var combodata_txt = $(this)
              .find(".individual_combo_pro")
              .attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData($(this), 0);
            productDetailsMain.push({
              menu_component_id: menu_component[0],
              menu_component_name: menu_component[1],
              product_details: productDetails,
              min_max_flag: menu_component[2],
            });
          });
        } else {
          $(".main_combo_div").each(function () {
            var combodata_txt = $(this).attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData(
              $(this),
              CompoType
            );

            productDetailsMain.push({
              menu_component_id: menu_component[0],
              menu_component_name: menu_component[1],
              product_details: productDetails,
              min_max_flag: menu_component[2],
            });
          });
        }
        menuSet = productDetailsMain;
        var postSetup = [];
        var productSetup = this.state.product_setup;

        if (Object.keys(productSetup).length > 0) {
          var selecteStMain = !(indKey in productSetup)
            ? Array()
            : productSetup[indKey];

          if (Object.keys(selecteStMain).length > 0) {
            var deliveryList = selecteStMain.delivery;

            if (Object.keys(deliveryList).length > 0) {
              for (var key in deliveryList) {
                var ExpVarbl = deliveryList[key];
                var mytemparr = ExpVarbl.split("~~");

                postSetup.push({
                  setupid: mytemparr[0],
                  setupname: mytemparr[1],
                  setupdescription: mytemparr[2],
                  setupprice: mytemparr[3],
                  setupqty: mytemparr[4],
                  setuptype: mytemparr[5],
                });
              }
            }

            var buffetList = selecteStMain.buffet;
            if (Object.keys(buffetList).length > 0) {
              for (var key in buffetList) {
                var ExpVarbl = buffetList[key];
                var mytemparr = ExpVarbl.split("~~");

                postSetup.push({
                  setupid: mytemparr[0],
                  setupname: mytemparr[1],
                  setupdescription: mytemparr[2],
                  setupprice: mytemparr[3],
                  setupqty: mytemparr[4],
                  setuptype: mytemparr[5],
                });
              }
            }
          }
        }

        var selfeCollcetionList = this.state.product_setup_selfcollecdction;

        if (selfeCollcetionList.length > 0) {
          for (var key in selfeCollcetionList) {
            var ExpVarbl = selfeCollcetionList[key];
            var mytemparr = ExpVarbl.split("~~");
            postSetup.push({
              setupid: mytemparr[0],
              setupname: mytemparr[1],
              setupdescription: mytemparr[2],
              setupprice: mytemparr[3],
              setupqty: mytemparr[4],
              setuptype: mytemparr[5],
            });
          }
        }

        let equipmentSetups = [];
        var equipmentList = this.state.equipmentSetup;

        if (equipmentList.length > 0) {
          for (var key in equipmentList) {
            var ExpVarbl = equipmentList[key];
            var mytemparr = ExpVarbl.split("~~");
            equipmentSetups.push({
              equipmentid: mytemparr[0],
              equipmentname: mytemparr[1],
              equipmentdescription: mytemparr[2],
              equipmentprice: mytemparr[3],
              equipmentquantity: mytemparr[6],
            });
          }
        }

        var postAddons = [];
        var productAddons = this.state.product_addons;

        if (Object.keys(productAddons).length > 0) {
          var selecteAdfMain = !(indKey in productAddons)
            ? Array()
            : productAddons[indKey];

          if (Object.keys(selecteAdfMain).length > 0) {
            for (var key in selecteAdfMain) {
              var Addons = selecteAdfMain[key];

              var AddonsValArr = [];
              var addon_setup_id = "";
              var addon_setup_title = "";
              var addon_setup_selection = "";

              for (var key1 in Addons) {
                var ExpVarbl = Addons[key1];
                var mytemparr = ExpVarbl.split("~~");
                addon_setup_id = mytemparr[0];
                addon_setup_title = mytemparr[1];
                addon_setup_selection = mytemparr[2];

                if (parseInt(mytemparr[7]) > 0) {
                  AddonsValArr.push({
                    addon_setup_val_id: mytemparr[3],
                    addon_setup_val_title: mytemparr[4],
                    addon_setup_val_desc: mytemparr[5],
                    addon_setup_val_price: mytemparr[6],
                    addon_setup_val_qty: mytemparr[7],
                  });
                }
              }

              if (Object.keys(AddonsValArr).length > 0) {
                postAddons.push({
                  addon_setup_id: addon_setup_id,
                  addon_setup_title: addon_setup_title,
                  addon_setup_selection: addon_setup_selection,
                  addons_setup_values: AddonsValArr,
                });
              }
            }
          }
        }

        // && Object.keys(menuSet).length > 0

        if (compo_errors === "0") {
          var prCommon = this.props.productData;
          var productId = compoProdDetail[0].product_id;
          var productName =
            compoProdDetail[0].product_alias !== ""
              ? stripslashes(compoProdDetail[0].product_alias)
              : stripslashes(compoProdDetail[0].product_name);
          var productImage =
            compoProdDetail[0].product_thumbnail !== ""
              ? prCommon.image_source +
                "/" +
                compoProdDetail[0].product_thumbnail
              : "";
          var productSku = compoProdDetail[0].product_sku;
          var productSlug = compoProdDetail[0].product_slug;

          // var productRemarks  = this.state.product_remarks;
          var productRemarks = "";

          /*var incrCompoPrice = $("#incr_compo_price").val();*/

          var incrCompoPrice = this.state.newproductTotalPrice;

          if (!incrCompoPrice) {
            var incrCompoPrice = $(".cart_indvl_price").html();
            incrCompoPrice = parseFloat(incrCompoPrice.replace(/[$@%]/g, ""));
            // var incrCompoPrice = parseFloat($(".cart_indvl_price").attr('data-carttotl'));
          }

          var prodcutQty = this.state.selectpaxcount;
          if (!prodcutQty) {
            var prodcutQty = parseInt($(".product_quantity").html());
          }
          var totalCompoPrice =
            incrCompoPrice !== "" ? parseFloat(incrCompoPrice) : 0;

          var unitProductPrice =
            parseFloat(totalCompoPrice) / parseFloat(prodcutQty);
          unitProductPrice = unitProductPrice.toFixed(2);

          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");
          var breaktime_indexflag = $(".dark").attr("data-index");
          var product_min_qty =
            $(".product-min-pax").attr("data-productminpax");
          if (
            typeof cookie.load("orderOutletId") !== "undefined" ||
            cookie.load("orderOutletId") !== undefined ||
            cookie.load("orderOutletId") !== ""
          ) {
            var outlet_id = cookie.load("orderOutletId");
          }
          if (
            typeof cookie.load("order_date") !== "undefined" ||
            cookie.load("order_date") !== ""
          ) {
            var catering_order_date = moment(cookie.load("order_date")).format(
              "YYYY/MM/DD"
            );
          }
          if (!this.state.event_start || !this.state.event_start) {
            swal("Error!", "Invalid start & end time!", "error");
            return false;
          }
          //&&  parseFloat(unitProductPrice) > 0
          if (parseFloat(totalCompoPrice) > 0) {
            var postObject = {};
            postObject = {
              app_id: appId,
              product_id: productId,
              product_qty: prodcutQty,
              outlet_id: outlet_id,
              product_min_qty: product_min_qty,
              availability_id: cateringId,
              product_name: productName,
              product_total_price: totalCompoPrice,
              product_unit_price: unitProductPrice,
              product_remarks: productRemarks,
              product_image: productImage,
              product_sku: productSku,
              product_slug: productSlug,
              modifiers: JSON.stringify(modifier),
              menu_set_component: JSON.stringify(menuSet),
              individual: "yes",
              customer_id: customerId,
              reference_id: customerId === "" ? getReferenceID() : "",
              product_edit_enable: "No",
              breaktime_enable: "Yes",
              breaktime_count: 1,
              breaktime_list:
                this.state.event_start + "-" + this.state.event_end,
              breaktime_indexflag: breaktime_indexflag,
              breaktime_started: this.state.event_start,
              breaktime_ended: this.state.event_end,
              product_special_notes: this.state.specialInstruction,
              cart_catering_order_date: catering_order_date,
              setup: JSON.stringify(postSetup),
              equipment: JSON.stringify(equipmentSetups),
              addons_setup: JSON.stringify(postAddons),
            };
            // return false;

            axios
              .post(apiUrlCtrng + "cart/insert", qs.stringify(postObject))
              .then((res) => {
                if (res.data.status === "ok") {
                  if (this.state.cart_item_ids) {
                    this.deleteCartItm(this.state.cart_item_ids, "noalert");
                  }

                  $(".add_to_cart").removeClass("loading-btn");
                  $(".close").trigger("click");
                  $(".addition-breaktime").hide();
                  swal("Success!", res.data.message, "success");
                  this.setState({ productGrandTotal: 0 });
                } else if (res.data.status === "error") {
                  swal("Error!", res.data.message, "error");
                }
              });
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      $(".compocart_error_msg").html("Sorry!. Invalid product combination.");
      $(".compocart_error_msg").show();
      $(".compocart_error_msg").delay(6000).fadeOut();
      return false;
    }
  }

  getComboproData($_this, CompoType) {
    /*  var custom_banner_text = this.state.custom_banner_text; */

    var custom_banner_text = "";

    var comboproSet = [],
      comboproMdf = [];
    $_this.find(".individual_combo_pro").each(function () {
      var extraqtyy = $(this).attr("data-extraqty");
      var extraproductprices = $(this).attr("data-extraproductprice");
      var custom_logo,
        custom_text = "";
      if ($(this).hasClass("selected")) {
        var modifiers_apply = $(this).attr("data-modifierapply");
        var extra_amount = parseInt($(this).attr("data-extraprice"));
        if (parseInt(modifiers_apply) === 0) {
          var componentsProDet = $(this).attr("data-proddet");
          var compoProDet = componentsProDet.split("~~");

          var compoProductDet = $(this).attr("data-combodata");
          var compoProDetails = compoProductDet.split("~");

          var combolstQtyValue = $(this)
            .find(".combo_qtycount")
            .attr("data-qtyval");
          if ($(this).find(".combo_qtycount").length <= 0) {
            combolstQtyValue = 1;
          }
          var comboProInvPrice;
          if (extra_amount === 1) {
            comboProInvPrice = $(this).attr("data-invcomboprice");
          } else {
            comboProInvPrice = 0;
          }

          /* if (custom_banner_text && custom_banner_text[compoProDetails[0]]) {
            if (custom_banner_text[compoProDetails[0]][compoProDet[0]]) {
              custom_text =
                custom_banner_text[compoProDetails[0]][compoProDet[0]][
                  "custom_banner_text"
                ];
              custom_logo =
                custom_banner_text[compoProDetails[0]][compoProDet[0]][
                  "custom_logo_banner"
                ];
            }
          } */

          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboproMdf,
            cart_menu_component_product_extra_qty: parseInt(extraqtyy),
            cart_menu_component_product_extra_price:
              parseFloat(extraproductprices),
            custom_logo: custom_logo,
            custom_text: custom_text,
          });
        } else {
          var comboMdfSet = [];
          var comboMdfValueSet;
          var modifierCombosets_txt = $(this)
            .find(".package_checkitem")
            .attr("data-mdfmain");
          var mdfSetDet =
            modifierCombosets_txt !== "" && modifierCombosets_txt !== undefined
              ? modifierCombosets_txt.split("~~")
              : [];
          var mdfcombopro_id = $(this)
            .find(".modifiers_valuecheck")
            .attr("data-mdfcombopro");
          var componentid = $(this)
            .find(".modifiers_valuecheck")
            .attr("data-componentid");

          $_this.find(".package_checkitem").each(function () {
            comboMdfValueSet = [];
            $(this)
              .find(".compo_mdf_" + mdfcombopro_id + "_" + componentid)
              .each(function () {
                if ($(this).is(":checked")) {
                  var combopro_mdf_txt = $(this).attr("data-mdfcombopro");

                  if (combopro_mdf_txt !== "") {
                    var combopro = $(this).parent().attr("data-mdfsub");
                    var compoProDetail = combopro.split("~~");

                    if (Object.keys(mdfSetDet).length > 0) {
                      var amount_modval =
                        extra_amount === 1 ? compoProDetail[3] : 0;
                      comboMdfValueSet.push({
                        modifier_value_name: compoProDetail[1],
                        modifier_value_id: compoProDetail[0],
                        modifier_value_price: amount_modval,
                        modifier_value_qty: 1,
                      });
                    }
                  }
                }
              });

            if (comboMdfValueSet !== null && comboMdfValueSet !== undefined) {
              comboMdfSet.push({
                modifier_name: mdfSetDet[1],
                modifier_id: mdfSetDet[0],
                modifiers_values: comboMdfValueSet,
              });
            }
          });

          var componentsProDet = $(this).attr("data-proddet");

          var compoProDet = componentsProDet.split("~~");
          if (extra_amount === 1) {
            var comboPrice =
              parseFloat(compoProDet[3]) > 0 ? compoProDet[3] : 0;
          } else {
            var comboPrice = 0;
          }

          var combolstQtyValue = $(this)
            .find(".combo_qtycount")
            .attr("data-qtyval");

          if ($(this).find(".combo_qtycount").length <= 0) {
            combolstQtyValue = 1;
          }

          /*  if (custom_banner_text && custom_banner_text[componentid]) {
            if (custom_banner_text[componentid][compoProDet[0]]) {
              custom_text = custom_banner_text[componentid][compoProDet[0]]["custom_banner_text"];
              custom_logo = custom_banner_text[componentid][compoProDet[0]]["custom_logo_banner"];
            }
          } */

          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboPrice,
            product_qty: combolstQtyValue,
            modifiers: comboMdfSet,
            cart_menu_component_product_extra_qty: parseInt(extraqtyy),
            cart_menu_component_product_extra_price:
              parseFloat(extraproductprices),
            custom_logo: custom_logo,
            custom_text: custom_text,
          });
        }
      }
    });

    return comboproSet;
  }
  changeImage() {
    this.setState((state) => ({ open: !state.open }));
  }
  getImageName = () => (this.state.open ? delete_dark : delete_white);
  getImageNameAlt = () => (this.state.open ? delete_white : delete_dark);

  getDeliverysetup(index, delivery) {
    const Deliveryhtml = delivery.map((DelryValue, index4) => (
      <li
        key={index4}
        className="setup_li"
        data-setupdet={
          DelryValue.catering_delivery_setup_id +
          "~~" +
          DelryValue.catering_delivery_title +
          "~~" +
          DelryValue.catering_delivery_description +
          "~~" +
          DelryValue.catering_delivery_price +
          "~~1~~Delivery"
        }
      >
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={DelryValue.catering_delivery_price}
            onChange={this.handleUpdateSetup.bind(
              this,
              index,
              index4,
              "delivery"
            )}
            defaultChecked={this.CheckDeliveryBuff(
              index,
              index4,
              DelryValue.catering_delivery_setup_id,
              "delivery"
            )}
          />
          <span>
            {DelryValue.catering_delivery_title +
              " ( " +
              showPriceValue(DelryValue.catering_delivery_price) +
              " ) : " +
              DelryValue.catering_delivery_description}
          </span>
        </div>
      </li>
    ));
    return Deliveryhtml;
  }

  /* Buffet List */
  getBuffetsetup(index, buffet) {
    const Buffethtml = buffet.map((buffetValue, index4) => (
      <li
        key={index4}
        className="setup_li"
        data-setupdet={
          buffetValue.catering_buffet_setup_id +
          "~~" +
          buffetValue.catering_buffet_title +
          "~~" +
          buffetValue.catering_buffet_description +
          "~~" +
          buffetValue.catering_buffet_price +
          "~~1~~Buffet"
        }
      >
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={buffetValue.catering_buffet_price}
            onChange={this.handleUpdateSetup.bind(
              this,
              index,
              index4,
              "buffet"
            )}
            defaultChecked={this.CheckDeliveryBuff(
              index,
              index4,
              buffetValue.catering_buffet_setup_id,
              "buffet"
            )}
          />
          <span>
            {buffetValue.catering_buffet_title +
              " ( " +
              showPriceValue(buffetValue.catering_buffet_price) +
              " ) : " +
              buffetValue.catering_buffet_description}
          </span>
        </div>
      </li>
    ));
    return Buffethtml;
  }

  /* Selef Collection List */
  getSelfCollectionsetup(index, selfcollection) {
    const Selfcollectionhtml = selfcollection.map(
      (selfCollectionValue, index4) => (
        <li
          key={index4}
          className="setup_li"
          data-setupdet={
            selfCollectionValue.catering_self_collection_setup_id +
            "~~" +
            selfCollectionValue.catering_self_collection_title +
            "~~" +
            selfCollectionValue.catering_self_collection_description +
            "~~" +
            selfCollectionValue.catering_self_collection_price +
            "~~1~~Self Collection"
          }
        >
          <div className="custom_checkbox">
            <input
              type="checkbox"
              className="setup_listing_value"
              value={selfCollectionValue.catering_self_collection_price}
              onChange={this.handleUpdateOtherSetup.bind(
                this,
                index,
                index4,
                "self_collection"
              )}
              defaultChecked={this.CheckDeliveryBuff(
                index,
                index4,
                selfCollectionValue.catering_self_collection_setup_id,
                "self_collection"
              )}
            />
            <span>
              {selfCollectionValue.catering_self_collection_title +
                " ( " +
                showPriceValue(
                  selfCollectionValue.catering_self_collection_price
                ) +
                " ) : " +
                selfCollectionValue.catering_self_collection_description}
            </span>
          </div>
        </li>
      )
    );
    return Selfcollectionhtml;
  }

  /* Equipment List */
  getEquipmentsetup(index, equipment) {
    const Equipmenthtml = equipment.map((item, index4) => (
      <li
        key={index4}
        id={"equip_" + item.catering_equipment_setup_id}
        className="setup_li"
        data-setupdet={
          item.catering_equipment_setup_id +
          "~~" +
          item.catering_equipment_title +
          "~~" +
          item.catering_equipment_description +
          "~~" +
          item.catering_equipment_price +
          "~~1~~Equipment"
        }
      >
        <span className="counter">
          <button
            onClick={this.equipmentcount.bind(
              this,
              index,
              item.catering_equipment_setup_id,
              "minus"
            )}
          >
            -
          </button>
          <span className="qty_val">
            {this.loadCurrentEquipment(item.catering_equipment_setup_id)}
          </span>
          <button
            onClick={this.equipmentcount.bind(
              this,
              index,
              item.catering_equipment_setup_id,
              "plus"
            )}
          >
            +
          </button>
        </span>
        <p>
          {item.catering_equipment_description +
            " ( " +
            showPriceValue(item.catering_equipment_price) +
            " )"}
        </p>
      </li>
    ));
    return Equipmenthtml;
  }

  loadCurrentEquipment(cart_equipment_id) {
    let current = 0;
    if (
      this.state.CurrentEquipment !== "" &&
      typeof this.state.CurrentEquipment !== undefined
    ) {
      this.state.CurrentEquipment.map(function (item) {
        if (
          item.cart_equipment_id === cart_equipment_id &&
          item.cart_equipment_qty > 0
        ) {
          current = item.cart_equipment_qty;
        }
      });
    }
    return current;
  }

  equipmentcount(indFlg, equipmentID, type) {
    let eqdetails = $("#equip_" + equipmentID);
    let existValue = eqdetails.find(".qty_val").text();
    let newVAlue = "";
    if (type === "plus") {
      newVAlue = parseInt(existValue) + 1;
    } else if (type === "minus") {
      newVAlue = parseInt(existValue) - 1;
    }
    eqdetails.find(".qty_val").html(newVAlue);

    var equipmentArr = Array();
    var incvl = 0;
    $("#equipmentsetup_" + indFlg)
      .find(".equipment_listing_div")
      .find(".setup_li")
      .each(function () {
        if ($(this).find(".qty_val").text() > 0) {
          equipmentArr[incvl] =
            $(this).attr("data-setupdet") +
            "~~" +
            $(this).find(".qty_val").text();
          incvl++;
        }
      });
    var sateProductSetup = this.state.equipmentSetup;
    sateProductSetup = equipmentArr;
    this.setState(
      { equipmentSetup: sateProductSetup },
      function () {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  handleUpdateSetup(indFlg, indexInr, type) {
    var deliveryArr = Array();
    var buffetArr = Array();
    $("#setup_" + indFlg)
      .find(".delivery_setup_error")
      .hide();
    $("#setup_" + indFlg)
      .find(".buffet_setup_error")
      .hide();

    if (type === "delivery") {
      var incvl = 0;
      $("#setup_" + indFlg)
        .find(".additionals_deliverylist")
        .find(".setup_listing_value")
        .each(function (dlvy) {
          if ($(this).is(":checked")) {
            deliveryArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
    } else if (type === "buffet") {
      var incvl = 0;
      // console.log("indFlg", indFlg, type);
      $("#bufsetup_" + indFlg)
        .find(".buffet_listing_div")
        .find(".setup_listing_value")
        .each(function (dlvy) {
          if ($(this).is(":checked")) {
            buffetArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
    }

    var indKey = "Index_" + indFlg;
    var subKey1 = "delivery";
    var subKey2 = "buffet";
    var sateProductSetup = this.state.product_setup;
    sateProductSetup[indKey] = [];
    sateProductSetup[indKey][subKey1] = deliveryArr;
    sateProductSetup[indKey][subKey2] = buffetArr;
    //console.log("sateProductSetup", sateProductSetup);
    this.setState(
      { product_setup: sateProductSetup },
      function () {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  handleUpdateOtherSetup(indFlg, indexInr, type) {
    $("#setup_" + indFlg)
      .find(".delivery_setup_error")
      .hide();
    $("#setup_" + indFlg)
      .find(".buffet_setup_error")
      .hide();
    if (type === "self_collection") {
      var selfcollectionArr = Array();
      var incvl = 0;
      $("#selfcollectionsetup_" + indFlg)
        .find(".self_collection_listing_div")
        .find(".setup_listing_value")
        .each(function () {
          if ($(this).is(":checked")) {
            selfcollectionArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
      var sateProductSetup = this.state.product_setup_selfcollecdction;
      sateProductSetup = selfcollectionArr;

      this.setState(
        { product_setup_selfcollecdction: sateProductSetup },
        function () {
          this.calculatedOverAllAmount(indFlg);
        }.bind(this)
      );
    }
  }

  handleUpdateCurrentSetup(settupType) {
    this.setState({ currentSettup: settupType });
  }

  CheckDeliveryBuff(indFlg, index4, idtxt, type) {
    var indKey = "Index_" + indFlg;
    var subKey = type;
    var chkBox = false;
    if (type === "self_collection") {
      var product_setup_selfcollecdction =
        this.state.product_setup_selfcollecdction;
      if (
        product_setup_selfcollecdction !== "" &&
        typeof product_setup_selfcollecdction !== undefined &&
        typeof product_setup_selfcollecdction !== "undefined"
      ) {
        product_setup_selfcollecdction.map(function (item) {
          var ExistValue = item.split("~~");
          if (ExistValue[0] === idtxt) {
            chkBox = true;
          }
        });
      }
    } else {
      var sateProductSetup = this.state.product_setup;
      if (
        sateProductSetup !== "" &&
        typeof sateProductSetup !== undefined &&
        Object.keys(sateProductSetup).length > 0
      ) {
        if (
          sateProductSetup[indKey] !== "" &&
          typeof sateProductSetup[indKey] !== undefined
        ) {
          if (
            sateProductSetup[indKey][subKey] !== "" &&
            sateProductSetup[indKey][subKey] !== null &&
            typeof sateProductSetup[indKey][subKey] !== undefined &&
            typeof sateProductSetup[indKey][subKey] !== "undefined"
          ) {
            sateProductSetup[indKey][subKey].map(function (item) {
              var ExistValue = item.split("~~");
              if (ExistValue[0] === idtxt) {
                chkBox = true;
              }
            });
          }
        }
      }
    }
    return chkBox;
  }

  CheckSingleAddon(product_catering_addons_id, cat_addonvalues_id, index3) {
    var chkBox = false;
    var product_addons = this.state.product_addons;
    if (product_addons !== "" && typeof product_addons !== undefined) {
      if (
        product_addons["Index_0"] !== "" &&
        typeof product_addons["Index_0"] !== undefined &&
        typeof product_addons["Index_0"] !== "undefined"
      ) {
        if (
          product_addons["Index_0"]["addon_" + index3] !== "" &&
          typeof product_addons["Index_0"]["addon_" + index3] !== undefined &&
          typeof product_addons["Index_0"]["addon_" + index3] !== "undefined"
        ) {
          product_addons["Index_0"]["addon_" + index3].map(function (item) {
            var ExistValue = item.split("~~");
            if (
              ExistValue[0] === product_catering_addons_id &&
              ExistValue[3] === cat_addonvalues_id
            ) {
              chkBox = true;
            }
          });
        }
      }
    }
    return chkBox;
  }

  productAddonsSetup(indFlg, addonsSetup) {
    const addonshtml = addonsSetup.map((proAddons, index3) => (
      <li
        key={index3}
        id={"addonsLi-" + indFlg + "-" + index3}
        onChange={this.openAddonsPopup.bind(this, indFlg, index3, proAddons)}
        className={this.CheckAddonsValues(indFlg, index3, "class")}
        data-addonmin={proAddons.cat_addon_min_select}
        data-addonmax={proAddons.cat_addon_max_select}
        data-addontype={proAddons.cat_addon_selection}
      >
        <div className="custom_checkbox custom-addon-checkbox">
          <input
            type="checkbox"
            onChange={this.handlerTestFun.bind(this)}
            className="addonCheck"
            checked={this.CheckAddonsValues(indFlg, index3, "chkbox")}
          />
          <span> {proAddons.cat_addon_title} </span>
          <div className="pkgerror_txt" style={{ display: "none" }}>
            Please select 1 more item!
          </div>
        </div>
        <div
          id={"addonsDet-" + indFlg + "-" + index3}
          className="white-popup mfp-hide popup_sec addon_popup"
        >
          <div className="pouup_in">
            <div className="addon_poptop">
              <div className="addon_poptop_header">
                <h3>
                  {proAddons.cat_addon_title}

                  {parseInt(proAddons.cat_addon_min_select) === 0 &&
                  parseInt(proAddons.cat_addon_max_select) === 0 ? (
                    ""
                  ) : (
                    <small>
                      (Select{" "}
                      {proAddons.cat_addon_min_select +
                        " - Max " +
                        proAddons.cat_addon_max_select}{" "}
                      selections)
                    </small>
                  )}
                </h3>
              </div>

              <div className="addon_listsec">
                {proAddons.cat_addon_selection === "single"
                  ? this.singleAddonPopupList(indFlg, index3, proAddons)
                  : this.multipleAddonPopupList(indFlg, index3, proAddons)}
              </div>
            </div>
            <div className="btn_sec">
              <div className="two-button-row">
                <div className="go_second">
                  <a
                    href="javascript:void(0)"
                    className="button button-left common-mfd-closefun"
                    title="Go Back"
                  >
                    Go Back
                  </a>
                </div>
                <div className="con_first delivery_submit_cls">
                  <a
                    href="javascript:void(0)"
                    className="button button-right common-mfd-closefun"
                    title="Continue"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
    return addonshtml;
  }
  openAddonsPopup(indFlg, index3, DelryValue) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;
    $("#" + liIdtxt).removeClass("active");
    if (Object.keys(selecteAddonSub).length > 0) {
      $("#" + liIdtxt).addClass("active");
      $("#" + liIdtxt)
        .find(".addonCheck")
        .prop("checked", true);
    } else {
      $("#" + liIdtxt)
        .find(".addonCheck")
        .prop("checked", false);

      if (DelryValue.cat_addon_selection === "single") {
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".addon_list_single")
          .prop("checked", false);
      } else if (DelryValue.cat_addon_selection === "multiple") {
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".addon_qty_input")
          .val("0");
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".plue_muns_div")
          .hide();
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".qty_count_sp, .delete-act-icon")
          .hide();
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".add_tocart_div")
          .show();

        /*
				$('#addonsDet-'+indFlg+'-'+index3).find('.plue_muns_div').each(function(qtyIn){
					var addonminpax = $(this).attr('data-addonminpax');
					    addonminpax = (addonminpax !== '') ? parseInt(addonminpax) : 0;
				    $(this).find('.addon_qty_input').val(addonminpax);
						
				});
				*/
      }
    }

    var idText = "#addonsDet-" + indFlg + "-" + index3;
    window.$.magnificPopup.open({
      items: {
        src: idText,
      },
      type: "inline",
    });
    $(".addon_listsec .addon_list_main").each(function (index) {
      var currentDIV = $(this).find(".plue_muns_div").data("addonbind");
      if (
        currentDIV !== "" &&
        typeof currentDIV !== undefined &&
        typeof currentDIV !== "undefined"
      ) {
        var currentDIVArray = currentDIV.split("~~");
        var current = $(this);
        if (Object.keys(selecteAddonSub).length > 0) {
          selecteAddonSub.map(function (item) {
            var ExistArray = item.split("~~");
            if (
              ExistArray[0] == currentDIVArray[0] &&
              ExistArray[3] == currentDIVArray[3] &&
              ExistArray[7] > 0
            ) {
              current.find(".add_tocart_div").hide();
              current.find(".qty_count_sp").hide();
              current.find(".plue_muns_div").show();
            }
          });
        }
      }
    });
  }

  CheckAddonsValues(indFlg, index3, type) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    var classTxt = "";
    var chkBox = false;
    if (Object.keys(selecteAddonSub).length > 0) {
      classTxt = "active";
      chkBox = true;
    }

    return type === "class" ? classTxt : chkBox;
  }

  handlerTestFun() {}

  singleAddonPopupList(indFlg, index3, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;

    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) => (
        <div
          className="addon_listrw addon_list_main"
          id={"addon_main-" + indFlg + "-" + index3 + "-" + index6}
          key={index6}
        >
          <div className="addon_linfo">
            <div className="custom_checkbox">
              <input
                type="checkbox"
                className="addon_list_single"
                onClick={this.addonSingleCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6
                )}
                defaultChecked={this.CheckSingleAddon(
                  proAddons.product_catering_addons_id,
                  AddonsValue.cat_addonvalues_id,
                  index3
                )}
              />
              <span>
                <h4>{AddonsValue.cat_addonvalues_title}</h4>
                {AddonsValue.cat_addonvalues_description !== "" ? (
                  <p>{AddonsValue.cat_addonvalues_description}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div
            data-addonbind={
              proAddons.product_catering_addons_id +
              "~~" +
              proAddons.cat_addon_title +
              "~~" +
              proAddons.cat_addon_selection +
              "~~" +
              AddonsValue.cat_addonvalues_id +
              "~~" +
              AddonsValue.cat_addonvalues_title +
              "~~" +
              AddonsValue.cat_addonvalues_description +
              "~~" +
              AddonsValue.cat_addonvalues_price
            }
            className="addon_lright text-right add_tocart_div"
          >
            {/* <span
              className="cross_multi qty_count_sp">
              x1
            </span> */}
            <span className="addon_price">
              {showPriceValue(AddonsValue.cat_addonvalues_price)}
            </span>
          </div>
        </div>
      ));
      return AddonsValuehtml;
    }
  }

  addonSingleCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var indKey = "Index_" + indFlg;
    var subKey = "addon_" + index3;
    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;

    $("#" + liIdtxt)
      .find(".pkgerror_txt")
      .hide();
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_poptop_header")
      .find("small")
      .removeClass("apply-red");
    var addonMin = $("#" + liIdtxt).attr("data-addonmin");
    var addonMax = $("#" + liIdtxt).attr("data-addonmax");
    var minmaxVld = "Yes";
    if (parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
      minmaxVld = "No";
    }

    var tempArr = Array();
    var allValEmpty = "yes";
    var incvl = 0;
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_list_single")
      .each(function (newInt) {
        if ($(this).is(":checked")) {
          var alltmFl = $(this)
            .closest(".addon_list_main")
            .find(".add_tocart_div")
            .attr("data-addonbind");
          var temarFl = alltmFl + "~~1";
          tempArr[incvl] = temarFl;
          allValEmpty = "No";
          incvl++;
        }
      });

    if (minmaxVld === "Yes") {
      if (parseInt(addonMax) < parseInt(incvl) && parseInt(incvl) > 0) {
        var errorTxt = "Maximum of selected item is " + addonMax + " only.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }

      if (parseInt(addonMin) > parseInt(incvl) && parseInt(incvl) > 0) {
        var errorTxt = "Please select minimum " + addonMin + " item.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }
    }

    var productAddons = this.state.product_addons;

    if (productAddons[indKey] === "" || productAddons[indKey] === undefined) {
      productAddons[indKey] = [];
    }
    if (allValEmpty == "No") {
      productAddons[indKey][subKey] = tempArr;
    } else {
      productAddons[indKey][subKey] = Array();
    }

    this.setState(
      { product_addons: productAddons },
      function () {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  multipleAddonPopupList(indFlg, index3, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;

    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) => (
        <div
          className="addon_listrw addon_list_main"
          id={"addon_main-" + indFlg + "-" + index3 + "-" + index6}
          key={index6}
        >
          <div className="addon_linfo">
            <h4>{AddonsValue.cat_addonvalues_title}</h4>
            {AddonsValue.cat_addonvalues_description !== "" ? (
              <p>{AddonsValue.cat_addonvalues_description}</p>
            ) : (
              ""
            )}
          </div>
          <div className="addon_lright text-right add_tocart_div">
            <span className="cross_multi qty_count_sp">
              {parseInt(AddonsValue.cat_addonvalues_min_pax) > 0
                ? "x" + AddonsValue.cat_addonvalues_min_pax
                : "x1"}
            </span>
            <div className="act-remove-topdiv">
              <span className="addon_price">
                {showPriceValue(AddonsValue.cat_addonvalues_price)}
              </span>
              <a
                href="javascript:void(0)"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "remove",
                  AddonsValue.cat_addonvalues_min_pax
                )}
                className="delete-act-icon"
                title="Remove"
                style={{ display: "none" }}
              >
                <i className="fa fa-trash-o" data-unicode="f014"></i>
              </a>
            </div>
            <a
              href="javascript:void(0)"
              onClick={this.addonSubAddtoCart.bind(
                this,
                indFlg,
                index3,
                index6
              )}
              className="btn btn_green"
              title="Add to cart"
            >
              Add to cart
            </a>
          </div>
          <div
            data-addonbind={
              proAddons.product_catering_addons_id +
              "~~" +
              proAddons.cat_addon_title +
              "~~" +
              proAddons.cat_addon_selection +
              "~~" +
              AddonsValue.cat_addonvalues_id +
              "~~" +
              AddonsValue.cat_addonvalues_title +
              "~~" +
              AddonsValue.cat_addonvalues_description +
              "~~" +
              AddonsValue.cat_addonvalues_price
            }
            className="addon_lright text-right plue_muns_div"
            data-addonminpax={AddonsValue.cat_addonvalues_min_pax}
          >
            <span className="cross_multi qty_count_sp">
              {parseInt(AddonsValue.cat_addonvalues_min_pax) > 0
                ? "x" + AddonsValue.cat_addonvalues_min_pax
                : "x1"}
            </span>

            <span className="addon_price">
              {showPriceValue(AddonsValue.cat_addonvalues_price)}
            </span>

            <span className="counter">
              <button
                className="qty_minus"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "minus",
                  AddonsValue.cat_addonvalues_min_pax
                )}
              >
                -
              </button>
              <span className="addon_qty_lable">
                {this.getQtyValue(
                  indFlg,
                  index3,
                  index6,
                  AddonsValue.cat_addonvalues_min_pax
                )}
              </span>
              <input
                type="hidden"
                className="addon_qty_input"
                value={this.getQtyValue(
                  indFlg,
                  index3,
                  index6,
                  AddonsValue.cat_addonvalues_min_pax
                )}
              />
              <button
                className="qty_plus"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "plus",
                  AddonsValue.cat_addonvalues_min_pax
                )}
              >
                +
              </button>
            </span>
            <a
              href="javascript:void(0)"
              onClick={this.addonDoneCart.bind(this, indFlg, index3, index6)}
              className="btn btn_green"
              title="Add to cart"
            >
              Done
            </a>
          </div>
        </div>
      ));
      return AddonsValuehtml;
    }
  }

  addonPlusMinusCart(indFlg, index3, index6, type, minPax) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var qty_input = $("#" + mainDivId)
      .find(".addon_qty_input")
      .val();

    var minPaxVal = minPax !== "" ? parseInt(minPax) : 0;
    if (type === "remove") {
      $("#" + mainDivId)
        .find(".addon_qty_input")
        .val(0);
      qty_input = 1;
      this.addonDoneCart(indFlg, index3, index6);
    } else if (type === "minus") {
      qty_input = parseInt(qty_input);
      if (qty_input <= minPaxVal) {
        $("#" + mainDivId)
          .find(".addon_qty_input")
          .val(0);
        this.addonDoneCart(indFlg, index3, index6);
      } else if (qty_input > 0) {
        qty_input = qty_input - 1;
        $("#" + mainDivId)
          .find(".addon_qty_input")
          .val(qty_input);
        if (qty_input === 0) {
          $("#" + mainDivId)
            .find(".plue_muns_div")
            .hide();
          $("#" + mainDivId)
            .find(".qty_count_sp")
            .hide();

          $("#" + mainDivId)
            .find(".add_tocart_div")
            .show();
        }
      }
    } else {
      qty_input =
        parseInt(qty_input) < minPaxVal ? minPaxVal : parseInt(qty_input) + 1;

      $("#" + mainDivId)
        .find(".addon_qty_input")
        .val(qty_input);
    }
    var count_sp_text = qty_input;
    $("#" + mainDivId)
      .find(".addon_qty_lable")
      .html(count_sp_text);
    var count_sp_text_x = "X" + qty_input;
    $("#" + mainDivId)
      .find(".qty_count_sp")
      .html(count_sp_text_x);
  }
  addonSubAddtoCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    $("#" + mainDivId)
      .find(".add_tocart_div")
      .hide();
    $("#" + mainDivId)
      .find(".qty_count_sp")
      .hide();

    $("#" + mainDivId)
      .find(".plue_muns_div")
      .show();
  }

  getQtyValue(indFlg, index3, index6, minPax) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    /*var qtyValue = (minPax !== '') ? parseInt(minPax) : 0;*/
    var qtyValue = 0;
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    if (Object.keys(selecteAddonSub).length > 0) {
      var ExpVarbl = selecteAddonSub[index6];
      if (
        ExpVarbl !== "" &&
        typeof ExpVarbl !== undefined &&
        typeof ExpVarbl !== "undefined"
      ) {
        var mytemparr = ExpVarbl.split("~~");
        qtyValue = mytemparr[7];
      }
    }

    return qtyValue;
  }

  addonDoneCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var indKey = "Index_" + indFlg;
    var subKey = "addon_" + index3;

    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;

    $("#" + liIdtxt)
      .find(".pkgerror_txt")
      .hide();
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_poptop_header")
      .find("small")
      .removeClass("apply-red");
    var addonMin = $("#" + liIdtxt).attr("data-addonmin");
    var addonMax = $("#" + liIdtxt).attr("data-addonmax");
    var minmaxVld = "Yes";
    if (parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
      minmaxVld = "No";
    }

    var tempArr = Array();
    var allValEmpty = "yes";
    var itmCount = 0;
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_qty_input")
      .each(function (newInt) {
        var alltmFl = $(this).closest(".plue_muns_div").attr("data-addonbind");
        var qtyInt = $(this).val();
        var temarFl = alltmFl + "~~" + qtyInt;
        tempArr[newInt] = temarFl;
        if (parseInt(qtyInt) > 0) {
          allValEmpty = "No";
          itmCount++;
        }
      });

    if (minmaxVld === "Yes") {
      if (parseInt(addonMax) < parseInt(itmCount) && parseInt(itmCount) > 0) {
        var errorTxt = "Maximum of selected item is " + addonMax + " only.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }

      if (parseInt(addonMin) > parseInt(itmCount) && parseInt(itmCount) > 0) {
        var errorTxt = "Please select minimum " + addonMin + " item.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }
    }

    var productAddons = this.state.product_addons;

    if (productAddons[indKey] === "" || productAddons[indKey] === undefined) {
      productAddons[indKey] = [];
    }

    if (allValEmpty == "No") {
      productAddons[indKey][subKey] = tempArr;
    } /* else {
      //productAddons[indKey][subKey] = Array();
    } */

    this.setState(
      { product_addons: productAddons },
      function () {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );

    var qty_input = $("#" + mainDivId)
      .find(".addon_qty_input")
      .val();

    $("#" + mainDivId)
      .find(".plue_muns_div")
      .hide();
    if (parseInt(qty_input) > 0) {
      $("#" + mainDivId)
        .find(".add_tocart_div")
        .find(".qty_count_sp, .delete-act-icon")
        .show();
    } else {
      $("#" + mainDivId)
        .find(".add_tocart_div")
        .find(".qty_count_sp, .delete-act-icon")
        .hide();
    }
    $("#" + mainDivId)
      .find(".add_tocart_div")
      .show();
  }

  /*Modifier value list*/
  modfierValuelistMain(
    index,
    cindex,
    mindex,
    product_details,
    component,
    modifier_value_image_source,
    selectvalue,
    selcted_vall
  ) {
    let qty_val = $(
      ".indvcombo-" +
        component.menu_set_component_id +
        "-" +
        product_details.product_primary_id
    )
      .find(".combo_qtycount")
      .attr("data-qtyval");

    //  console.log(qty_val,'qty_val');
    qty_val = qty_val == undefined || qty_val == "" ? 1 : qty_val;

    let qtyarr = [];
    for (let i = 1; i <= qty_val; i++) {
      qtyarr.push(i);
    }

    const html = qtyarr.map((qtyval, qtyindex) =>
      this.modfierValuelistMainLoop(
        index,
        cindex,
        mindex,
        qtyindex,
        product_details,
        component,
        modifier_value_image_source,
        selectvalue,
        selcted_vall
      )
    );

    return html;
  }

  modfierValuelistMainLoop(
    index,
    cindex,
    mindex,
    qtyindex,
    product_details,
    component,
    modifier_value_image_source,
    selectvalue,
    selcted_vall
  ) {
    let display_val = "block";

    let display_cur_val = $(
      ".indvcombo-" +
        component.menu_set_component_id +
        "-" +
        product_details.product_primary_id
    )
      .find(".show_details")
      .css("display");

    if (display_cur_val !== "none" && display_cur_val != undefined) {
      display_val = "none";
    }

    const html = product_details.modifiers.map((modifiers, rindex) => {
      return (
        <div
          className="form-group package_checkitem mdfr_list_divlcs"
          key={rindex}
          data-invcomboprice={product_details.product_price}
          id={"mdf-" + cindex + "-" + mindex + qtyindex}
          data-mdfmin={modifiers.pro_modifier_min_select}
          data-mdfmax={modifiers.pro_modifier_max_select}
          data-mdfmain={this.proMdfmainData(modifiers)}
          style={{ display: display_val }}
        >
          <p>
            <i>{stripslashes(modifiers.pro_modifier_name)}</i>
            <br />
            (Choice of {modifiers.pro_modifier_min_select} -{" "}
            {modifiers.pro_modifier_max_select} of the followings)
          </p>
          <div className="pkgerror_txt" style={{ display: "none" }}>
            {" "}
            Please select 1 more item(s)!
          </div>

          {this.modfierValuelistSub(
            modifiers,
            index,
            cindex,
            qtyindex,
            product_details,
            component,
            modifier_value_image_source,
            selectvalue,
            selcted_vall
          )}
        </div>
      );
    });

    return html;
  }

  modfierValuelistSub(
    modifiers,
    index,
    cindex,
    qtyindex,
    product_details,
    component,
    modifier_value_image_source,
    selectvalue,
    selcted_vall
  ) {
    const html = modifiers.modifiers_values.map((modifiers_values, mvindex) => {
      let checkVal = this.checkProductSelected(
        modifiers_values.pro_modifier_value_id,
        qtyindex,
        "modifierVal"
      )
        ? true
        : false;

      return (
        <div
          className="custom_checkbox"
          key={index}
          id={"mdfvalue-" + cindex + "-" + index + "-" + mvindex}
          data-productid={product_details.product_id}
          data-mdfsub={
            modifiers_values.pro_modifier_value_id +
            "~~" +
            stripslashes(modifiers_values.pro_modifier_value_name) +
            "~~1~~" +
            modifiers_values.pro_modifier_value_price +
            "~~" +
            modifiers.pro_modifier_id +
            "~~" +
            modifiers.pro_modifier_name
          }
        >
          <input
            type="checkbox"
            className={
              "modifiers_valuecheck mod_" +
              modifiers_values.pro_modifier_value_id +
              " " +
              "compo_mdf_" +
              product_details.product_id +
              "_" +
              component.menu_set_component_id
            }
            data-prodprice={product_details.product_price}
            data-mdfcombopro={product_details.product_id}
            data-modifiervalueid={modifiers_values.pro_modifier_value_id}
            data-componentid={component.menu_set_component_id}
            data-modprice={modifiers_values.pro_modifier_value_price}
            data-isindividual={modifiers_values.pro_modifier_individual}
            onChange={this.checkMinMaxMod.bind(
              this,
              component.menu_set_component_id,
              product_details.product_primary_id,
              "modifier",
              product_details.product_id,
              modifiers_values.pro_modifier_value_id,
              "",
              1
            )}
            defaultValue={modifiers_values.pro_modifier_value_id}
            defaultChecked={checkVal}
          />{" "}
          <span>
            {modifiers_values.pro_modifier_value_image ? (
              <img
                src={
                  modifier_value_image_source +
                  "/" +
                  modifiers_values.pro_modifier_value_image
                }
                alt=""
              />
            ) : (
              ""
            )}

            {stripslashes(modifiers_values.pro_modifier_value_name)}
            <p>
              {parseInt(modifiers_values.pro_modifier_value_price) > 0
                ? "(" + "$" + modifiers_values.pro_modifier_value_price + ")"
                : ""}
            </p>
          </span>
        </div>
      );
    });
    return html;
  }

  qtyoptionContent(
    product_details,
    component,
    tag_image_source,
    selectvalue,
    selcted_vall
  ) {
    return (
      <div
        className="custom_text_logo"
        style={{ display: selectvalue ? "block" : "none" }}
      >
        <div className="text_center">
          {(product_details.product_tag || []).map((tag, index) => {
            return (
              <img
                src={tag_image_source + tag.pro_tag_image}
                alt={tag.pro_tag_name}
                key={index}
              />
            );
          })}
          <p className="text_center">
            <b
              className="popup_price_show"
              style={{
                display: "none",
              }}
            >
              $
              {parseInt(product_details.product_price) > 0
                ? product_details.product_price
                : ""}
            </b>
          </p>
          <span
            className="counter combo_qtycount"
            data-qtyval={
              selectvalue ? selectvalue.cart_menu_component_product_qty : 1
            }
          >
            <button
              onClick={this.singleProductcount.bind(
                this,
                component.menu_set_component_id,
                product_details.product_primary_id,
                "single_minus",
                product_details.product_id,
                "",
                "single_product",
                product_details.increase_quantity,
                product_details.product_max_pax
              )}
            >
              -
            </button>
            <span className="qty_val">
              {" "}
              {selectvalue ? selectvalue.cart_menu_component_product_qty : 1}
            </span>
            <button
              onClick={this.singleProductcount.bind(
                this,
                component.menu_set_component_id,
                product_details.product_primary_id,
                "single_plus",
                product_details.product_id,
                "",
                "single_product",
                product_details.increase_quantity,
                product_details.product_max_pax
              )}
            >
              +
            </button>
          </span>
          <span
            className={
              "error " + product_details.product_id + "_" + "count_exist_error"
            }
            style={{
              display: "none",
            }}
          >
            Sorry!. maximum of selected item is{" "}
            {product_details.product_max_pax} only.
          </span>
        </div>
      </div>
    );
  }

  qtyoptionContentForModifier(
    product_details,
    component,
    selectvalue,
    selcted_vall
  ) {
    return (
      <div
        className="custom_text_logo"
        style={{ display: selectvalue ? "block" : "none" }}
      >
        <div className="text_center">
          <p className="text_center">
            <b className="popup_price_show" style={{ display: "none" }}>
              $
              {parseInt(product_details.product_price) > 0
                ? product_details.product_price
                : ""}
            </b>
          </p>
          <span
            className="counter combo_qtycount"
            data-qtyval={
              selectvalue ? selectvalue.cart_menu_component_product_qty : 1
            }
          >
            <button
              onClick={this.singleProductcountForModifier.bind(
                this,
                component.menu_set_component_id,
                product_details.product_primary_id,
                "single_minus",
                product_details.product_id,
                "",
                "single_product",
                product_details.increase_quantity,
                product_details.product_max_pax
              )}
            >
              -
            </button>
            <span className="qty_val">
              {" "}
              {selectvalue ? selectvalue.cart_menu_component_product_qty : 1}
            </span>
            <button
              onClick={this.singleProductcountForModifier.bind(
                this,
                component.menu_set_component_id,
                product_details.product_primary_id,
                "single_plus",
                product_details.product_id,
                "",
                "single_product",
                product_details.increase_quantity,
                product_details.product_max_pax
              )}
            >
              +
            </button>
          </span>

          <span
            className={
              "error " + product_details.product_id + "_" + "count_exist_error"
            }
            style={{ display: "none" }}
          >
            Sorry!. maximum of selected item is{" "}
            {product_details.product_max_pax} only.
          </span>
        </div>
      </div>
    );
  }

  render() {
    let productData,
      staticblockData = [];
    let tag_image_source,
      modifier_value_image_source = "";
    let product_gallery_image_source,
      image_source = "";
    let flavour = [];
    let temp = "";
    let productTag = "";
    if (this.props.staticblockData) {
      staticblockData = this.props.staticblockData.result_set;
    }
    var gstPar = 0;
    var grandTotalAmt = this.state.cartDetailsMain
      ? parseFloat(this.state.cartDetailsMain.cart_grand_total)
      : 0;

    var zone_delivery_charge =
      this.state.zone_delivery_charge !== "" &&
      typeof this.state.zone_delivery_charge !== undefined
        ? parseFloat(this.state.zone_delivery_charge)
        : 0;
    if (zone_delivery_charge > 0) {
      grandTotalAmt =
        parseFloat(grandTotalAmt) + parseFloat(zone_delivery_charge);
    }

    var zone_additional_delivery_charge =
      this.state.zone_additional_delivery_charge !== "" &&
      typeof this.state.zone_additional_delivery_charge !== undefined
        ? parseFloat(this.state.zone_additional_delivery_charge)
        : 0;

    if (zone_additional_delivery_charge > 0) {
      grandTotalAmt =
        parseFloat(grandTotalAmt) + parseFloat(zone_additional_delivery_charge);
    }
    var surcharge =
      this.state.surcharge !== "" && typeof this.state.surcharge !== undefined
        ? parseFloat(this.state.surcharge)
        : 0;

    let {
      sur_deliverysetup_fee,
      sur_ear_deliverysetup_fee,
      sur_lat_teardown_fee,
      sur_deliverysetup_fee_name,
      sur_ear_deliverysetup_fee_name,
    } = getSurchargesplitup(surcharge, this.state.surchargeLog);

    if (surcharge > 0) {
      grandTotalAmt = parseFloat(grandTotalAmt) + parseFloat(surcharge);
    }

    var comSettings = this.state.settings;
    if (comSettings && Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
      var gstAmount = getGstValue(gstPar, grandTotalAmt, "format");
      var gstAmountTxt = getGstValue(gstPar, grandTotalAmt, "");
      grandTotalAmt = parseFloat(grandTotalAmt) + parseFloat(gstAmountTxt);
    }

    if (this.props.productData && this.props.productData.status === "ok") {
      productData = this.props.productData.result_set;
      tag_image_source = this.props.productData.common.tag_image_source;
      product_gallery_image_source =
        this.props.productData.common.product_gallery_image_source;
      image_source = this.props.productData.common.image_source;
      modifier_value_image_source =
        this.props.productData.common.modifier_value_image_source;
      var minMaxSelect = parseInt(
        this.props.productData.result_set[0].product_apply_minmax_select
      );
      var compoTotalPrice = this.props.productData.result_set[0].product_price;
    }

    const imageName = this.getImageName();
    return (
      <>
        <Header />

        {/* products main */}
        <div className="container product_container">
          {/* banner */}
          {(staticblockData || []).map((staticblock, index) => {
            if (staticblock.staticblocks_slug === "package-banner") {
              return (
                <section
                  className="cat_banner package_banner catering_banner"
                  key={index}
                >
                  <img
                    src={
                      staticblock.gallery_image_path +
                      staticblock.gallery_images[0]
                    }
                    alt="banner"
                  />
                  {Parser(stripslashes(staticblock.staticblocks_description))}
                </section>
              );
            }
          })}
          {/*     <section className="cat_banner package_banner catering_banner">
          
          <div className="banner_content text_center">
            <h1>
              Bespoke & Handcrafted
              <span>Delivering a gastronomic and visual food experience</span>
            </h1>
          </div>
        </section>*/}
          <div className="catering_products_main">
            <Category {...this.props} />
            <Product
              {...this.props}
              loadcartitems={this.loadcartitems.bind(this)}
              loading={this.state.loading}
              cartCheck={this.state.cartDetailsMain ? 1 : 0}
            />
            <div className="product_orders">
              <div className="fixed_checkout_order">
                <Link
                  to="/package/checkout"
                  className="btn btn_green btn_fill btn_upp "
                  style={{
                    display: this.state.cartDetailsMain ? "block" : "none",
                  }}
                >
                  Continue to Checkout
                </Link>
              </div>
              <div className="product_orders_top">
                <h4>My Cart</h4>
                <span className="close_product_orders">
                  <img src={close_light} alt="close_light" />
                </span>
              </div>
              {/*<form className="form_sec">
                <div className="srch-btn-grp relative">
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="text"
                        placeholder="Search here..."
                        className="form-control only-bb"
                      />
                    </div>
                  </div>
                  <button>
                    <img src={search} alt="search" />
                  </button>
                </div>
              </form>*/}
              <div className="product_delivery product-card catering_list_products">
                <div className="del_address">
                  <h2>Delivery Address</h2>
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="text"
                        id="postal_code"
                        placeholder="Postal Code"
                        className="form-control"
                        value={
                          cookie.load("catering_postal_code") !== "undefined"
                            ? cookie.load("catering_postal_code")
                            : this.state.catering_postal_code
                        }
                        onChange={this.changePostalCode.bind(this)}
                        onKeyPress={(e) => this.validateIntval(e)}
                      />
                      <span
                        className="error postal_code_error"
                        style={{ display: "none" }}
                      >
                        Please enter valid postal code!
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="text"
                        placeholder="Address Line 1"
                        className="form-control"
                        value={this.state.catering_address_line}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input_field">
                          <input
                            type="text"
                            placeholder="Unit Number 1"
                            className="form-control"
                            name="floor_no"
                            value={this.state.catering_floor_no}
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input_field">
                          <input
                            type="text"
                            placeholder="Unit Number 2"
                            className="form-control"
                            name="unit_no"
                            value={this.state.catering_unit_no}
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group datetime-wrap catering_event">
                    <h3>Event Date</h3>
                    <div className="input_field">
                      <DatePicker
                        selected={
                          cookie.load("order_date")
                            ? new Date(cookie.load("order_date"))
                            : this.state.orderDate
                        }
                        onChange={this.handleChange}
                        className="form-control order_date"
                        placeholder="Event Date"
                        excludeDates={this.state.disable_dates}
                        minDate={this.state.mindate}
                        //  maxDate={this.state.maxdate}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <span
                      className="error order_date_error"
                      style={{ display: "none" }}
                    >
                      Please select order date!
                    </span>
                  </div>
                </div>
                <div className="order_det">
                  {this.state.cartDetailsMain ? this.getCartDetList() : ""}
                  <div
                    className="order_card dark addition-breaktime"
                    data-index=""
                    style={{ display: "none" }}
                  >
                    <div className="order_card_head">
                      Order Details{" "}
                      <Link
                        onClick={this.deleteCartItm.bind(this, "", "alert")}
                        to="#"
                      >
                        <img src={imageName} alt="delete" />
                      </Link>
                    </div>
                    <div className="order_card_body">
                      <div className="event_timing">
                        <div className="form-group">
                          <div className="input_field">
                            <label>Event Start Time</label>
                            <div className="custom_select">
                              <select
                                className="form-control"
                                id="event_start_timer"
                                name="start_time"
                                onChange={this.handleUpdateChange.bind(this)}
                              >
                                {Parser(this.getbreaktimelist(""))}
                              </select>
                              <span
                                className="error start_time_error"
                                style={{ display: "none" }}
                              >
                                This Field is required!
                              </span>
                            </div>
                          </div>
                          <span
                            data-tip={this.state.delivery_blocks}
                            data-html={true}
                            data-class="default_tooltip order_tooltip"
                            className="tooltip_link"
                          >
                            <img src={warning} alt="warning" />
                          </span>
                        </div>
                        <div className="form-group">
                          <div className="input_field">
                            <label>Event End Time</label>
                            <div className="custom_select">
                              <select
                                className="form-control"
                                name="end_time"
                                id="event_end_timer"
                                onChange={this.handleUpdateChange.bind(this)}
                              >
                                {Parser(this.getbreaktimelist(""))}
                              </select>
                              <span
                                className="error end_time_error"
                                style={{ display: "none" }}
                              >
                                This Field is required!
                              </span>
                            </div>
                          </div>
                          <span
                            data-tip={this.state.delivery_blocks}
                            data-html={true}
                            data-class="default_tooltip order_tooltip"
                            className="tooltip_link"
                          >
                            <img src={warning} alt="warning" />
                          </span>
                        </div>
                      </div>
                      <div className="products_cart text_center">
                        <img src={cart_blue} alt="cart" />
                        <span>You haven’t added any items yet !</span>
                      </div>
                    </div>
                  </div>
                  {!this.state.cartDetailsMain && (
                    <div className="text_center">
                      <Link
                        className="link add-breaktime"
                        to="#"
                        onClick={this.changeImage.bind(this)}
                      >
                        {this.state.cartDetailsMain
                          ? "Add Another Break Time & Order"
                          : "Select Event Time"}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="products_total product-card"
                style={{
                  display: this.state.cartDetailsMain ? "block" : "none",
                }}
              >
                {/* <div className="products_total_item">
                  <p>Setup</p>                 
                  <span className="total_cart_price">
                    {this.state.cartDetailsMain
                      ? showPriceValue(
                          this.state.cartDetailsMain.cart_grand_total
                        )
                      : ""}
                  </span>
                </div> */}

                <div className="products_total_item">
                  <p>Subtotal</p>
                  <span className="total_cart_price">
                    {this.state.cartDetailsMain
                      ? showPriceValue(
                          this.state.cartDetailsMain.cart_grand_total
                        )
                      : ""}
                  </span>
                </div>

                {/* {parseFloat(this.state.zone_delivery_charge) > 0 && (
                  <div className="products_total_item">
                    <p>Delivery Charge</p>
                    <span>{showPriceValue(zone_delivery_charge)}</span>
                  </div>
                )}
                {parseFloat(this.state.zone_additional_delivery_charge) > 0 && (
                  <div className="products_total_item">
                    <p>Additional Delivery Charge</p>
                    <span>
                      {showPriceValue(zone_additional_delivery_charge)}
                    </span>
                  </div>
                )} */}

                {(parseFloat(this.state.zone_delivery_charge) > 0 ||
                  parseFloat(this.state.zone_additional_delivery_charge) >
                    0) && (
                  <div className="products_total_item">
                    <p>Location surcharge</p>
                    <span>
                      {showPriceValue(
                        zone_delivery_charge + zone_additional_delivery_charge
                      )}
                    </span>
                  </div>
                )}

                {/*parseFloat(this.state.surcharge) > 0 && (
                  <div className="products_total_item">
                    <p>Surcharge</p>
                    <span>{showPriceValue(surcharge)}</span>
                  </div>
                )*/}

                {parseFloat(this.state.surcharge) > 0 &&
                  sur_deliverysetup_fee > 0 && (
                    <div className="products_total_item">
                      <p>Delivery & setup fee {sur_deliverysetup_fee_name}</p>
                      <span>{showPriceValue(sur_deliverysetup_fee)}</span>
                    </div>
                  )}

                {parseFloat(this.state.surcharge) > 0 &&
                  sur_ear_deliverysetup_fee > 0 && (
                    <div className="products_total_item">
                      <p>
                        Early setup fee / Late setup fee{" "}
                        {sur_ear_deliverysetup_fee_name}
                      </p>
                      <span>{showPriceValue(sur_ear_deliverysetup_fee)}</span>
                    </div>
                  )}

                {parseFloat(this.state.surcharge) > 0 &&
                  sur_lat_teardown_fee > 0 && (
                    <div className="products_total_item">
                      <p>Late teardown fee</p>
                      <span>{showPriceValue(sur_lat_teardown_fee)}</span>
                    </div>
                  )}

                {parseFloat(gstPar) > 0 && (
                  <div className="products_total_item">
                    <p className="text-uppercase">GST ({gstPar}%)</p>
                    <span>{showPriceValue(gstAmount)}</span>
                  </div>
                )}

                <div className="products_total_item products_total_amount">
                  <p>Total </p>
                  <span>{showPriceValue(grandTotalAmt)}</span>
                </div>
                <Link
                  className="btn btn_green btn_fill btn_upp checkout_btn"
                  to="#"
                  onClick={this.checkPostalcode.bind(this)}
                >
                  Continue to Checkout
                </Link>
              </div>
              <div
                className="text_center"
                style={{
                  display: this.state.cartDetailsMain ? "block" : "none",
                }}
              >
                <Link
                  className="link download_quote"
                  to="#"
                  onClick={this.downloadCartItem.bind(this)}
                >
                  Download Quotation
                </Link>
              </div>
            </div>
          </div>
          <div className="fixed_checkout">
            <Link
              to="/package/checkout"
              className="btn btn_green btn_fill btn_upp checkout_btn"
              to="#"
              onClick={this.checkPostalcode.bind(this)}
              style={{
                display: this.state.cartDetailsMain ? "block" : "none",
              }}
            >
              Continue to Checkout
            </Link>
          </div>
        </div>

        <Modal_Popup
          modal={this.state.modalPopup}
          toggle={this.closeToggle}
          className="popup_sec popup_product_details"
        >
          <div className="product_details_popup">
            {(productData || []).map((productDetail, index) => {
              return (
                <div key={index} className="main_product_index">
                  <div className="package_details">
                    <div className="package_details_left">
                      <Slider {...settings}>
                        {productDetail.image_gallery.length > 0 ? (
                          (productDetail.image_gallery || []).map(
                            (image, gindex) => {
                              return (
                                <div key={gindex}>
                                  <img
                                    src={
                                      product_gallery_image_source +
                                      "/" +
                                      image.pro_gallery_image
                                    }
                                    alt="product"
                                  />
                                </div>
                              );
                            }
                          )
                        ) : (
                          <img src={noimage} alt="" />
                        )}
                      </Slider>
                    </div>

                    <div className="package_details_right">
                      <div className="package_details_right_inner">
                        <div className="pdr_left">
                          <h4 key={index}>
                            {stripslashes(productDetail.product_name)}
                          </h4>
                          <p>
                            {productDetail.product_long_description !== null &&
                              productDetail.product_long_description != "" &&
                              Parser(
                                stripslashes(
                                  productDetail.product_long_description
                                )
                              )}

                            {productDetail.product_long_description === "" &&
                              productDetail.product_short_description != "" &&
                              Parser(
                                stripslashes(
                                  productDetail.product_short_description
                                )
                              )}
                          </p>
                        </div>
                        <div className="pdr_right">
                          <h4 id="show_total_proice">
                            {parseInt(this.state.productGrandTotal) > 0
                              ? showPriceValue(this.state.productGrandTotal)
                              : showPriceValue(
                                  productDetail.product_min_pax *
                                    productDetail.product_price
                                )}
                          </h4>

                          {productDetail.hide_min_pax_label != 1 && (
                            <h5>Choose Pax</h5>
                          )}
                          <span className="counter">
                            <button
                              className="qty_minus"
                              onClick={this.updateProductPax.bind(
                                this,
                                index,
                                "minus",
                                this.state.selectpaxcount <
                                  productDetail.product_min_pax
                                  ? productDetail.product_min_pax
                                  : this.state.selectpaxcount,
                                productDetail.product_min_pax
                              )}
                            >
                              -
                            </button>

                            <span className="products_quantity">
                              {this.state.selectpaxcount <
                              productDetail.product_min_pax
                                ? productDetail.product_min_pax
                                : this.state.selectpaxcount}
                            </span>
                            <button
                              className="qty_plus"
                              onClick={this.updateProductPax.bind(
                                this,
                                index,
                                "plus",
                                this.state.selectpaxcount <
                                  productDetail.product_min_pax
                                  ? productDetail.product_min_pax
                                  : this.state.selectpaxcount,
                                productDetail.product_min_pax
                              )}
                            >
                              +
                            </button>
                          </span>
                          <i
                            className="product-min-pax"
                            data-productminpax={productDetail.product_min_pax}
                            style={{
                              display:
                                productDetail.hide_min_pax_label == 1
                                  ? "none"
                                  : "block",
                            }}
                          >
                            MIN Pax : {productDetail.product_min_pax}
                          </i>

                          {typeof productDetail.product_tag !== "undefined" &&
                            productDetail.product_tag.length > 0 && (
                              <div className="flavours">
                                {(productDetail.product_tag || []).map(
                                  (tag, tindex) => {
                                    return (
                                      <img
                                        src={
                                          tag_image_source + tag.pro_tag_image
                                        }
                                        alt={tag.pro_tag_name}
                                        key={tindex}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            )}
                        </div>
                        {/*<div className="step-buttons" >
                    <span></span>
                      <ul className="clear_ul">
                        {
                          (productDetail.set_menu_component  || []).map((comp, msindex) => {
                            var serial_no = msindex + 1;
                            var selected_values_array = 0;
                            if(comp.menu_component_min_select > 0){
                             var classes= 'required';
                            }
                            return (
                             <li key={msindex} id={comp.menu_set_component_id} data-selected=''>
                                <a href={"#"+serial_no+"s"} className={"stepbutton_status "+ classes} id={"step_"+comp.menu_set_component_id}>{serial_no}</a>
                              </li>
                              )
                            })
                        }
                      </ul>
                  </div>*/}
                      </div>
                    </div>
                  </div>

                  <div className="product_acc_grp">
                    {(productDetail.set_menu_component || []).map(
                      (component, cindex) => {
                        var menuCmpId = component.menu_component_id;
                        return (
                          <div
                            className="accordion"
                            key={cindex}
                            id={`${cindex + 1}s`}
                          >
                            <div className="accordion_title active">
                              {" "}
                              {component.menu_set_component_name}{" "}
                              {component.menu_component_min_select > 0 && (
                                <span>
                                  Minimum Select{" "}
                                  {component.menu_component_min_select}{" "}
                                </span>
                              )}
                            </div>
                            <div
                              className="accordion_body main_combo_div"
                              data-packageminlimit={
                                component.menu_component_min_select
                              }
                              data-packagemaxlimit={
                                component.menu_component_max_select
                              }
                              data-componentapplyprice={
                                component.menu_component_apply_price
                              }
                              id={"product_" + component.menu_set_component_id}
                              data-id={component.menu_set_component_id}
                            >
                              <div className="accordion_body_inner">
                                <div
                                  className="pkgerror_txt min_product_err"
                                  style={{ display: "none" }}
                                >
                                  Please select 1 more item!
                                </div>
                                <div className="product_card_desc">
                                  <div className="desc_left">
                                    {component.menu_set_description !== null &&
                                    component.menu_set_description !== ""
                                      ? Parser(
                                          stripslashes(
                                            component.menu_set_description
                                          )
                                        )
                                      : ""}
                                  </div>
                                  <div className="desc_right">
                                    <ul className="clear_ul">
                                      {(component.product_details || []).map(
                                        (product_details, deindex) => {
                                          return (
                                            product_details.product_tag || []
                                          ).map((product_tag, taindex) => {
                                            const tags =
                                              flavour.indexOf(
                                                product_tag.pro_tag_image
                                              ) === -1
                                                ? flavour.push(
                                                    product_tag.pro_tag_image
                                                  )
                                                : "";
                                          });
                                        }
                                      )}
                                      {(flavour || []).map((fla, findex) => {
                                        return (
                                          <li key={findex}>
                                            {" "}
                                            <img
                                              src={tag_image_source + fla}
                                              alt=""
                                            />{" "}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                                <div className="product_det_cards">
                                  {(component.product_details || []).map(
                                    (product_details, mindex) => {
                                      let selectvalue =
                                        this.checkProductSelected(
                                          product_details.product_id,
                                          0,
                                          "product",
                                          component.menu_component_min_select,
                                          menuCmpId
                                        );

                                      let selcted_vall = selectvalue
                                        ? "selected"
                                        : "";

                                      return (
                                        <div
                                          className="det_card  compo_minmax_maindiv"
                                          key={mindex}
                                          data-mismatchpro=""
                                        >
                                          <a
                                            href="#"
                                            className="prd_close"
                                            style={{
                                              display: selectvalue
                                                ? "block"
                                                : "none",
                                            }}
                                          >
                                            <img
                                              src={close_light}
                                              alt="close"
                                              onClick={this.incComboQty.bind(
                                                this,
                                                component.menu_set_component_id,
                                                product_details.product_primary_id,
                                                "cancel",
                                                product_details.product_id,
                                                "",
                                                "",
                                                1
                                              )}
                                            />
                                          </a>

                                          <div
                                            className={
                                              "det_inner individual_combo_pro indvcombo-" +
                                              menuCmpId +
                                              "-" +
                                              product_details.product_primary_id +
                                              " " +
                                              selcted_vall
                                            }
                                            data-customlogobanner={
                                              parseInt(
                                                product_details.custom_text_logo
                                              ) === 1
                                                ? 1
                                                : 0
                                            }
                                            data-excludepricemultiplypax={
                                              product_details.exclude_price_multiply_pax
                                            }
                                            data-modifierapply={
                                              product_details.modifiers.length >
                                              0
                                                ? 1
                                                : 0
                                            }
                                            data-combodata={
                                              component.menu_component_id +
                                              "~" +
                                              component.menu_component_name +
                                              "~0"
                                            }
                                            data-invcomboprice={
                                              product_details.product_price
                                            }
                                            data-extraprice=""
                                            data-extraqty="0"
                                            data-extraproductprice="0"
                                            data-proddet={
                                              product_details.product_id +
                                              "~~" +
                                              product_details.product_name +
                                              "~~" +
                                              product_details.product_sku +
                                              "~~" +
                                              product_details.product_price
                                            }
                                          >
                                            <div className="det_inner_image">
                                              {product_details.product_thumbnail !==
                                              "" ? (
                                                <img
                                                  src={
                                                    image_source +
                                                    "/" +
                                                    product_details.product_thumbnail
                                                  }
                                                  alt=""
                                                />
                                              ) : (
                                                <img src={noimage} alt="" />
                                              )}
                                            </div>
                                            <div className="det_inner_content">
                                              <h5 className="text_center">
                                                {stripslashes(
                                                  product_details.product_name
                                                )}
                                              </h5>
                                              <p className="product_desc text_center">
                                                {product_details.product_short_description !==
                                                ""
                                                  ? Parser(
                                                      stripslashes(
                                                        product_details.product_short_description
                                                      )
                                                    )
                                                  : ""}
                                              </p>

                                              {product_details.modifiers
                                                .length > 0 &&
                                                this.modfierValuelistMain(
                                                  index,
                                                  cindex,
                                                  mindex,
                                                  product_details,
                                                  component,
                                                  modifier_value_image_source,
                                                  selectvalue,
                                                  selcted_vall
                                                )}

                                              {product_details.product_quantity_inc ==
                                                1 &&
                                                product_details.modifiers
                                                  .length > 0 &&
                                                this.qtyoptionContentForModifier(
                                                  product_details,
                                                  component,
                                                  selectvalue,
                                                  selcted_vall
                                                )}
                                              {product_details.product_quantity_inc ==
                                                1 &&
                                                product_details.modifiers
                                                  .length == 0 &&
                                                this.qtyoptionContent(
                                                  product_details,
                                                  component,
                                                  tag_image_source,
                                                  selectvalue,
                                                  selcted_vall
                                                )}

                                              {product_details.modifiers
                                                .length > 0 && (
                                                <div
                                                  className="text_center modifier-btn-grp"
                                                  style={{
                                                    display: selectvalue
                                                      ? ""
                                                      : "none",
                                                  }}
                                                >
                                                  <a
                                                    href="#"
                                                    className="btn btn_green btn_sm mdf-done"
                                                    data-id={
                                                      "indvcombo-" +
                                                      menuCmpId +
                                                      "-" +
                                                      product_details.product_primary_id
                                                    }
                                                    id={
                                                      "done-indvcombo-" +
                                                      menuCmpId +
                                                      "-" +
                                                      product_details.product_primary_id
                                                    }
                                                    onClick={this.checkMinSelect.bind(
                                                      this,
                                                      menuCmpId,
                                                      product_details.product_primary_id,
                                                      "modifier",
                                                      product_details.product_id
                                                    )}
                                                  >
                                                    Done
                                                  </a>
                                                  <a
                                                    href="#"
                                                    className="btn btn_brown btn_sm mdf-cancel"
                                                    onClick={this.incComboQty.bind(
                                                      this,
                                                      menuCmpId,
                                                      product_details.product_primary_id,
                                                      "cancel",
                                                      product_details.product_id,
                                                      "",
                                                      "",
                                                      1
                                                    )}
                                                  >
                                                    cancel
                                                  </a>
                                                </div>
                                              )}

                                              {parseInt(
                                                product_details.custom_text_logo
                                              ) === 1 ? (
                                                <div>
                                                  <div className="form-group">
                                                    <div className="input_field">
                                                      <textarea
                                                        className="form-control"
                                                        placeholder="Enter your custom title here..."
                                                        maxLength="15"
                                                      ></textarea>
                                                      <span className="input_desc">
                                                        Maximum 15 characters
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="input-file-sec"
                                                    style={{ display: "none" }}
                                                  >
                                                    <input
                                                      type="file"
                                                      name=""
                                                      className=""
                                                      id="upload-file"
                                                    />
                                                    <span className="file-name">
                                                      Your Logo
                                                    </span>
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="btn btn_dark upload-btn"
                                                    >
                                                      Browse Logo
                                                    </a>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              <div
                                                className="text_center det-wrap custom_checkbox"
                                                id={
                                                  "mdf-" + cindex + "-" + mindex
                                                }
                                                data-productid={
                                                  product_details.product_id
                                                }
                                                data-mdfsub={
                                                  "simple" +
                                                  "~~" +
                                                  product_details.product_id +
                                                  "~~1~~" +
                                                  product_details.product_price
                                                }
                                              >
                                                <p className="text_center">
                                                  <b
                                                    className="popup_price"
                                                    style={{ display: "none" }}
                                                  >
                                                    {parseFloat(
                                                      product_details.product_price
                                                    ) > 0
                                                      ? showPriceValue(
                                                          product_details.product_price
                                                        ) + "/ pax"
                                                      : ""}
                                                  </b>
                                                </p>
                                                <a
                                                  href="#"
                                                  className="btn btn_green btn_sm show_details"
                                                  onClick={this.incComboQty.bind(
                                                    this,
                                                    menuCmpId,
                                                    product_details.product_primary_id,
                                                    product_details.modifiers
                                                      .length > 0
                                                      ? "extraModifier"
                                                      : "simple",
                                                    product_details.product_id,
                                                    "",
                                                    product_details.product_quantity_inc ===
                                                      1
                                                      ? 1
                                                      : 0,
                                                    1
                                                  )}
                                                  data-modifierapply={
                                                    product_details.modifiers
                                                      .length > 0
                                                      ? 1
                                                      : 0
                                                  }
                                                  data-customproduct={
                                                    product_details.product_quantity_inc ===
                                                    1
                                                      ? 1
                                                      : 0
                                                  }
                                                  style={{
                                                    display: selectvalue
                                                      ? "none"
                                                      : "",
                                                  }}
                                                >
                                                  <label
                                                    type="hidden"
                                                    disabled=""
                                                    data-qtyval=""
                                                    className="combo-input-label combo-input combolst_qty_value"
                                                  ></label>{" "}
                                                  Select
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>

                  {Object.keys(productDetail.delivery).length > 0 ? (
                    <div className="additionals additionals_ex">
                      <div className="row">
                        <div className="col-lg-6" id={"setup_" + index}>
                          <div className="custom_radio">
                            <input
                              type="radio"
                              name="settup_details"
                              value="delivery"
                              defaultChecked={
                                this.state.currentSettup === "Delivery"
                                  ? true
                                  : false
                              }
                              onChange={this.handleUpdateCurrentSetup.bind(
                                this,
                                "Delivery"
                              )}
                            />{" "}
                            <span>Delivery</span>
                          </div>
                          {this.state.currentSettup === "Delivery" && (
                            <ul className="additionals-deliverylist additionals_deliverylist">
                              {this.getDeliverysetup(
                                index,
                                productDetail.delivery
                              )}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {Object.keys(productDetail.buffet).length > 0 ? (
                    <div className="additionals additionals_ex">
                      <div className="row">
                        <div className="col-lg-6" id={"bufsetup_" + index}>
                          <div className="custom_radio">
                            <input
                              type="radio"
                              name="settup_details"
                              value="buffet"
                              defaultChecked={
                                this.state.currentSettup === "Buffet"
                                  ? true
                                  : false
                              }
                              onChange={this.handleUpdateCurrentSetup.bind(
                                this,
                                "Buffet"
                              )}
                            />{" "}
                            <span>Buffet</span>
                          </div>
                          {this.state.currentSettup === "Buffet" && (
                            <ul className="additionals-deliverylist buffet_listing_div">
                              {this.getBuffetsetup(index, productDetail.buffet)}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {Object.keys(productDetail.self_collection).length > 0 ? (
                    <div className="additionals additionals_ex">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          id={"selfcollectionsetup_" + index}
                        >
                          <h5>Self Collection </h5>

                          <ul className="additionals-deliverylist self_collection_listing_div">
                            {this.getSelfCollectionsetup(
                              index,
                              productDetail.self_collection
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {Object.keys(productDetail.equipment).length > 0 && (
                    <div className="additionals additionals_ex">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          id={"equipmentsetup_" + index}
                        >
                          <h5>Equipment</h5>

                          <ul className="additionals-deliverylist additionals_equipmentlist equipment_listing_div">
                            {this.getEquipmentsetup(
                              index,
                              productDetail.equipment
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {Object.keys(productDetail.addons_setup).length > 0 ? (
                    <div className="additionals additionals_ex">
                      <h5>
                        <span>Addons</span>
                        <small>(Choose your addons )</small>
                      </h5>
                      <ul className="addon_checklist clearfix">
                        {this.productAddonsSetup(
                          index,
                          productDetail.addons_setup
                        )}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}

            <div className="additionals">
              <div className="row">
                {(staticblockData || []).map((staticblock, staticindex) => {
                  return (
                    <div key={staticindex}>
                      {staticblock.staticblocks_slug === "popup-disclaimer"
                        ? Parser(
                            stripslashes(staticblock.staticblocks_description)
                          )
                        : ""}
                    </div>
                  );
                })}
                <div className="col-sm-12 instruction">
                  <h5>Add special instruction</h5>
                  <div className="form-group">
                    <div className="input_field">
                      <textarea
                        className="form-control spl_instruction"
                        name="spl_instruction"
                        value={this.state.specialInstruction}
                        onChange={this.handleSpecialInstruction.bind(this)}
                      ></textarea>
                    </div>
                  </div>

                  <input
                    type="hidden"
                    id="set_menu_component_type"
                    name="set_menu_component_type"
                    value={minMaxSelect}
                  />
                  <input
                    type="hidden"
                    id="product_unitprice"
                    name="product_unitprice"
                    value={compoTotalPrice}
                  />
                  <input
                    type="hidden"
                    id="incr_compo_price"
                    name="incr_compo_price"
                    value={this.state.productGrandTotal}
                  />

                  <Link
                    to="#"
                    className="btn btn_green btn_upp btn_fill btn_lg add_to_cart"
                    onClick={this.updateCartPackage.bind(
                      this,
                      productData,
                      "done",
                      "0"
                    )}
                  >
                    Update Cart
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal_Popup>

        <ReactTooltip />
        <Footer {...this.props} />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCategory: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getProduct: (parameter) => {
      dispatch({ type: GET_CATERING_PRODCUTLIST, parameter });
    },
    getStaticblock: (staticblock_list) => {
      dispatch({
        type: GET_STATIC_BLOCK,
        staticblock_list:
          "package-banner,popup-disclaimer,delivery-charge-calculation",
      });
    },
    updateCartDetail: (productId, cartItemId, cartQty) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty });
    },
    getCarttimedata: (obj) => {
      dispatch({ type: GET_AVAILABLE_TIME, avlDateFlg: obj });
    },
    getCartdatedata: (obj) => {
      dispatch({ type: GET_AVAILABLE_DATE, avlDateFlg: obj });
    },
    getCartList: (obj) => {
      dispatch({ type: GET_CARTLIST, obj });
    },
    uploadImage: (obj) => {
      dispatch({ type: GET_UPLOAD_IMAGE, obj });
    },
    deleteImage: (obj) => {
      dispatch({ type: GET_DELETE_IMAGE, obj });
    },
  };
};

const mapStateToProps = (state) => {
  let categoryData,
    productData,
    cartdate,
    carttime,
    staticblockData = [];
  if (Object.keys(state.categorylist).length > 0) {
    if (
      state.categorylist[0].status === "ok" &&
      Object.keys(state.categorylist[0].result_set).length > 0
    ) {
      categoryData = state.categorylist[0];
    }
  }

  if (Object.keys(state.cateringprodcutlist).length > 0) {
    if (
      state.cateringprodcutlist[0].status === "ok" &&
      Object.keys(state.cateringprodcutlist[0].result_set).length > 0
    ) {
      productData = state.cateringprodcutlist[0];
    }
  }

  if (Object.keys(state.availabiledate).length > 0) {
    if (
      state.availabiledate[0].status === "success" &&
      Object.keys(state.availabiledate[0].result_set).length > 0
    ) {
      cartdate = state.availabiledate[0];
    }
  }

  if (Object.keys(state.availabiletime).length > 0) {
    if (
      state.availabiletime[0].status === "success" &&
      Object.keys(state.availabiletime[0].result_set).length > 0
    ) {
      carttime = state.availabiletime[0];
    }
  }

  if (Object.keys(state.staticblack).length > 0) {
    if (
      state.staticblack[0].status === "ok" &&
      Object.keys(state.staticblack[0].result_set).length > 0
    ) {
      staticblockData = state.staticblack[0];
    }
  }

  return {
    categoryData: categoryData,
    productData: productData,
    staticblockData: staticblockData,
    cartdate: cartdate,
    carttime: carttime,
    cartlist: state.cartlist,
    uploadDataImage: state.uploadDataImage,
    deleteDataImage: state.deleteDataImage,
  };
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
