/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { stripslashes } from "../../Helpers/SettingHelper";
import { GET_TESTIMONIAL } from "../../../actions";
import blogImg from "../../../common/images/blog-placeholder.jpg";

class Testimonial extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBlogList();
  }
  render() {
    let testimonialArr = this.props.testimonial;
    let testimoniallist = [];
    let testimonialimagesource = "";

    if (Object.keys(testimonialArr).length > 0) {
      if (testimonialArr[0].status === "ok") {
        testimoniallist = testimonialArr[0].result_set;
        testimonialimagesource = testimonialArr[0].common.image_source;
      }
    }

    var settingsTestimonial = {
      items: 3,
      autoplay: false,
      margin: 20,
      loop: true,
      center: true,
      nav: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        780: {
          items: 3,
        },
      },
    };
    return (
      <>
        {Object.keys(testimoniallist).length > 0 && (
          <section className="home-testimonial">
            <div className="container">
              <h2 className="home-title">What they said</h2>

              <div className="home-blog-slider">
                <OwlCarousel options={settingsTestimonial}>
                  {testimoniallist.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="inner-testimonials">
                          <div className="testi-content">
                            {item.testimonial_description !== "" &&
                              item.testimonial_description !== null && (
                                <p>
                                  {stripslashes(item.testimonial_description)}
                                </p>
                              )}
                          </div>
                          <div className="testi-author">
                            <div className="testi-author-img">
                              <img
                                src={
                                  item.testimonial_image !== "" &&
                                  item.testimonial_image !== null
                                    ? testimonialimagesource +
                                      item.testimonial_image
                                    : blogImg
                                }
                              />
                            </div>
                            <div className="testi-author-name">
                              <h4>{stripslashes(item.testimonial_tittle)}</h4>
                              {item.testimonial_company !== "" && (
                                <span>
                                  {stripslashes(item.testimonial_company)}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    testimonial: state.testimonial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBlogList: () => {
      dispatch({ type: GET_TESTIMONIAL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Testimonial);
