import { takeEvery, call, put } from "redux-saga/effects";
import { GET_DELETE_CATERING_CART, SET_DELETE_CATERING_CART } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import { getReferenceID } from "../components/Helpers/SettingHelper";
import cookie from "react-cookies";
import Axios from "axios";
var qs = require("qs");
export const watchGetCateringDeleteCart = function* () {
  yield takeEvery(GET_DELETE_CATERING_CART, workerGetCateringDeleteCart);
};

function* workerGetCateringDeleteCart({ cartItemId }) {
  try {
    var postObject = {};
    postObject = {
      app_id: appId,
      cart_item_id: cartItemId,
      cartAction: "Delete",
    };

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getReferenceID();
    } else {
      postObject["customer_id"] = cookie.load("UserId");
    }

    const uri = apiUrl + "cart/delete";
    const result = yield call(Axios.post, uri, qs.stringify(postObject));

    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_DELETE_CATERING_CART, value: resultArr });
  } catch (e) {
    console.log(e, "Get Delete Cart Failed");
  }
}
