import PropTypes from "prop-types"
import React, { useEffect, useState, useCallback, useRef } from "react"
import { createClient, CheckoutContainer } from "@koomipay/react-legacy"

const KoomipayCheckout = (props) => {
  const { order, clientKey, paymentAmount } = props
  const koomipayClient = useRef(null)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null)
  const [amount, setAmount] = useState()
  const [isValid, setIsValid] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [paymentData, setPaymentData] = useState({})
  const [redirectUrl, setRedirectUrl] = useState("")

  const [payment, setPayment] = useState(null)

  const initKoomipayClient = useCallback(async () => {
    const price = Number(paymentAmount)
    if (clientKey && price && !koomipayClient.current) {
      koomipayClient.current = createClient({ apiKey: clientKey })
      const amount = {
        currency: "SGD",
        price,
      }
      const { paymentMethods, success } = await koomipayClient.current.getPaymentMethods({
        amount,
        countryCode: "SG",
      })
      if (success && paymentMethods.length) {
        setPaymentMethods(paymentMethods)
        setCurrentPaymentMethod(paymentMethods[0].type === "scheme" ? paymentMethods[0] : null)
        setAmount(amount)
      }
    }
  }, [clientKey, paymentAmount])

  useEffect(() => {
    initKoomipayClient()
  }, [initKoomipayClient])

  useEffect(() => {
    if (redirectUrl) {
      window.addEventListener("message", handle3DSCompleteEvent, false)
      return () => {
        window.removeEventListener("message", handle3DSCompleteEvent)
      }
    }
  }, [redirectUrl])

  function handleCompleteCheckout(order) {
    const { onComplete } = props
    if (onComplete && typeof onComplete === "function") {
      onComplete(order)
    }
  }

  function handle3DSCompleteEvent(event) {
    setRedirectUrl("")
    if (event.origin !== document.location.origin || !event.data || event.data.type !== "3DS-complete") return

    const result = event.data && event.data.result
    if (result === "success") {
      handleCompleteCheckout(payment)
    } else {
      alert("Proceed payment failed, please try again\n\nError: " + event.data && event.data.refusalReason)
    }
  }

  const payNow = async (e) => {
    e.preventDefault()
    if (koomipayClient.current && order) {
      const { order_id_for_koomipay, grand_total, products } = order
      const total = Number(grand_total)
      if (!order_id_for_koomipay || !total) {
        return
      }

      let items = []
      try {
        items = JSON.parse(products)
      } catch (error) {
        items = []
      }

      setSubmitting(true)
      const { browserInfo, ...params } = paymentData
      const response = await koomipayClient.current.checkout({
        orderID: order_id_for_koomipay,
        paymentMethod: params,
        browserInfo: browserInfo,
        amount: amount,
        items: (items || []).map((i) => ({
          productID: i.product_id,
          productName: i.product_name,
          quantity: Number(i.product_qty),
          price: Number(i.product_unit_price),
        })),
        returnUrl: document.location.origin + "/checkout/koomipay-complete",
      })

      const data = response.data || {}

      setSubmitting(false)
      if (data && data.success) {
        const redirectUrl = data.data && data.data.redirect3ds
        if (redirectUrl) {
          setPayment(data.data)
          setRedirectUrl(redirectUrl)
        } else {
          if (data.data && data.data.resultCode === "Authorised") {
            setPayment(data.data)
            handleCompleteCheckout(data.data)
            return null
          } else {
            const message = data.data?.additionalData?.["retry.attempt1.responseCode"] || "There was an error with your payment. Please try again."
            alert(message)
          }
        }
      } else {
        const message = data.data?.additionalData?.["retry.attempt1.responseCode"] || "There was an error with your payment. Please try again."
        alert(message)
      }
    }
  }

  if (redirectUrl) {
    return (
      <div className="koomipay-checkout">
        <iframe src={redirectUrl} title={"3DS Authentication"} height={480} width={"100%"}></iframe>
      </div>
    )
  }

  const notValid = !isValid || submitting

  return (
    <div className="custom_alert koomipay-checkout">
      <div className="custom_alertin">
        <div className="alert_height">
          <div className="alert_header">Koomipay Payment</div>
          <div className="alert_body">
            {!currentPaymentMethod ? (
              <div class="inset-0 flex flex-col gap-3 items-center justify-center pointer-events-none">
                <div class="w-16 h-16">
                  <svg class="w-full h-full animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="alt_btns">
                <form onSubmit={payNow} action="#">
                  <div className="paymentsection cccard_paysec">
                    <CheckoutContainer client={koomipayClient.current} amount={amount} paymentMethod={currentPaymentMethod} onValid={setIsValid} onChange={setPaymentData} />
                    {!!amount && (
                      <button className="button" style={notValid ? { opacity: 0.5, background: "black" } : {}} disabled={notValid}>
                        {submitting ? (
                          "Processing..."
                        ) : (
                          <>
                            Pay {amount.currency} {parseFloat(amount.price).toFixed(2)}
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
KoomipayCheckout.propTypes = {
  clientKey: PropTypes.string,
  paymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPay: PropTypes.func,
}

export default KoomipayCheckout
