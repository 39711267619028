/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { GET_INSTA_FEED } from "../../../actions";

import instagramImg from "../../../common/images/instagram_icon.png";
var Parser = require("html-react-parser");

class InstaFeed extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBannerList();
  }

  render() {
    let bannerArr = this.props.instafeed;

    var settingsourclient = {
      items: 6,
      nav: true,
      autoplay: true,
      dots: false,
      margin: 10,
      autoplayTimeout: 2000,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 4,
        },
        1200: {
          items: 6,
        },
      },
    };

    return (
      <section class="instapro-section">
        {Object.keys(bannerArr).length > 0 && (
          <div class="instapro-section-inner">
            <div class="container">
              <a href="https://www.instagram.com/" target="_blank">
                <h2 className="home-title">
                  {" "}
                  <img src={instagramImg} />
                  <span>Tigga Folks</span>
                </h2>
              </a>
              <div className="home-instafeed">
                <OwlCarousel options={settingsourclient}>
                  {bannerArr.map((item, index) => {
                    if (item.media_type !== "VIDEO") {
                      return (
                        <div key={index} className="home-instafeed-image">
                          <img src={item.media_url} />
                        </div>
                      );
                    }
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  var instafeeds = Array();
  if (Object.keys(state.instafeed).length > 0) {
    if (state.instafeed[0]) {
      instafeeds = state.instafeed[0].data;
    }
  }

  return {
    instafeed: instafeeds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_INSTA_FEED });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(InstaFeed);
