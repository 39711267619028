/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import update from "immutability-helper";
import FacebookLogin from "react-facebook-login";
import { format } from "date-fns";
var dateFormat = require("dateformat");

import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  dineinId,
  baseUrl,
  fbAppId,
  outletUrl,
  mediaUrl,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_FORGET_PASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
  GET_TABLEAVAILABILITY,
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  meridiem,
} from "../Helpers/SettingHelper";

import OrderdatetimeSlot from "./OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
  Guestcheckout,
} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win-b.svg";
import warningImg from "../../common/images/warning.svg";

import avicon from "../../common/images/av-icon.png";
import loupe from "../../common/images/loupe.svg";
import CartSideBar from "./CartSideBar";

import fbscootersvg from "../../common/images/delivery-bike.svg";
import fbscooter from "../../common/images/delivery-bike.svg";
import fbscooterw from "../../common/images/delivery-bike-white.svg";
import takeawayImg from "../../common/images/fb-takeaway.png";
import takeawaywImg from "../../common/images/fb-takeaway-w.png";
import dineImg from "../../common/images/fb-dinein.png";
import dinewImg from "../../common/images/fb-dinein-w.png";
import calenderImg from "../../common/images/fb-calender.png";
import calenderwImg from "../../common/images/fb-calender-w.png";
import menuImg from "../../common/images/fb-menu.png";
import menuwImg from "../../common/images/fb-menu-w.png";
import qrImg from "../../common/images/qr-code.png";
import cateringImg from "../../common/images/catering.svg";
import cateringwImg from "../../common/images/catering-white.svg";
import shoppingBag from "../../common/images/shopping-bag.svg";

import cartLike from "../../common/images/heart-white.svg";
import cartLikeActive from "../../common/images/heart-red.svg";

import outletNoImage from "../../common/images/noimg-400x400.jpg";

var Parser = require("html-react-parser");
class Header extends Component {
  constructor(props) {
    super(props);
    var defaultAvilablityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    this.state = {
      seletedAvilablityId: defaultAvilablityId,
      defaultAvilablityId: defaultAvilablityId,
      seletedOutletId: "",
      setAvilablityId: defaultAvilablityId,
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      searchProResult: [],
      selectedProResult: [],
      orderHandled: "",
      orderDeliveryAddress: "",
      orderDeliveryPostalCode: "",
      nextavail: "",
      logindata: "",
      deliveryInfo: [],
      pickupInfo: [],

      /* For Advanced Slot Start */
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "0",
      /* For Advanced Slot End */
      fields: {
        email: "",
        pin: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscheckout: {
        check_email: "",
        check_firstname: "",
        check_phone: "",
        check_ref_code: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        pin: "",
        rePin: "",
        mobile: "",
        pdpa_consent: "",
        terms: false,
        terms1: false,
        completeReg: "N",
        check_ref_code:
          cookie.load("referCode") !== "" &&
          typeof cookie.load("referCode") !== undefined &&
          typeof cookie.load("referCode") !== "undefined"
            ? cookie.load("referCode")
            : "",
      },
      menuData: [],
      popupOutletID: "",
      popupOutletDetails: "",
      error_order_from: "",
      error_order_type: "",
      error_pickup_outlet: "",
      outletCateList: [],
      switchmsg: "",
      fbstatus: "ok",
      dine_in_click: "No",
      table_number: "",
      delivery_availability_enable: false,
      takeaway_availability_enable: false,
      catering_availability_enable: false,
      availability_list: [],
    };

    this.props.getGlobalSettings();
    this.props.getPickupOutlets();
    // this.props.getAllOutlets(deliveryId);
    this.props.getAllOutlets();
    this.props.getSecondaryAddress();

    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      logintype: "mobile",
      passwordtype: "pin",
      login_username: formPayload.email,
      login_password: formPayload.pin,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "terms1") {
      value = $("#terms1").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }

    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });

    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      registertype: "mobile",
      passwordtype: "pin",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_pin: formPayload.pin,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      customer_newsletter_enable: formPayload.terms1 === "Y" ? "yes" : "no",
      site_url: this.props.globalsettings[0].result_set.client_site_url,
      customer_ref_code: formPayload.check_ref_code,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/

  /*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
    this.setState(
      update(this.state, { fieldscheckout: { [field]: { $set: value } } })
    );
  };

  handleCheckout = () => {
    const formPayload = this.state.fieldscheckout;
    if (
      document.getElementById("spn-email-error").innerHTML ===
      '<span class="error">This email already exists</span>'
    ) {
      return false;
    }
    if (
      document.getElementById("spn-mobile-error").innerHTML ===
      '<span class="error">This mobile number already exists</span>'
    ) {
      return false;
    }

    showLoader("login_in_guest", "class");

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.check_firstname,
      customer_email: formPayload.check_email,
      customer_phone: formPayload.check_phone,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
      customer_ref_code: formPayload.check_ref_code,
    };

    axios
      .post(apiUrl + "guestaccount/create", qs.stringify(postObject))
      .then((response) => {
        hideLoader("login_in_guest", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id, {
            path: "/",
          });
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );
          cookie.save("UserMobile", response.data.result_set.customer_phone, {
            path: "/",
          });
          cookie.save("UserEmail", response.data.result_set.customer_email, {
            path: "/",
          });
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            { path: "/" }
          );
          cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
          cookie.save("userAccountType", 1, { path: "/" });
          const { history } = this.props;
          var qs = require("qs");
          var postObject = {
            app_id: appId,
            reference_id: getReferenceID(),
            customer_id: response.data.result_set.customer_id,
            availability_id: cookie.load("defaultAvilablityId"),
          };

          axios
            .post(
              apiUrl + "cart/update_customer_info",
              qs.stringify(postObject)
            )
            .then((res) => {
              showAlert("Success", "Logged in Successfully!");
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              $("body").removeClass("cart-items-open");
              if (res.data.status === "ok") {
                console.log(
                  cookie.load("loginpopupTrigger"),
                  "loginpopupTrigger"
                );
                if (cookie.load("loginpopupTrigger") === "fromcheckout") {
                  cookie.remove("loginpopupTrigger", { path: "/" });
                  history.push("/checkout");
                } else {
                  history.push("/myaccount");
                }
              } else {
                if (cookie.load("redirectReservatin") === "Yes") {
                  cookie.remove("redirectReservatin", { path: "/" });
                  history.push("/reservation");
                } else {
                  history.push("/myaccount");
                }
              }
            });
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState({
        pickupOutlets: PropsDt.outletslist,
        pickupOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }
    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }

    // if (PropsDt.fbloginData !== this.props.fbloginData) {
    //   this.doLogin(PropsDt.fbloginData);
    // }

    if (PropsDt.tableAvailability !== this.props.tableAvailability) {
      // console.log(PropsDt.tableAvailability);
      // console.log(PropsDt.tableAvailability.length);
      if (PropsDt.tableAvailability.status) {
        if (PropsDt.tableAvailability.status == "ok") {
          this.dineinOutlet(PropsDt.tableAvailability.result_set[0]);
        } else {
          let res_obj = {
            status: PropsDt.tableAvailability.status,
            message: PropsDt.tableAvailability.result_set,
          };
          this.showMessage(res_obj);
          hideLoader("dinein_popup_item", "class");
        }
      }
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        var formPay_load = this.state.fieldssignup;
        this.setState({
          regstatus: "ok",
          fieldssignup: {
            firstname: "",
            email: "",
            pin: "",
            rePin: "",
            mobile: "",
            pdpa_consent: "",
            terms: "N",
            terms1: "Y",
            completeReg: "Y",
            check_ref_code: "",
          },
        });
        this.showMessage(PropsDt.registration[0]);
      }
    }

    if (PropsDt.globalsettings == this.props.globalsettings) {
      if (
        Object.keys(PropsDt.globalsettings).length > 0 &&
        this.props.globalsettings !== undefined
      ) {
        var availability_list =
          PropsDt.globalsettings[0].result_set.availability;
        this.setState({ availability_list: availability_list }, function () {
          this.checkAvailabilty();
        });
      }
    }
  }

  checkAvailabilty() {
    var availability_list = this.state.availability_list;

    if (Object.keys(availability_list).length > 0) {
      var delivery_availability_enable = false;
      var takeaway_availability_enable = false;
      var catering_availability_enable = false;

      const getedUser = availability_list.map((item, index) => {
        if (item.availability_name == "Delivery") {
          delivery_availability_enable = true;
        }

        if (item.availability_name == "Pickup") {
          takeaway_availability_enable = true;
        }

        if (item.availability_name == "Catering") {
          catering_availability_enable = true;
        }
      });

      this.setState({
        delivery_availability_enable: delivery_availability_enable,
        takeaway_availability_enable: takeaway_availability_enable,
        catering_availability_enable: catering_availability_enable,
      });
    }
  }

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link
          to={"/page/" + menu.nav_title_slug}
          className="sub_menu_list"
          title={menu.nav_title}
        >
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
            onClick={this.openCategoryNav.bind(this, menu.nav_pages)}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu) {
    if (menu.nav_type === "0") {
      return (
        <Link to={"/page/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
            onClick={this.openCategoryNav.bind(this, menu.nav_pages)}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "menu") {
      returnClsTx =
        this.props.match.path === "/menu" ||
        this.props.match.path === "/menu/:slugType/:slugValue" ||
        this.props.match.path === "/menu/:slugType/:slugValue/:proValue"
          ? "active"
          : "";
    } else if (nav_pages === "about-us") {
      returnClsTx =
        this.props.match.path === "/about-us" ||
        this.props.match.path === "/our-story" ||
        this.props.match.path === "/locations" ||
        this.props.match.path === "/contact-us"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : "";
    }
    return returnClsTx;
  }
  openCategoryNav(pageLink, event) {
    if (pageLink === "products") {
      if (
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
      ) {
        if (cookie.load("defaultAvilablityId") === cateringId) {
          event.preventDefault();
          if ($(".mobile-menu.active").length > 0) {
            $("#close_mobile_menu").trigger("click");
          }
          $("html, body").animate(
            {
              scrollTop: $(".home-options-tab").offset().top - 100,
            },
            500
          );
        }
      }
    }
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        // if(menu.nav_pages !== "reservation") {
        return (
          <li key={i + 100} className={this.menuActiveCls(menu.nav_pages)}>
            {this.createLink(menu)}
            {menu.sub_menu.length > 0 && (
              <>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="submenu-arow disbl_href_action"
                ></a>
                <ul className="submenu_list">
                  {menu.sub_menu.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          this.props.match.path === "/" + item.nav_pages
                            ? "active sub_menu_list"
                            : "sub_menu_list"
                        }
                      >
                        {this.createSubmenu(item)}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </li>
        );
        // }
      }, this);
    }
  }

  // listMainNavigationMob() {
  //   if (this.state.menudata) {
  //     return this.state.menudata.map(function (menu, i) {
  //       return (
  //         <li key={i + 100} className={this.menuActiveCls(menu.nav_pages)}>
  //           {this.createLink(menu)}
  //           {menu.sub_menu.length > 0 && (
  //             <>
  //               <a
  //                 href="/"
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                 }}
  //                 className="submenu-arow disbl_href_action"
  //               ></a>
  //               <ul className="submenu_list">
  //                 {menu.sub_menu.map((item, index) => {
  //                   return (
  //                     <li
  //                       key={index}
  //                       className={
  //                         this.props.match.path === "/" + item.nav_pages
  //                           ? "active sub_menu_list"
  //                           : "sub_menu_list"
  //                       }
  //                     >
  //                       {this.createSubmenu(item)}
  //                     </li>
  //                   );
  //                 })}
  //               </ul>
  //             </>
  //           )}
  //         </li>
  //       );
  //     }, this);
  //   }
  // }

  /* menu navigation -end */
  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  setAvailabilityFun(availability, actFrom, event) {
    event.preventDefault();

    if (availability === "viewmenu") {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#viewmenu-popup",
        },
        type: "inline",
      });
    } else {
      this.setState(
        { setAvilablityId: availability, error_order_type: "" },
        () => {
          this.chooseAvailabilityFun(availability, actFrom, event);
        }
      );
    }
  }

  // chooseAvailabilityFun(availability, event) {
  //   event.preventDefault();

  //   this.setState({ "dine_in_click": "No" });
  //   // if (availability === reservationId || availability === dineinId) {
  //   //   return false;
  //   // }
  //   if (availability === "") {
  //     this.setState({ error_order_type: 1 });
  //   } else {
  //     this.setState({ error_order_type: "" });

  //     var defaultAvilTy = cookie.load("defaultAvilablityId");

  //     if (defaultAvilTy !== availability) {
  //       var cartTotalItems = cookie.load("cartTotalItems");
  //       cartTotalItems =
  //         cartTotalItems != "" && cartTotalItems != undefined
  //           ? parseInt(cartTotalItems)
  //           : 0;
  //       if (defaultAvilTy === cateringId && cartTotalItems > 0) {
  //         this.setState({ nextavail: availability });
  //         $.magnificPopup.open({
  //           items: {
  //             src: "#warning-popup",
  //           },
  //           type: "inline",
  //         });
  //         return false;
  //       } else if (
  //         cookie.load("orderOutletId") != "" &&
  //         cookie.load("orderOutletId") != undefined
  //       ) {
  //         this.setState({ nextavail: availability });
  //         $.magnificPopup.open({
  //           items: {
  //             src: "#warning-popup",
  //           },
  //           type: "inline",
  //         });
  //         return false;
  //       }
  //     } else if (
  //       defaultAvilTy === availability &&
  //       cookie.load("orderOutletId") != "" &&
  //       cookie.load("orderOutletId") != undefined
  //     ) {
  //       /*$.magnificPopup.close();
  // 		this.props.history.push('/products');
  //     return false;*/
  //       if (availability !== reservationId) {
  //         this.setState({ nextavail: availability });
  //         $("#clearcartavailability").val(availability);
  //         $.magnificPopup.open({
  //           items: {
  //             src: "#warning-popup",
  //           },
  //           type: "inline",
  //         });
  //         return false;
  //       }
  //     }

  //     var popupIdtxt = "";
  //     if (availability === deliveryId) {
  //       $(".delivery_outletpoup").find(".outlet_error").html("");
  //       popupIdtxt = "#delevery-postcode-popup";
  //     } else if (availability === pickupId) {
  //       popupIdtxt = "#takeaway-popup";
  //     } else if (availability === cateringId) {
  //       cookie.save("defaultAvilablityId", cateringId, { path: "/" });
  //       $.magnificPopup.close();
  //       this.props.history.push("/");
  //       return false;
  //     } else if (availability === reservationId) {
  //       cookie.save("defaultAvilablityId", reservationId, { path: "/" });
  //       $.magnificPopup.close();
  //       this.props.history.push("/reservation");
  //       return false;
  //       /*popupIdtxt = '#comingsoon-popup';*/
  //     } else if (availability === dineinId) {
  //       cookie.save("defaultAvilablityId", dineinId, { path: "/" });
  //       $.magnificPopup.close();
  //       popupIdtxt = "#dinein-popup";
  //     }

  //     if (popupIdtxt !== "") {
  //       $.magnificPopup.open({
  //         items: {
  //           src: popupIdtxt,
  //         },
  //         type: "inline",
  //       });
  //     }
  //   }
  // }

  chooseAvailabilityFun(availability, actFrom, event) {
    event.preventDefault();
    // if (availability === reservationId || availability === dineinId) {
    //   return false;
    // }

    if (availability === "viewmenu") {
      popupIdtxt = "#viewmenu-popup";
    } else {
      var defaultAvilTy = cookie.load("defaultAvilablityId");
      if (defaultAvilTy !== availability) {
        var cartTotalItems = cookie.load("cartTotalItems");
        cartTotalItems =
          cartTotalItems != "" && cartTotalItems != undefined
            ? parseInt(cartTotalItems)
            : 0;
        if (defaultAvilTy === cateringId && cartTotalItems > 0) {
          this.setState({ nextavail: availability });

          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        } else if (
          cookie.load("orderOutletId") != "" &&
          cookie.load("orderOutletId") != undefined
        ) {
          this.setState({ nextavail: availability });
          $("#clearcartavailability").val(availability);
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (
        defaultAvilTy === availability &&
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        /*$.magnificPopup.close();
			this.props.history.push('/products');
      return false;*/
        if (availability !== reservationId) {
          this.setState({ nextavail: availability });
          $("#clearcartavailability").val(availability);
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }

      var popupIdtxt = "";
      if (availability === deliveryId) {
        $(".delivery_outletpoup").find(".outlet_error").html("");
        popupIdtxt = "#delevery-postcode-popup";
      } else if (availability === pickupId) {
        popupIdtxt = "#takeaway-popup";
      } else if (availability === cateringId) {
        cookie.save("defaultAvilablityId", cateringId, { path: "/" });
        $.magnificPopup.close();
        this.props.history.push("/catering");
        return false;
      } else if (availability === reservationId) {
        cookie.save("defaultAvilablityId", reservationId, { path: "/" });
        $.magnificPopup.close();
        this.props.history.push("/reservation");
        return false;
      } else if (availability === dineinId) {
        cookie.save("defaultAvilablityId", dineinId, { path: "/" });
        $.magnificPopup.close();
        popupIdtxt = "#dinein-popup";
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  chooseAvailabilityMbl() {
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "Order Now";
    if (availability === deliveryId) {
      showtext = "Delivery";
    } else if (availability === pickupId) {
      showtext = "Self Collection";
    } else if (availability === dineinId) {
      showtext = "Dine In";
    } else if (availability === cateringId) {
      showtext = "Catering";
      // } else if (availability === reservationId) {
    } else if (
      this.props.match.path === "/reservation" ||
      this.props.match.path === "/reservation-summary" ||
      this.props.match.path === "/reservation-thankyou"
    ) {
      showtext = "Reservation";
    }
    return showtext;
  }

  /* facebook login */
  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];
      var birthday = response.birthday;
      var qs = require("qs");
      var photo = response.picture.data.url;
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }
      /* update DOB */
      var dob = "";
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd");
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );
      const { history } = this.props;

      if (cookie.load("cateringPay") === "start") {
        showAlert("Success", "Logged in Successfully!");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });

        history.push(
          "/payment/" +
            cookie.load("orderIdTxt") +
            "/" +
            cookie.load("paymentIdTxt")
        );
        /*history.push("/payment");*/
      } else {
        var qs = require("qs");
        var postObject = {
          app_id: appId,
          reference_id: getReferenceID(),
          customer_id: fbloginData.result_set.customer_id,
          availability_id: cookie.load("defaultAvilablityId"),
        };

        axios
          .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
          .then((res) => {
            showAlert("Success", "Logged in Successfully!");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });

            if (res.data.status === "ok") {
              if (mobileno === "") {
                showAlert(
                  "Error",
                  "Please update the Mobile Number to continue booking"
                );
                $.magnificPopup.open({
                  items: {
                    src: ".alert_popup",
                  },
                  type: "inline",
                });
                history.push("/myaccount");
              } else if (cookie.load("loginpopupTrigger") === "fromcheckout") {
                cookie.remove("loginpopupTrigger", { path: "/" });
                history.push("/checkout");
              } else if (
                cookie.load("loginpopupTrigger") === "fromchecatering"
              ) {
                cookie.remove("loginpopupTrigger");
                history.push("/catering");
                // } else if (
                //   cookie.load("loginpopupTrigger") === "fromchecatering"
                // ) {
                //   cookie.remove("loginpopupTrigger", { path: "/" });
                //   history.push("/package/checkout");
              } else if (cookie.load("redirectReservatin") === "Yes") {
                cookie.remove("redirectReservatin", { path: "/" });
                history.push("/reservation");
              } else {
                history.push("/myaccount");
              }
            } else {
              if (cookie.load("redirectReservatin") === "Yes") {
                cookie.remove("redirectReservatin", { path: "/" });
                history.push("/reservation");
              } else {
                history.push("/myaccount");
              }
            }
          });
      }
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      /*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  closepopup(event) {
    event.preventDefault();
    hideLoader("dinein_popup_item", "class");

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    });

    /* For Advanced Slot Start */
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      slotType: tempVl,
      seleted_ord_slot: tempVl,
      seleted_ord_slotTxt: tempVl,
      seleted_ord_slot_str: tempVl,
      seleted_ord_slot_end: tempVl,
    });
    /* For Advanced Slot End */

    this.destroyCart("Yes");

    var warning_avilablity = $("#warning_avilablity").val();
    var nextavail = this.state.nextavail;
    if (warning_avilablity !== "") {
      var nextavail = warning_avilablity;
    }

    var popupIdtxt = "";
    if (nextavail === deliveryId) {
      popupIdtxt = "#delevery-postcode-popup";
    } else if (nextavail === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    }
    if (nextavail === cateringId) {
      $.magnificPopup.close();
      this.props.history.push("/catering");
    }

    console.log(popupIdtxt, "popupIdtxt");

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }
  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
      cookie.remove("outletchosen", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("defaultAvilablityId", { path: "/" });
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    /* For Advanced Slot */
    cookie.remove("isAdvanced", { path: "/" });
    cookie.remove("slotType", { path: "/" });
    cookie.remove("orderSlotVal", { path: "/" });
    cookie.remove("orderSlotTxt", { path: "/" });
    cookie.remove("orderSlotStrTime", { path: "/" });
    cookie.remove("orderSlotEndTime", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    var outletIdTxt = this.state.delivery_outlet_id;

    /*  if (outletIdTxt === "") {
      $(".postal_error").html(
        '<span class="error">Go Back and Select your delivery outlet.</span>'
      );
      return false;
    } */

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .all([
        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&postal_code=" +
            postalcode +
            "&postalcode_basedoutlet=yes"
        ) /* ,
        axios.get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&outletId=" +
            outletIdTxt
        ), */,
      ])
      .then(
        axios.spread((res, timeslt) => {
          var deliveryInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("outletchosen", availability, { path: "/" });

            var orderDeliveryAddress =
              res.data.result_set.postal_code_information.zip_buno +
              " " +
              res.data.result_set.postal_code_information.zip_sname;
            var orderHandled =
              stripslashes(res.data.result_set.outlet_name) +
              ", Crew will be seeing you in " +
              res.data.result_set.outlet_delivery_tat +
              " Minutes";

            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
            deliveryInfo["orderOutletName"] = stripslashes(
              res.data.result_set.outlet_name
            );
            deliveryInfo["orderPostalCode"] =
              res.data.result_set.postal_code_information.zip_code;
            deliveryInfo["orderTAT"] = res.data.result_set.outlet_delivery_tat;
            deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
            deliveryInfo["orderHandled"] = orderHandled;
            deliveryInfo["defaultAvilablityId"] = availability;

            var unitNum = this.showUnitNum(
              res.data.result_set.outlet_unit_number1,
              res.data.result_set.outlet_unit_number2
            );

            var orderHandledText =
              res.data.result_set.outlet_address_line1 +
              " " +
              res.data.result_set.outlet_address_line2 +
              ", " +
              unitNum +
              " Singapore " +
              res.data.result_set.outlet_postal_code;

            deliveryInfo["orderHandledByText"] = orderHandledText;

            this.setState({
              deliveryInfo: deliveryInfo,
              seletedAvilablityId: availability,
              seletedOutletId: res.data.result_set.outlet_id,
              order_tat_time: res.data.result_set.outlet_delivery_tat,
              orderHandled: orderHandled,
              orderDeliveryAddress: orderDeliveryAddress,
              orderDeliveryPostalCode:
                res.data.result_set.postal_code_information.zip_code,
            });
            /*  +
                " Singapore " +
                res.data.result_set.postal_code_information.zip_code */

            /* Success time slot response */
            axios
              .get(
                apiUrlV2 +
                  "settings/chkTimeslotIsAvaiable?app_id=" +
                  appId +
                  "&availability_id=" +
                  availability +
                  "&outletId=" +
                  res.data.result_set.outlet_id
              )
              .then((timeslt) => {
                hideLoader("delivery_submit_cls", "class");
                $.magnificPopup.close();
                if (timeslt.data.status === "success") {
                  /* For Advanced Slot */
                  var isAdvanced = timeslt.data.isAdvanced,
                    slotType = "0";
                  if (isAdvanced === "yes") {
                    slotType = timeslt.data.slotType;
                  }
                  this.setState({
                    getDateTimeFlg: "yes",
                    isAdvanced: isAdvanced,
                    slotType: slotType,
                  });

                  removeOrderDateTime();
                  removePromoCkValue();

                  $.magnificPopup.open({
                    items: {
                      src: "#awesome-popup",
                    },
                    type: "inline",
                  });
                } else {
                  $.magnificPopup.open({
                    items: {
                      src: "#outlet-error-popup",
                    },
                    type: "inline",
                  });
                }
              });
          }

          /* Error response */
          if (res.data.status === "error") {
            hideLoader("delivery_submit_cls", "class");
            $.magnificPopup.close();
            this.setState({ deliveryInfo: deliveryInfo });

            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: "#error-postal-popup",
              },
              type: "inline",
            });

            if (first === 0) {
              var mgsTxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Please enter valid postal code.";
              $(".postal_error").html(
                '<span class="error">' + mgsTxt + "</span>"
              );
            }
          }
        })
      );
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          cookie.save("outletchosen", availability, { path: "/" });
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_tat +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              " Singapore " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });
          cookie.save("orderOutletId", res.data.result_set.outlet_id, {
            path: "/",
          });
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            { path: "/" }
          );
          cookie.save(
            "orderPostalCode",
            res.data.result_set.postal_code_information.zip_code,
            { path: "/" }
          );
          cookie.save("orderTAT", res.data.result_set.outlet_delivery_tat, {
            path: "/",
          });
          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", availability, { path: "/" });

          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            " " +
            res.data.result_set.outlet_address_line2 +
            ", Singapore " +
            postalcode;
          cookie.save("orderHandledByText", orderHandledText, { path: "/" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      cookie.save("outletchosen", cookie.load("defaultAvilablityId"), {
        path: "/",
      });
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        this.props.history.push("/products");
      }
    }
  }

  selectDatetm() {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) {
      // showLoader("takeaway-btn-act", "class");
      showLoader("self_pop_col", "class");

      axios
        .get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            pickupId +
            "&outletId=" +
            seletedOutletId
        )
        .then((res) => {
          // hideLoader("takeaway-btn-act", "class");
          hideLoader("self_pop_col", "class");

          /* Success response */
          if (res.data.status === "success") {
            /* For Advanced Slot */
            var isAdvanced = res.data.isAdvanced,
              slotType = "0";
            if (isAdvanced === "yes") {
              slotType = res.data.slotType;
            }
            this.setState({
              getDateTimeFlg: "yes",
              isAdvanced: isAdvanced,
              slotType: slotType,
            });

            removeOrderDateTime();
            removePromoCkValue();

            $.magnificPopup.open({
              items: {
                src: "#awesome-popup",
              },
              type: "inline",
            });
          } else {
            $.magnificPopup.open({
              items: {
                src: "#outlet-error-popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    }
  }

  gobackOrderNow(e) {
    e.preventDefault();
    console.log(this.state);
    $.magnificPopup.close();
    $(".search_outlet").val("");
    // this.setState({ pickupOutlets: this.state.pickupOutletsList });
    var goback = "#order-popup";
    $.magnificPopup.open({
      items: {
        src: goback,
      },
      type: "inline",
    });
  }

  gobackOrderFrom(e) {
    e.preventDefault();
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        if (
          stripslashes(item.outlet_name).toLowerCase().indexOf(value) >= 0 ||
          stripslashes(item.outlet_address_line1)
            .toLowerCase()
            .indexOf(value) >= 0 ||
          stripslashes(item.outlet_postal_code).indexOf(value) >= 0
        ) {
          return item;
        }
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => {
        return (
          <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
            <span
              onClick={this.setPickupOutletFun.bind(
                this,
                loaddata,
                loaddata.outlet_id
              )}
            >
              <div className="outlet-list-lhs">
                <img
                  src={
                    loaddata.outlet_image !== ""
                      ? outletUrl + loaddata.outlet_image
                      : outletNoImage
                  }
                />
              </div>
              <div className="outlet-list-rhs">
                <h4 className="outlet-list-name">
                  {stripslashes(loaddata.outlet_name)}
                </h4>
                <p className="outlet-list-address">
                  {loaddata.outlet_address_line1},{" "}
                  {this.showUnitNum(
                    loaddata.outlet_unit_number1,
                    loaddata.outlet_unit_number2
                  )}
                  Singapore {loaddata.outlet_postal_code}
                </p>
                <ul>
                  <li className="otulet-waiting">
                    <span>Waiting : {loaddata.outlet_delivery_tat} min</span>
                  </li>
                  <li className="otulet-direction">
                    <span>
                      <a
                        href={encodeURI(loaddata.outlet_map_link).replace(
                          "&",
                          "%26"
                        )}
                        target="_blank"
                        onClick={this.openMapLink.bind(
                          this,
                          encodeURI(loaddata.outlet_map_link).replace(
                            "&",
                            "%26"
                          )
                        )}
                      >
                        Direction
                      </a>
                    </span>
                  </li>
                  {/* {loaddata.da_monday_start_time !== "" &&
                      loaddata.da_monday_start_time !== null && (
                        <li className="otulet-timing">
                          <span>
                            Operations:{" "}
                            {meridiem(loaddata.da_monday_start_time)} -{" "}
                            {meridiem(loaddata.da_monday_end_time)}
                          </span>
                        </li>
                      )} */}
                </ul>
              </div>
            </span>
          </li>
        );
      });
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  /* load outlets  */
  loadMenuOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      var existOutlet = [];
      return this.state.deliveryOutlets.map((loaddata, index) => {
        if (
          loaddata.outlet_id !== "321" &&
          existOutlet.indexOf(loaddata.outlet_id) < 0 &&
          loaddata.outlet_menu_pdf
        ) {
          existOutlet.push(loaddata.outlet_id);
          return (
            <li key={index}>
              <span
                onClick={this.downalodPDF.bind(this, loaddata.outlet_menu_pdf)}
              >
                <div className="outlet-list-lhs">
                  <img
                    src={
                      loaddata.outlet_image !== ""
                        ? outletUrl + loaddata.outlet_image
                        : outletNoImage
                    }
                  />
                </div>
                <div className="outlet-list-rhs">
                  <h4 className="outlet-list-name">
                    {stripslashes(loaddata.outlet_name)}
                  </h4>
                  <p className="outlet-list-address">
                    {loaddata.outlet_address_line1},{" "}
                    {this.showUnitNum(
                      loaddata.outlet_unit_number1,
                      loaddata.outlet_unit_number2
                    )}
                    Singapore {loaddata.outlet_postal_code}
                  </p>
                  <ul>
                    <li className="otulet-waiting">
                      <span>Waiting : {loaddata.outlet_delivery_tat} min</span>
                    </li>
                    <li className="otulet-direction">
                      <span>
                        <a
                          href={encodeURI(loaddata.outlet_map_link).replace(
                            "&",
                            "%26"
                          )}
                          target="_blank"
                          onClick={this.openMapLink.bind(
                            this,
                            encodeURI(loaddata.outlet_map_link).replace(
                              "&",
                              "%26"
                            )
                          )}
                        >
                          Direction
                        </a>
                      </span>
                    </li>
                    {/* {loaddata.da_monday_start_time !== "" &&
                      loaddata.da_monday_start_time !== null && (
                        <li className="otulet-timing">
                          <span>
                            Operations:{" "}
                            {meridiem(loaddata.da_monday_start_time)} -{" "}
                            {meridiem(loaddata.da_monday_end_time)}
                          </span>
                        </li>
                      )} */}
                  </ul>
                </div>
              </span>
            </li>
          );
        }
      });
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  downalodPDF(link, e) {
    e.stopPropagation();
    e.preventDefault();
    window.open(mediaUrl + "outlet/" + link, "_blank");
  }

  setPickupOutletFun(outletDetails, outletID) {
    this.setState({
      seletedOutletId: outletID,
      popupOutletDetails: outletDetails,
      error_pickup_outlet: "",
    });

    this.pickOutlet(outletDetails, "");
  }

  openMapLink(link, e) {
    e.stopPropagation();
    e.preventDefault();
    window.open(link, "_blank");
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;

    var pickupInfo = this.state.pickupInfo;
    /* &&
      Object.keys(pickupInfo).length > 0 */
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) ? "active" : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo + "," : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    // event.preventDefault();
    if (loaddata === "") {
      this.setState({ error_pickup_outlet: 1 });
      return false;
    } else {
      this.setState({ error_pickup_outlet: "" });
    }

    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );

    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      " " +
      loaddata.outlet_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  componentDidMount() {
    var currentThisVal = this;
    $(document)
      .off("click", ".clear_all_values")
      .on("click", ".clear_all_values", function (e) {
        /* "Yes" */
        currentThisVal.changeAvailability();
      });

    $(document)
      .off("click", ".sub_menu_list")
      .on("click", ".sub_menu_list", function (e) {
        $("#close_mobile_menu").trigger("click");
      });

    if (
      cookie.load("openLogin") !== undefined &&
      typeof cookie.load("openLogin") !== undefined &&
      typeof cookie.load("openLogin") !== "undefined"
    ) {
      cookie.remove("openLogin", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (
      cookie.load("openWarningPopup") !== undefined &&
      typeof cookie.load("openWarningPopup") !== undefined &&
      typeof cookie.load("openWarningPopup") !== "undefined" &&
      this.props.match.path === "/"
    ) {
      cookie.remove("openWarningPopup", { path: "/" });

      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
    }

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });

    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );

    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    this.props.getMenuData("header-menu-hvq9ogqi83f2vwtp07jlpdz1g");
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec").toggleClass("open");
        $(".hsearch_sec .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      });
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }

    /*NEW MENU SECTION*/
    if ($(".mobile_account_menu_click").length > 0) {
      $(".mobile_account_menu_click").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".mobile_account_menu").slideToggle();
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".arrow_myaccount, .mobile_account_menu_col")) {
          if ($(".mobile_account_menu").is(":visible")) {
            $(".mobile_account_menu").slideToggle();
          }
        }
      });
    }

    /* Mobile header menu */
    // $(".hcategory_trigger").click(function () {
    //   $(this).toggleClass("active");
    //   $(".hcategory_menu").toggleClass("open");
    // });

    // $(document).click(function (e) {
    //   if (!$(e.target).is(".hcategory_trigger")) {
    //     if ($(".hcategory_menu").hasClass("open")) {
    //       $(".hcategory_menu").removeClass("open");
    //       $(".hcategory_trigger").removeClass("active");
    //     }
    //   }
    // });

    /* Mobile header menu */
    $(".hcategory_selected_text").click(function () {
      $(".hcategory_selected_text").toggleClass("active");
      $(".hcategory_menu").toggleClass("open");
    });

    $(document).click(function (e) {
      if (
        !$(e.target).is(".hcategory_selected_text") &&
        !$(e.target).is(".hcategory_trigger")
      ) {
        if ($(".hcategory_menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".hcategory_selected_text").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });

    /*this.getOptions.bind(this,"mill");*/
    this.getSearchProductList();
  }

  componentDidUpdate() {}

  getSearchProductList() {
    var orderOutletIdtext = cookie.load("orderOutletId");
    var addquery_txt = "";
    if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
      addquery_txt = "&outletId=" + orderOutletIdtext;
    }
    var searchResult = [];
    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A&availability=" +
          cookie.load("defaultAvilablityId") +
          addquery_txt
      )
      .then((response) => {
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;

                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                    });
                  }
                }
              }
            }
          }
        }
        if (
          this.props.match.path === "/products/:slugType/:slugValue" ||
          this.props.match.path === "/products"
        ) {
          this.props.sateValChange("productlist", searchResult);
        }

        this.setState({
          searchProResult: searchResult,
          selectedProResult: searchResult,
        });
      });
  }

  searchProKeyPress = (event) => {
    $(".search_result").show();

    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item;
        }
      });

    cookie.save("searchKeyWord", value, { path: "/" });

    $("#clearSearch").show();
    if (value === "") {
      $("#clearSearch").hide();
    }

    this.setState({ selectedProResult: matches });
  };

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/menu/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      return <li className="no-pro-found">No Product found</li>;
    }
  }

  clearSearchFun() {
    var emtytxt = "";
    $(".productsearch").val(emtytxt);
    this.setState({ selectedProResult: this.state.searchProResult });
  }

  getOptions = (input) => {
    var searchProResult = this.state.searchProResult;
    var searchResult = [];
    /*if (Object.keys(searchProResult).length > 0) {*/
    /*searchProResult.map((loadData) =>
			  searchResult.push({ value: loadData.value, label: loadData.label })
			 );*/
    searchResult.push({ value: "wqewrr", label: "fish cury" });
    searchResult.push({ value: "werew3", label: "fish cury2" });
    return { options: searchResult };
    /*}*/
  };

  ViewProducts(event) {
    var productSlug = event.value;
    this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkAblBtn() {
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var actText = "Order Now";
    if (
      availability === deliveryId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Delivery";
    } else if (
      availability === pickupId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Self Collection";
    } else if (availability === dineinId) {
      actText = "Dine In";
    } else if (availability === cateringId) {
      actText = "Catering";
      // } else if (availability === reservationId) {
    }

    if (
      this.props.match.path === "/reservation" ||
      this.props.match.path === "/reservation-summary" ||
      this.props.match.path === "/reservation-thankyou"
    ) {
      actText = "Reservation";
    }

    return (
      <a
        href="/"
        onClick={this.closepopup.bind(this)}
        className="hordertype_btn"
        title={actText}
      >
        {actText}
      </a>
    );
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (this.state.setAvilablityId !== "") {
      if (this.state.setAvilablityId === avlType) {
        clsTxt += "active";
      }
    } else {
      if (
        (availability == avlType || this.state.setAvilablityId === avlType) &&
        orderOutletId !== "" &&
        orderOutletId !== undefined
      ) {
        clsTxt += "active";
      } else if (
        availability == avlType &&
        (avlType === cateringId || avlType === reservationId)
      ) {
        clsTxt += "active";
      }
    }

    return clsTxt;
  }

  checkActiveDivMbl(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "myaccount";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <a
            // href={baseUrl + "myaccount"}
            href={baseUrl + "myaccount"}
            className="myacunt_act"
            title="My Account"
          >
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl);
    } else {
      $("#postalcode1").val(pstVl);
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        /* For Advanced Slot */
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        /* For Advanced Slot */
        var isAdvanced = this.state.isAdvanced;
        var slotType = this.state.slotType;
        var orderSlotVal = "",
          orderSlotTxt = "",
          orderSlotStrTime = "",
          orderSlotEndTime = "";
        if (slotType === "2") {
          orderSlotVal = this.state.seleted_ord_slot;
          orderSlotTxt = this.state.seleted_ord_slotTxt;
          orderSlotStrTime = this.state.seleted_ord_slot_str;
          orderSlotEndTime = this.state.seleted_ord_slot_end;
        }
        cookie.save("isAdvanced", isAdvanced, { path: "/" });
        cookie.save("slotType", slotType, { path: "/" });
        cookie.save("orderSlotVal", orderSlotVal, { path: "/" });
        cookie.save("orderSlotTxt", orderSlotTxt, { path: "/" });
        cookie.save("orderSlotStrTime", orderSlotStrTime, { path: "/" });
        cookie.save("orderSlotEndTime", orderSlotEndTime, { path: "/" });
        /* For Advanced Slot End */

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });
        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });

        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });
        cookie.save("outletchosen", orderInfoData["defaultAvilablityId"], {
          path: "/",
        });

        $.magnificPopup.close();
        if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", { path: "/" });
          this.props.history.push("/");
        } else {
          if (
            this.props.match.path === "/menu/:slugType/:slugValue/:proValue"
          ) {
            location.reload();
          } else {
            location.href = baseUrl + "menu";
          }
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  goBackSlot(e) {
    e.preventDefault();
    var backSection = "";
    if (this.state.seletedAvilablityId === deliveryId) {
      backSection = "#delevery-postcode-popup";
    } else {
      backSection = "#takeaway-popup";
    }
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: backSection,
      },
      type: "inline",
    });
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  searBlkFun(typeTxt) {
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var liTopCls = "htico_search";
    var scearDivTopCls = "hsearch_sec";
    var triggerActCls = "hsearch_trigger";
    var searchResultCls = "search_result";
    var searchIconcls = "search_i_icon";
    if (typeTxt === "mbl") {
      liTopCls = "htico_search_mbl";
      scearDivTopCls = "hsearch_sec_mbl";
      triggerActCls = "hsearch_trigger_mbl";
      searchResultCls = "search_result_mbl";
      searchIconcls = "fa fa-search";
    }

    return (
      <>
        {/* <li className={liTopCls}> */}
        {(defaultAvilTyId === cateringId ||
          defaultAvilTyId === reservationId) && (
          <a
            href="/"
            data-effect="mfp-zoom-in"
            className="hsearch_trigger_dis disbl_href_action"
            title="Search"
          >
            &nbsp;
          </a>
        )}

        {cookie.load("orderOutletId") !== undefined &&
          defaultAvilTyId !== cateringId &&
          defaultAvilTyId !== reservationId && (
            <a href="/" className={triggerActCls} title="Search">
              <img src={loupe} alt="" />
            </a>
          )}

        {cookie.load("orderOutletId") === undefined &&
          defaultAvilTyId !== cateringId &&
          defaultAvilTyId !== reservationId && (
            <a
              href="#order-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link"
              title="Search"
            >
              <img src={loupe} alt="" />
            </a>
          )}

        <div className={scearDivTopCls}>
          <div className="input-sec">
            <input
              type="text"
              name="searchkey"
              className="productsearch"
              id={"productsearch_" + typeTxt}
              placeholder="Search..."
              onKeyUp={this.searchProKeyPress}
            />

            <a
              href="/"
              id="clearSearch"
              onClick={this.clearSearchFun.bind(this)}
              className="search_text_clear disbl_href_action"
            >
              X
            </a>
          </div>

          <ul className={searchResultCls} id={searchResultCls}>
            {this.loadProSearchList()}
          </ul>
        </div>
      </>
    );
  }

  startQRScan() {
    this.setState({ dine_in_click: "Yes" });
    $.magnificPopup.close();
    this.props.history.push("/scanqrcode");
  }

  handleScan = (data) => {
    if (data) {
      // cookie.save('orderTableNo', data,{ path: '/' });
      showLoader("dinein_popup_item", "class");
      this.setState({ dine_in_click: "No", table_number: data });
      // let table_no = data;
      this.findTable();
    }
  };
  handleError = (err) => {
    this.setState({ dine_in_click: "No" });
    $.magnificPopup.close();
  };

  findTable() {
    showLoader("dinein_popup_item", "class");

    var table_number = this.state.table_number;

    if (table_number !== "") {
      cookie.save("orderTableNo", table_number, { path: "/" });

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        tm_table_number: table_number,
      };
      this.props.getTableAvailability(qs.stringify(postObject));
    } else {
      hideLoader("dinein_popup_item", "class");
      showAlert("Error", "Scan QR or Enter Table Number to Continue!");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  HandleTableNumberchng = (event) => {
    // this.findTable(event.target.value);
    this.setState({ dine_in_click: "No", table_number: event.target.value });
  };

  dineinOutlet(outletDetails) {
    $.magnificPopup.close();

    var outlet_name = outletDetails.outlet_name;
    var outlet_id = outletDetails.outlet_id;
    var outlet_unit_number1 = outletDetails.outlet_unit_number1;
    var outlet_unit_number2 = outletDetails.outlet_unit_number2;
    var outlet_address_line1 = outletDetails.outlet_address_line1;
    var outlet_address_line2 = outletDetails.outlet_address_line2;
    var outlet_postal_code = outletDetails.outlet_postal_code;
    var outlet_dine_tat = outletDetails.outlet_dine_tat;

    if (outlet_unit_number1 != "" && outlet_unit_number2 != "") {
      var unitTxt = ", #" + outlet_unit_number1 + "-" + outlet_unit_number2;
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        unitTxt +
        ", Singapore " +
        outlet_postal_code;
    } else if (outlet_unit_number1 != "" || outlet_unit_number2 != "") {
      var unitTxt =
        outlet_unit_number1 != ""
          ? ", #" + outlet_unit_number1
          : ", #" + outlet_unit_number2;
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        unitTxt +
        ", Singapore " +
        outlet_postal_code;
    } else {
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        ", Singapore " +
        outlet_postal_code;
    }

    this.setState({ orderHandled: orderHandled });
    cookie.save("defaultAvilablityId", dineinId, { path: "/" });
    cookie.save("orderOutletId", outlet_id, { path: "/" });
    cookie.save("orderOutletName", outlet_name, { path: "/" });
    cookie.save("orderPostalCode", outlet_postal_code, { path: "/" });
    cookie.save("orderTAT", outlet_dine_tat, { path: "/" });
    cookie.save("orderHandled", orderHandled, { path: "/" });
    cookie.save("orderHandledByText", orderHandled, { path: "/" });
    if (this.props.match.path === "/menu") {
      window.location.reload();
    } else {
      this.props.history.push("/menu");
    }
  }

  /* ViewProducts */

  render() {
    var currenturl = window.location.href;
    var substring = "products";
    var isCheckout = "checkout";

    var showCatryName =
      typeof this.props.showCatryName !== "undefined"
        ? this.props.showCatryName
        : "Category Name";

    /* For Advanced Slot Start */
    var settingsArr = this.props.globalsettings;
    var advancedTimeslotEnable = "0";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        advancedTimeslotEnable =
          settingsArr[0].result_set.client_advanced_timeslot_enable;
      }
    }

    return (
      <header>
        <div className="header-top-cls">
          <div className="container-full">
            <div className="logo-main-section">
              <div className="menu_icon trigger_menunav_act">
                <span className="icon-bar icon-bar1"></span>
                <span className="icon-bar icon-bar2"></span>
                <span className="icon-bar icon-bar3"></span>
              </div>
              <div className="mobile-login">
                <a href="#" className="controller-nav">
                  <img src={avicon} alt="avatar" />
                </a>
                <ul className="mobile-login-list">
                  <li>
                    <a
                      href="#login-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link htico_sign"
                      title="Login"
                      onClick={(e) => {
                        e.preventDefault();
                        $.magnificPopup.open({
                          items: {
                            src: "#login-popup",
                          },
                          type: "inline",
                        });
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      href="#signup-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link"
                      title="Sign up"
                    >
                      Register
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mobile-menu">
                <div className="mobile-menu-header">
                  <div className="mobile-menu-close">
                    <span id="close_mobile_menu"></span>
                  </div>
                </div>
                {this.state.menudata && (
                  <div className="mobile-menu-body">
                    <ul className="menu">
                      {this.state.menudata && this.listMainNavigation()}
                      {cookie.load("UserId") ? (
                        <>
                          <li
                            style={{
                              display:
                                this.state.menudata !== "" &&
                                typeof this.state.menudata !== undefined &&
                                typeof this.state.menudata !== "undefined" &&
                                this.state.menudata.length > 0
                                  ? ""
                                  : "none",
                            }}
                          >
                            <a
                              className={
                                this.props.match.path === "/myaccount" ||
                                this.props.match.path === "/myorders" ||
                                this.props.match.path === "/rewards" ||
                                this.props.match.path === "/mypromotions"
                                  ? "htico_sign active"
                                  : "htico_sign"
                              }
                              href={baseUrl + "myaccount"}
                              title="My Account"
                            >
                              <i /> <span>My Account</span>
                            </a>
                          </li>
                          <li
                            style={{
                              display:
                                this.state.menudata !== "" &&
                                typeof this.state.menudata !== undefined &&
                                typeof this.state.menudata !== "undefined" &&
                                this.state.menudata.length > 0
                                  ? ""
                                  : "none",
                            }}
                          >
                            <Link
                              className="htico_sign"
                              to="/logout"
                              title="Logout"
                            >
                              <i /> <span>Logout</span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <li
                          style={{
                            display:
                              this.state.menudata !== "" &&
                              typeof this.state.menudata !== undefined &&
                              typeof this.state.menudata !== "undefined" &&
                              this.state.menudata.length > 0
                                ? ""
                                : "none",
                          }}
                        >
                          <a
                            href="#login-popup"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link htico_sign"
                            title="Login"
                            onClick={(e) => {
                              e.preventDefault();
                              $("#close_mobile_menu").trigger("click");
                              $.magnificPopup.open({
                                items: {
                                  src: "#login-popup",
                                },
                                type: "inline",
                              });
                            }}
                          >
                            <span>Login</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>

              <div className="logo">
                <Link to={"/"} title="Tigga Folks">
                  <img src={mainLogo} alt="Logo" />
                </Link>
              </div>
              {this.state.menudata && (
                <div className="hmenu_sec">
                  <ul className="hmenu_list desktop_hmenu_list">
                    {this.listMainNavigation()}
                    {cookie.load("UserId") ? (
                      <>
                        <li
                          className="hsign_sec"
                          style={{
                            display:
                              this.state.menudata !== "" &&
                              typeof this.state.menudata !== undefined &&
                              typeof this.state.menudata !== "undefined" &&
                              this.state.menudata.length > 0
                                ? ""
                                : "none",
                          }}
                        >
                          <a
                            className={
                              this.props.match.path === "/myaccount" ||
                              this.props.match.path === "/myorders" ||
                              this.props.match.path === "/rewards" ||
                              this.props.match.path === "/mypromotions"
                                ? "htico_sign active"
                                : "htico_sign"
                            }
                            href={baseUrl + "myaccount"}
                            title="My Account"
                          >
                            <i /> <span>My Account</span>
                          </a>
                          <a
                            href="/"
                            className="submenu-arow disbl_href_action"
                          ></a>
                          <ul className="submenu_list">
                            <li>
                              {" "}
                              <Link
                                className="htico_sign"
                                to="/logout"
                                title="Logout"
                              >
                                <span>Logout</span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    ) : (
                      <li
                        className="hsign_sec"
                        style={{
                          display:
                            this.state.menudata !== "" &&
                            typeof this.state.menudata !== undefined &&
                            typeof this.state.menudata !== "undefined" &&
                            this.state.menudata.length > 0
                              ? ""
                              : "none",
                        }}
                      >
                        <a
                          href="#login-popup"
                          data-effect="mfp-zoom-in"
                          className="open-popup-link htico_sign"
                          title="Login"
                          onClick={(e) => {
                            e.preventDefault();
                            $.magnificPopup.open({
                              items: {
                                src: "#login-popup",
                              },
                              type: "inline",
                            });
                          }}
                        >
                          <span>Login</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}

              <div className="hmenu-login-section">
                <ul className="hmenu-login-act">
                  <li className="hordertype_sec">{this.checkAblBtn()}</li>
                  <li className="htico_search">{this.searBlkFun("desktop")}</li>
                  <li
                    className={
                      this.props.match.path === "/favourite"
                        ? "hcart_like active"
                        : "hcart_like"
                    }
                  >
                    <Link
                      to={"/favourite"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!cookie.load("UserId")) {
                          window.$.magnificPopup.open({
                            items: {
                              src: "#login-popup",
                            },
                            type: "inline",
                          });
                        } else {
                          this.props.history.push("/favourite");
                        }
                      }}
                    >
                      <img src={cartLike} />
                      <img
                        src={cartLikeActive}
                        alt="Favourite"
                        title="Favourite"
                      />
                    </Link>
                  </li>
                  {this.props.match.path !== "/catering" && (
                    <li className="htico_cart">
                      <CartSideBar
                        {...this.props}
                        headerState={this.state}
                        prpSateValChange={this.props.sateValChange}
                        pageName="header"
                      />
                    </li>
                  )}
                  {this.props.match.path === "/catering" && (
                    <li className="htico_cart catering_htico_cart">
                      <Link to="/catering" className="card_icon">
                        <img src={shoppingBag} alt="cart" />
                        {/* <span className="cart_count">{(this.props.cartcount)?this.props.cartcount:total_count}</span> */}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-order-now">
          <div className="mobile-order-lhs">
            {cookie.load("UserId") ? (
              <>
                <a
                  className={
                    this.props.match.path === "/myaccount" ||
                    this.props.match.path === "/myorders" ||
                    this.props.match.path === "/rewards" ||
                    this.props.match.path === "/mypromotions"
                      ? "htico_sign active"
                      : "htico_sign"
                  }
                  href={baseUrl + "myaccount"}
                  title="My Account"
                >
                  <i /> <span>My Account</span>
                </a>{" "}
                <Link to="/logout" title="Logout">
                  <span>Logout</span>
                </Link>
              </>
            ) : (
              <>
                <a
                  href="#login-popup"
                  data-effect="mfp-zoom-in"
                  title="Login"
                  onClick={(e) => {
                    e.preventDefault();
                    $.magnificPopup.open({
                      items: {
                        src: "#login-popup",
                      },
                      type: "inline",
                    });
                  }}
                >
                  <span>Login</span>
                </a>
                <a
                  href="#signup-popup"
                  data-effect="mfp-zoom-in"
                  title="Login"
                  onClick={(e) => {
                    e.preventDefault();
                    $.magnificPopup.open({
                      items: {
                        src: "#signup-popup",
                      },
                      type: "inline",
                    });
                  }}
                >
                  <span>Signup</span>
                </a>
              </>
            )}
          </div>
          <div className="mobile-order-rhs">{this.checkAblBtn()}</div>
        </div>

        {/* mobile_accountdel_body - start */}

        {/* mobile_accountdel_body - end */}

        {/* currenturl.includes(substring) && (
          <div className="hcategory_sec">
            <a href="/" className="hcategory_selected_text disbl_href_action">
              {showCatryName} <span className="hcategory_trigger"></span>
            </a>
            <div className="hcategory_menu ">
              <MenuNavigationmob />
            </div>
          </div>
        ) */}

        <div
          className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-success-msg">
            Nice! Products added to your cart
          </p>{" "}
        </div>

        <div
          className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-error-msg">Something went wrong</p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-common-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-success-msg">
            Nice! Products added to your cart
          </p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide catering_error"
          id="jquery-common-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-error-msg">Something went wrong</p>{" "}
        </div>

        {/* login popup */}

        <div id="login-popup" className="mfp-hide popup_sec login-popup">
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3> Login </h3>
              <p>Sign in or Join Now</p>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer">
                <FacebookLogin
                  appId={fbAppId}
                  fields="name,email,picture,first_name,last_name,birthday,gender"
                  callback={this.responseFacebook}
                  scope="public_profile,email,user_birthday"
                  cssclassName="btn btn-black  fa fa-facebook"
                  redirectUri={baseUrl}
                  isMobile={false}
                  icon="fa-facebook"
                  textButton="&nbsp; Login with Facebook"
                />
                <div className="or-seperator">
                  <span>Or Sign in with</span>
                </div>
              </div>
              <Login
                fields={this.state.fields}
                onChange={this.fieldChange}
                onValid={this.handleSignin}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        {/* Signup popup */}
        <div id="signup-popup" className="mfp-hide popup_sec signup-popup">
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3> Create an Account </h3>
              <p>Update your informations and continue</p>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer signup-popup-head">
                <FacebookLogin
                  appId={fbAppId}
                  fields="name,email,picture,first_name,last_name,birthday,gender"
                  callback={this.responseFacebook}
                  scope="public_profile,email,user_birthday"
                  cssclassName="btn btn-black  fa fa-facebook"
                  redirectUri={baseUrl}
                  icon="fa-facebook"
                  textButton="&nbsp; Login with Facebook"
                />

                <div className="or-seperator">
                  <span>Or Register With</span>
                </div>
              </div>
              <Signup
                fields={this.state.fieldssignup}
                onChange={this.fieldChangeSignup}
                onValid={this.handleSignup}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        {/* Forgot Password Popup */}

        <div
          id="forgot-password-popup"
          className="white-popup mfp-hide popup_sec login-popup forgot-password-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>Forgot your Password?</h3>
              <p>You can reset your Password here.</p>
            </div>
            <div className="full-login-new-body">
              <Forgotpassword
                fields={this.state.fieldsfgtpassword}
                onChange={this.fieldforgot}
                onValid={this.forgotpassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        <div
          id="guest-checkout-popup"
          className="white-popup mfp-hide popup_sec login-popup guest-checkout-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>Continue as Guest</h3>
              {/* <p>You can reset your PIN here.</p> */}
            </div>
            <div className="full-login-new-body">
              <Guestcheckout
                {...this.props}
                fields={this.state.fieldscheckout}
                onChange={this.fieldChangecheckout}
                onValid={this.handleCheckout}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        {/* Order popup - start */}
        <div
          id="order-popup"
          className="white-popup mfp-hide popup_sec order_popup"
        >
          <div className="full-login-new-header">
            <h3> Menu</h3>
            <p>Please Choose</p>
          </div>
          <div className="full-login-new-body">
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  {this.state.delivery_availability_enable === true && (
                    <li>
                      <a
                        href="/"
                        onClick={this.setAvailabilityFun.bind(
                          this,
                          deliveryId,
                          "1"
                        )}
                        className={this.checkActiveDivHd(deliveryId)}
                      >
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={fbscooter} />
                          <img className="order_type_imgwt" src={fbscooterw} />
                        </div>
                        <h3>Delivery</h3>
                      </a>
                    </li>
                  )}

                  {this.state.takeaway_availability_enable === true && (
                    <li>
                      <a
                        href="/"
                        onClick={this.setAvailabilityFun.bind(
                          this,
                          pickupId,
                          "1"
                        )}
                        className={this.checkActiveDivHd(pickupId)}
                      >
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={takeawayImg} />
                          <img
                            className="order_type_imgwt"
                            src={takeawaywImg}
                          />
                        </div>
                        <h3>Self Collection</h3>
                      </a>
                    </li>
                  )}

                  {this.state.catering_availability_enable === true ? (
                    <li>
                      <a
                        href="/"
                        onClick={this.setAvailabilityFun.bind(
                          this,
                          cateringId,
                          "1"
                        )}
                        className={this.checkActiveDivHd(cateringId)}
                      >
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={cateringImg} />
                          <img
                            className="order_type_imgwt"
                            src={cateringwImg}
                          />
                        </div>
                        <h3>Catering</h3>
                        {/* <span>Coming Soon</span> */}
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a className={this.checkActiveDivHd(cateringId)}>
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={cateringImg} />
                          <img
                            className="order_type_imgwt"
                            src={cateringwImg}
                          />
                        </div>
                        <h3>Catering</h3>
                        <span>Coming Soon</span>
                      </a>
                    </li>
                  )}
                </ul>
                {this.state.error_order_type === 1 && (
                  <div className="postal_error">
                    <span className="error">
                      Please select any one avilablity
                    </span>
                  </div>
                )}
                <div className="two-button-row common-button-row">
                  {/* <div className="go_second">
                    <a
                      href={void 0}
                       onClick={this.gobackOrderFrom.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div> */}
                  {/* <div className="con_first">
                    <a
                      className="button disbl_href_action"
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(
                        this,
                        this.state.setAvilablityId
                      )}
                    >
                      Continue
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Order popup - end */}

        {/* delivery-takeaway popup - start */}
        <div
          id="delivery-takeaway-popup"
          className="white-popup mfp-hide popup_sec order_popup delivery_takeaway_popup"
        >
          <div className="full-login-new-header">
            <h3>Menu</h3>
            <p>Please Choose</p>
          </div>
          <div className="full-login-new-body">
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a
                      href="/"
                      onClick={this.setAvailabilityFun.bind(
                        this,
                        deliveryId,
                        "2"
                      )}
                      className={this.checkActiveDivHd(deliveryId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={fbscooter} />
                        <img className="order_type_imgwt" src={fbscooterw} />
                      </div>
                      <h3>Delivery</h3>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={this.setAvailabilityFun.bind(
                        this,
                        pickupId,
                        "2"
                      )}
                      className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={takeawayImg} />
                        <img className="order_type_imgwt" src={takeawaywImg} />
                      </div>
                      <h3>Self Collection</h3>
                    </a>
                  </li>
                </ul>
                {this.state.error_order_type === 1 && (
                  <div className="postal_error">
                    <span className="error">
                      Please select any one avilablity
                    </span>
                  </div>
                )}
                <div className="two-button-row common-button-row">
                  <div className="go_second">
                    <a
                      href="/"
                      onClick={this.gobackOrderFrom.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                  {/* <div className="con_first">
                    <a
                      className="button disbl_href_action"
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(
                        this,
                        this.state.setAvilablityId
                      )}
                    >
                      Continue
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* delivery-takeaway popup - end */}

        {/* Delivery Popup - Start */}
        <div
          id="delevery-popup"
          className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
        >
          <div className="full-order-now-header">
            <h2>Please Select</h2>
            <p>Your Delivery Outlet</p>
          </div>
          <div className="full-order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img className="outlet-scooter-img" src={fbscooterw} />
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPressDly}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {this.loadDeliveryOutletsList()}
                    </ul>
                    <a
                      className="button disbl_href_action"
                      id="delivery-continue-link"
                      href="/"
                      onClick={this.selectDlyOutlet.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delivery Popup - end */}

        {/*  Delivery Postal code Popup - start */}
        <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="full-login-new-header">
            <h3> Delivery </h3>
            <p>Let us know Your Delivery Location</p>
          </div>

          <div className="full-login-new-body">
            <img className="pop-scooter-img" src={fbscootersvg} />

            <div className="innervmid_in">
              {cookie.load("UserId") && (
                <div className="address-list-cls address-list-main">
                  {this.userAddressList(1)}
                </div>
              )}

              <div className="form-group">
                <div className="datetime_selt_lbl">Enter your postal code</div>
                <div
                  className={
                    this.state.secondaryaddresslist.length > 0
                      ? "focus-out focused"
                      : "focus-out"
                  }
                >
                  <label>Postal Code</label>
                  <input
                    type="text"
                    id="postalcode"
                    pattern="\d*"
                    maxLength="6"
                    className="form-control input-focus"
                  />
                  <div className="postal_error"></div>
                </div>
              </div>
              <div className="btn_sec">
                <div className="two-button-row">
                  <div className="go_second">
                    {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
                    <a
                      href="/"
                      onClick={this.gobackOrderNow.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                  <div className="con_first delivery_submit_cls">
                    {/*<input type="button" onClick={this.selectOutlet.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />*/}
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        1,
                        deliveryId
                      )}
                      className="button button-right delivery_submit"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup - end */}

        {/* Delevery Popup error - start */}

        <div
          id="error-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="full-login-new-header">
            <h3 className="text-uppercase">Sorry </h3>
            <p>We can`t find your postal code</p>
          </div>

          <div className="full-login-new-body">
            <img className="pop-scooter-img" src={iconUnhappy} />

            <div className="innervmid_in">
              <form className="form_sec">
                <div className="form-group">
                  <div className="datetime_selt_lbl">
                    Enter your postal code
                  </div>
                  <div
                    className={
                      this.state.secondaryaddresslist.length > 0
                        ? "focus-out focused"
                        : "focus-out"
                    }
                  >
                    <label>Postal code</label>
                    <input
                      type="text"
                      id="postalcode1"
                      pattern="\d*"
                      maxLength="6"
                      className="form-control input-focus"
                    />
                    <div className="postal_error"></div>
                  </div>
                </div>
                <div className="btn_sec delivery_submit_cls delivery_submit_div">
                  {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                  <input
                    type="button"
                    onClick={this.findOutletBasedZone.bind(this, 0, deliveryId)}
                    className="button delivery_submit"
                    value="Continue"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Delevery Popup error - Start */}

        {/* success popup - Start */}
        <div
          id="awesome-popup"
          className="white-popup mfp-hide delevery_popup_datetime"
        >
          <div className="full-login-new-header">
            <h3>
              {" "}
              {this.state.seletedAvilablityId === deliveryId
                ? "Delivery"
                : "Self Collection"}{" "}
            </h3>
            <p>
              {this.state.seletedAvilablityId === deliveryId
                ? "Let us Know your Delivery Location"
                : "Let us Know your Self Collection Outlet"}
            </p>
          </div>
          <div className="full-login-new-body">
            <img className="pop-scooter-img" src={iconWin} />
            <h2 className="text-uppercase">Awesome</h2>

            {this.state.seletedAvilablityId === deliveryId ? (
              <div className="awesome_del">
                <h5>We can Deliver To Your Location !</h5>

                <input
                  type="text"
                  readOnly
                  value={this.state.orderDeliveryPostalCode}
                  className="form-control input-focus"
                />
                <h2>{this.state.orderDeliveryAddress}</h2>
              </div>
            ) : (
              <div className="awesome_del">
                <h5>You Can Self Collection From</h5>
                <h2>{this.state.orderHandled}</h2>
              </div>
            )}

            <div className="datetime_selt_sec">
              <div className="datetime_selt_lbl">
                {this.state.seletedAvilablityId === deliveryId
                  ? "Choose Date & Time"
                  : "Choose Self Collection Date & Time"}
              </div>

              {!currenturl.includes(isCheckout) && (
                <div>
                  {advancedTimeslotEnable === "1" ? (
                    <OrderAdvancedDatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                      indutualText={false}
                    />
                  ) : (
                    <OrderdatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                    />
                  )}
                </div>
              )}

              <div className="ordrdatetime_error"></div>
            </div>

            <div className="btn_sec">
              <a
                href="/"
                className="button"
                title="Go Back"
                onClick={this.goBackSlot.bind(this)}
              >
                Go Back
              </a>
              <input
                type="button"
                onClick={this.setOrderOutletDateTimeData.bind(this)}
                className="button disbl_href_action"
                value="Continue"
              />
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* success popup - Start */}
        <div
          id="awesome-popup-old"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconWin} />
                  <h2 className="text-uppercase">Awesome</h2>
                  <small>We can Deliver !</small>
                </div>
                <div className="awesome_del">
                  <h5>Your Delivery Address :</h5>
                  <h2>{this.state.orderDeliveryAddress}</h2>
                </div>
                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.gotoProducts.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* error Popup - start */}
        <div
          id="outlet-error-popup"
          className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_body">
                <img className="warning-popup-img" src={warningImg} />
                <h2 className="text-uppercase">Sorry</h2>
                <p>{"We can`t Deliver At the Moment!"}</p>
                <p>Please come back again.</p>
                <div className="alt_btns">
                  {this.state.seletedAvilablityId === pickupId ? (
                    <a
                      href="/"
                      onClick={this.gobckPkupOutletpopup.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      change outlet
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.gobckOutletpopup.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      change address
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* error Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="takeaway-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> Self Collection </h3>
            <p>Let us Know your Self Collection Outlet</p>
          </div>
          <div className="full-login-new-body">
            <img src={takeawayImg} />

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus search_outlet"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <ul className="self_outlet_inner">
                      {this.loadOutletsList()}
                    </ul>
                    {this.state.error_pickup_outlet === 1 && (
                      <>
                        <br />
                        <div className="postal_error">
                          <span className="error">
                            Please select any one outlet
                          </span>
                        </div>
                      </>
                    )}

                    <div className="takelist-btn-part common-button-row">
                      <a
                        className="button"
                        href="/"
                        onClick={this.gobackOrderNow.bind(this)}
                      >
                        Go Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="viewmenu-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> View Menu </h3>
            <p>Please choose the outlet to view the menu</p>
          </div>
          <div className="full-login-new-body">
            <img src={menuImg} />
            {/*  <img className="order_type_img" src={menuImg} />
                            <img className="order_type_imgwt" src={menuwImg} /> */}
            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus search_outlet"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <ul className="self_outlet_inner">
                      {this.loadMenuOutletsList()}
                    </ul>
                    <div className="takelist-btn-part">
                      <a
                        className="button takeaway-btn-act"
                        id="takeaway-continue-link"
                        href="/"
                        onClick={this.gobackOrderNow.bind(this)}
                      >
                        Go Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/*  Dinein Popup - start */}
        <div
          id="dinein-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> dine in </h3>
            <p>Let us know your dine in table number</p>
          </div>
          <div className="full-login-new-body">
            <img src={dineImg} />
            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item dinein_popup_item">
                  {this.state.dine_in_click !== "Yes" && (
                    <button
                      className="scan-qr"
                      typ="button"
                      onClick={this.startQRScan.bind(this)}
                    >
                      <img src={qrImg} />
                      <span>Scan Your QR Code</span>
                    </button>
                  )}

                  {/* {(this.state.dine_in_click === "Yes" && <>
                      <div className="self_popup_hea_row" id="qr_code">
                        <QrReader
                        delay={300}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{ width: '312px' }}
                        />
                      </div>
                      <div>
                        <button typ="button" onClick={ this.handleError }>Cancel</button>
                      </div>
                    </>)} */}

                  {this.state.dine_in_click !== "Yes" && (
                    <div className="innervmid_in">
                      <div className="form-group">
                        <input
                          type="text"
                          id="table_number"
                          onChange={this.HandleTableNumberchng}
                          placeholder="Enter Your Table Number"
                          className="form-control input-focus"
                        />
                      </div>
                    </div>
                  )}

                  {this.state.dine_in_click !== "Yes" && (
                    <div className="takelist-btn-part">
                      {/* <a
                        className="button"
                        href="/"
                        onClick={this.gobackOrderNow.bind(this)}
                      >
                        Back
                      </a> */}
                      {/* <div>
                        <button typ="button" onClick={this.gobackOrderNow.bind(this)}>Back</button>
                      </div> */}
                      <button
                        type="button"
                        className="button takeaway-btn-act"
                        onClick={this.findTable.bind(this)}
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dinein Popup - end */}

        {/*  Coming Soon Popup - start */}
        <div
          id="comingsoon-popup"
          className="white-popup mfp-hide popup_sec comingsoon_popup"
        >
          <div className="order-body">
            <div className="comingsoon_popup_hea_row">
              <div className="comingsoon_popup_hea_col">
                <h2>COMING SOON.</h2>
              </div>
            </div>
            <div className="comingsoon_pop_row">
              <p> can you select another availability.</p>
              <a
                href="/"
                onClick={this.closepopup.bind(this)}
                className="button disbl_href_action"
                title="Go Back"
              >
                Go Back
              </a>
            </div>
          </div>
        </div>
        {/* Coming Soon Popup - end */}

        {/* Warning Popup - start */}
        <div
          id="warning-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="full-login-new-header">
            <h3>Warning</h3>
          </div>
          <div className="full-login-new-body">
            <input type="hidden" id="warning_avilablity" />
            <img className="warning-popup-img" src={warningImg} />
            {this.state.switchmsg !== "" &&
            this.state.switchmsg !== "/products" ? (
              <>
                <p>
                  Switch to{" "}
                  {this.state.switchmsg[0].toUpperCase() +
                    this.state.switchmsg.slice(1)}
                  ?
                </p>
              </>
            ) : (
              <>
                <p>By switching you are about to clear your cart.</p>
                <p>Do you wish to proceed ?</p>
              </>
            )}
            <input type="hidden" id="clearcartavailability" />
            <div className="alt_btns">
              <a
                href={void 0}
                className="popup-modal-dismiss button button-left disbl_href_action"
              >
                No
              </a>
              {/* popup-modal-dismiss */}
              <a
                href={void 0}
                onClick={this.changeAvailability.bind(this)}
                className="button button-right  disbl_href_action clear_all_values"
              >
                Yes
              </a>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        <div className="mbl-menu-overly"></div>
      </header>
    );
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  var tableAvailability = Array();
  if (Object.keys(state.tableavailability).length > 0) {
    // if (state.tableavailability[0].status === "ok") {
    tableAvailability = state.tableavailability[0];
    // }
  }

  return {
    globalsettings: state.settings,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    tableAvailability: tableAvailability,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
    getTableAvailability: (formPayload) => {
      dispatch({ type: GET_TABLEAVAILABILITY, formPayload });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
