/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";
import axios from "axios";
import { appId, apiUrl } from "../Helpers/Config";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = { FaqList: [], FaqDisplay: "" };
  }

  componentDidMount() {
    $("#dvLoading").hide();
    var urlShringTxt = apiUrl + "cms/faq?status=A&app_id=" + appId;

    axios.get(urlShringTxt).then((res) => {
      var FaqList = "";
      console.log(res.data, "res.datares.data");
      if (res.data.status === "ok") {
        var FaqList = res.data.result_set;
      }
      this.setState(
        {
          FaqList: FaqList,
        },
        function () {
          // this.loadFaq();
        }
      );
    });
  }
  // loadFaq() {
  //   var Faq_List = "";
  //   if (this.state.FaqList.length > 0) {
  //     Faq_List = (
  //       <Accordion>
  //         {this.state.FaqList.map((item, index) => {
  //           if (Object.keys(item.faqs).length > 0) {
  //             var faqListNew = item.faqs;
  //             return Object.keys(item.faqs).map((item1, index1) => {
  //               var faqListCurrent = faqListNew[item1];
  //               if (
  //                 faqListCurrent.faq_title !== "" &&
  //                 faqListCurrent.faq_title !== null
  //               ) {
  //                 return (
  //                   <AccordionItem
  //                     title={
  //                       faqListCurrent.faq_title !== null
  //                         ? Parser(faqListCurrent.faq_title)
  //                         : ""
  //                     }
  //                     key={index + index1}
  //                   >
  //                     {faqListCurrent.faq_description !== "" &&
  //                     faqListCurrent.faq_description !== null
  //                       ? Parser(faqListCurrent.faq_description)
  //                       : ""}
  //                   </AccordionItem>
  //                 );
  //               }
  //             });
  //           }
  //         })}
  //       </Accordion>
  //     );
  //   }
  //   this.setState({ FaqDisplay: Faq_List });
  // }

  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="faq-page">
          <h3>Frequently Asked Questions</h3>
          <div className="container-one cms-content">
            <div className="myacc_filter">
              <div className="tab_sec filter_tabsec" id="ordertab1">
                <ul className="nav nav-tabs text-center">
                  {this.state.FaqList.map((item, index) => {
                    return (
                      <li className={index === 0 ? "active" : ""}>
                        <a
                          data-toggle="tab"
                          href={"#tab-id-inn" + index}
                          aria-expanded="true"
                        >
                          <span>{item.faqcategory_title}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <div className="container cms-content">
                  <div className="tab-content">
                    {this.state.FaqList.map((item, index) => {
                      return (
                        <div
                          id={"tab-id-inn" + index}
                          className={
                            index === 0
                              ? " tab-pane fade active in"
                              : "tab-pane fade"
                          }
                        >
                          <h4
                            className={
                              "tab_mobtrigger inner_tab_border " + (index === 0)
                                ? "active"
                                : ""
                            }
                          >
                            <i></i>
                          </h4>
                          <div className="tab_mobrow filter_tabin">
                            <Accordion>
                              {item.faqs.map((item1, index1) => {
                                return (
                                  <AccordionItem
                                    title={
                                      item1.faq_title !== null
                                        ? Parser(item1.faq_title)
                                        : ""
                                    }
                                    key={index + index1}
                                  >
                                    {item1.faq_description !== "" &&
                                    item1.faq_description !== null
                                      ? Parser(item1.faq_description)
                                      : ""}
                                  </AccordionItem>
                                );
                              })}
                            </Accordion>
                          </div>
                        </div>
                      );
                    })}

                    {/* <div id="tab-id-inn6" className="tab-pane fade">
                            <h4 className="tab_mobtrigger inner_tab_border"><i></i></h4>
                            <div className="tab_mobrow filter_tabin">
                              
                            </div>
                        </div>
                        <div id="tab-id-inn7" className="tab-pane fade">
                            <h4 className="tab_mobtrigger inner_tab_border"><i></i></h4>
                            <div className="tab_mobrow filter_tabin">
                              
                            </div>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container cms-content">{this.state.FaqDisplay}</div> */}
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Faq.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));
