/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Sidebar from "./Sidebar";

import {
  fbAppId,
  baseUrl,
  appId,
  apiUrl,
  reservationId,
} from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";

import { connect } from "react-redux";

import Moment from "moment";
var qs = require("qs");
import {
  GET_RESERVATIONHISTORY,
  GET_RESERVATIONCOMPHISTORY,
  GET_GLOBAL_SETTINGS,
  GET_ACTIVITYCOUNT
} from "../../actions";

import cookie from "react-cookies";

import DateImg from "../../common/images/date-reserve-icon.png";

class Myreservation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showresvitems: 10,
      showresvcompitems: 10,
      overall_orders: 0,
      reservation_orders: 0,
      reservationlist: [],
      reservationlistComp: [],
      activereservationitem: [],
	  reservation_remark: '',
	  remarkerror: '',
      globalsettings: []
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
    this.props.getGlobalSettings();
  }

  componentDidMount() {

    let tabSelect =
      typeof this.props.match.params.tab !== "undefined"
        ? this.props.match.params.tab
        : "";

    /* reservation orders */
    this.props.getReservationHistory(this.state.showresvitems, "P");
    this.props.getReservationCompHistory(this.state.showresvcompitems, "C");

    $("#dvLoading").fadeOut(2000);

    //On Click Event
    $("ul.web_order_typecls li").click(function () {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });
    
    //Default Action
    $(".filter_tabsec").hide(); //Hide all content

    if(tabSelect === "reservation") {
      $("ul.web_order_typecls li:nth-child(2)").trigger("click");
    } else {
      $("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab
      $(".filter_tabsec:first").show(); //Show first tab content
    }

    this.getActivityCounts();

  }

  getActivityCounts() {
    const inputKeys = [
      "catering_orders",
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }
  
  loadReservationItems() {
    showLoader("reserv-loader-div", "class");
    var pageNext = this.state.showresvitems + 10;
    this.setState({ showresvitems: pageNext }, function () {
      this.props.getReservationHistory(pageNext, "P");
    });
  }

  loadReservationCompItems() {
    showLoader("reserv-loader-div", "class");
    var pageNext = this.state.showresvcompitems + 10;
    this.setState({ showresvcompitems: pageNext }, function () {
      this.props.getReservationCompHistory(pageNext, "C");
    });
  }

  componentWillReceiveProps(nextProps) {

    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
        if (nextProps.activitycount !== this.props.activitycount) {
          if (
            nextProps.activitycount[0].status &&
            nextProps.activitycount[0].result_set
          ) {
            this.setState({
              order_all: nextProps.activitycount[0].result_set.order_all,
              overall_orders:
                nextProps.activitycount[0].result_set.overall_orders,
              deliveryTakeaway_orders:
                nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
              catering_orders:
                nextProps.activitycount[0].result_set.catering_orders,
              reservation_orders:
                nextProps.activitycount[0].result_set.reservation_orders,
            });
            $("#dvLoading").fadeOut(2000);
          }
        }
    } else {
        this.setState({
            order_all: 0,
            overall_orders: 0,
            catering_orders: 0,
            deliveryTakeaway_orders: 0,
            reservation_orders: 0,
        });
    }
    
    if (nextProps.reservationlist !== this.state.reservationlist) {
      var displyTxt = "none";
      if (
        this.state.showresvitems < nextProps.reservationcommon.total_records
      ) {
        displyTxt = "inline-block";
      }
      hideLoader("reserv-loader-div", "class");
      this.setState({
        reservationlist: nextProps.reservationlist,
        resviewmore: displyTxt,
      });
    }

    if (nextProps.reservationlistComp !== this.state.reservationlistComp) {
        var displyTxt = "none";
        if (
          this.state.showresvcompitems < nextProps.reservationcommonComp.total_records
        ) {
          displyTxt = "inline-block";
        }
        hideLoader("reserv-loader-div", "class");
        this.setState({
          reservationlistComp: nextProps.reservationlistComp,
          resviewcompmore: displyTxt,
        });
      }

    if(nextProps.globalsettings !== this.props.globalsettings) {
      if (nextProps.globalsettings[0].status === "ok") {
        this.setState({ globalsettings: nextProps.globalsettings[0].result_set })
      }
    }
  }

  reservStatus(statusTxt) {
    var returnTxt = "Pending";
    statusTxt = statusTxt !== "" ? parseInt(statusTxt) : 0;
    if (statusTxt === 2) {
      returnTxt = "Cancel";
    } else if (statusTxt === 1) {
      returnTxt = "Confirmed";
    } else if (statusTxt === 3) {
      returnTxt = "Arrived";
    } else if (statusTxt === 4) {
      returnTxt = "Not Arrived";
    } else if (statusTxt === 5) {
      returnTxt = "Completed";
    }

    return returnTxt;
  }

  showNoOfPax(adultCount, childCount) {
    var adultTxt =
      parseInt(adultCount) == 0
        ? ""
        : parseInt(adultCount) == 1
        ? adultCount + " Adult"
        : adultCount + " Adults";
    var childTxt =
      parseInt(childCount) == 0
        ? ""
        : parseInt(childCount) == 1
        ? childCount + " Child"
        : childCount + " Children";
    var paxText = adultTxt + " " + childTxt;

    return paxText;
  }
  
  cancelReservationItems(item) {
	  this.setState({ activereservationitem: item }, function () {
            $.magnificPopup.open({
				items: {
				  src: "#cancelreservtn-popup",
				},
				type: "inline",
			});
      });
  }
  
  cancelReservationActn() {
	  var reservationItem = this.state.activereservationitem;
	  var reservation_remark = this.state.reservation_remark;
	  if(reservation_remark !== '') {
		  if(Object.keys(reservationItem).length > 0) {
				showLoader("cancelreservtn-btn", "class");
				var postObject = {
				  app_id: appId,
				  customer_id: reservationItem.reservation_customer_id,
				  reservation_id: reservationItem.reservation_id,
				  reservation_remark: reservation_remark
				};

				axios.post(apiUrl + "reservation/reservation_cancel", qs.stringify(postObject))
				  .then((response) => {
					hideLoader("cancelreservtn-btn", "class");
					if(response.data.status === "ok") {
					  window.scrollTo(0, 0);
					  $.magnificPopup.close();
					  const { history } = this.props;
					  history.push("/refpage/gotomyresvr");
					}
					return false;
				  }).catch(function (error) {
					console.log(error);
				  });
		  }
	  } else {
		  this.setState({remarkerror: 'Please enter the remarks'});
	  }
  }
  
  HandlechngRemark = (event) => {
    this.setState({reservation_remark: event.target.value, remarkerror: ''});
  }

  /* reservation order - start*/
  getReservationItemData = (dataProp) => {
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.reservation_date).format("DD/MM/YYYY");
        var dataTmtxt =
          Moment(item.reservation_date).format("YYYY/MM/DD") +
          " " +
          item.reservation_start_time;
        const orderTime = Moment(dataTmtxt).format("h:mm A");

        return (
          <div
            key={item.reservation_id}
            className="current_order reserv-orderlist"
          >
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER NO - {item.reservation_local_order_id}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{this.reservStatus(item.reservation_status)}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>Reservation Date & Time</h2>
                  <h4 className="checkoutDate">
                    {orderDate} {orderTime}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>NO OF PAX</h2>
                  <h4 className="checkoutTime">
                    {this.showNoOfPax(
                      item.reservation_no_of_adult_pax,
                      item.reservation_no_of_children_pax
                    )}
                  </h4>
                </div>
              </div>
              <div className="order_no_deatails">
                <h3>Outlet Name</h3>
                <p>{item.reservation_outlet_name}</p>
				{(item.reservation_status !== '2' && item.reservation_status !== '5') && <button className="button" onClick={this.cancelReservationItems.bind(this,item)}>Cancel</button>}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h3>My Account</h3>
            <p className="acc-para">My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.</p>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section account_sec  ">
                    <Sidebar pageName="myreservations" />
                    <div className="box_in accsec_right">
                      <div className="account_sec">
                        <div className="accsec_right">
                          <div className="tab_sec main_tabsec_inner">

                          {/* <ul className="web_account_menu_cls orc_list orderType web_order_typecls"> 
                           
                            <li data-tab="active orc-tab2" className="orderTypeli getReservationCls"> 
                              <a className="ReservationorderNotifi" href="#ordertab3"> <i className="sprite_lst_icon reserve_icon_t ">
                              <img src={DateImg} /></i> 
                              <h5>Reservation</h5> 
                              {(parseFloat(this.state.reservation_orders)>0) && <span className="count_num_t " id="ReservationorderNotifi">{this.state.reservation_orders}</span>}
                              </a>
                            </li>

                          </ul> */}

                            <div className="myacc_filter">
                              <div
                                className="tab_sec filter_tabsec"
                                id="ordertab3"
                              >
                                <ul className="nav nav-tabs text-center">
                                  <li className="active">
                                    <a
                                      data-toggle="tab"
                                      href="#tab-id-inn5"
                                      aria-expanded="true"
                                    >
                                      <span>Pending Bookings</span>
                                    </a>
                                  </li>
                                  <li className="">
                                    <a
                                      data-toggle="tab"
                                      href="#tab-id-inn6"
                                      aria-expanded="false"
                                    >
                                      <span>Confirmed Bookings</span>
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content">
                                  <div
                                    id="tab-id-inn5"
                                    className="tab-pane fade active in"
                                  >
                                    <h4 className="tab_mobtrigger inner_tab_border active">
                                      <i></i>
                                    </h4>
                                    <div className="tab_mobrow filter_tabin">
                                      <div className="">
                                        <div className="ord-body">
                                          <div className="cur-order-body">
                                            <div className="myacc_order_details">
                                                {this.getReservationItemData(this.state.reservationlist)}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="load_more_div">
                                    <button className="load_more_resdata" style={{display:this.state.resviewmore}} onClick={this.loadReservationItems.bind(this)}>Load More</button>
                                    </div>
                                  </div>
                                  <div
                                    id="tab-id-inn6"
                                    className="tab-pane fade"
                                  >
                                    <h4 className="tab_mobtrigger inner_tab_border">
                                      <i></i>
                                    </h4>
                                    <div className="tab_mobrow filter_tabin">
                                      <div className="">
                                        <div className="ord-body">
                                          <div className="pst-order-body">
                                            <div className="myacc_order_details">
                                                {this.getReservationItemData(this.state.reservationlistComp)}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="load_more_div">
                                    <button className="load_more_resdata" style={{display:this.state.resviewcompmore}} onClick={this.loadReservationCompItems.bind(this)}>Load More</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* next tab */}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>
		
		
		<div id="cancelreservtn-popup" className="white-popup mfp-hide popup_sec self_popup cancelreservtn_popup">
			{(Object.keys(this.state.activereservationitem).length > 0) && <div>
				  <div className="full-login-new-header">
					<h3> Reservation Cancel Request </h3>
					<p>Reservation OrderId - {this.state.activereservationitem.reservation_local_order_id}</p>
				  </div>
				  <div className="full-login-new-body">
						<div className="form-group">
							<div className="focus-out2" style={{textAlign: 'left'}}>
							  <textarea className="form-control input-focus" placeholder="Remarks*" onChange = { this.HandlechngRemark } id="remarks"></textarea>
							  <span style={{color: 'red'}}>{this.state.remarkerror}</span>
							</div>
						</div>
						<div className="btn_sec">
						    <button className="button cancelreservtn-btn" onClick={this.cancelReservationActn.bind(this)}>Submit</button>
						</div>
				  </div>
			</div>}
        </div>
		
        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var reshistoryArr = Array();
  var rescommonArr = Array();
  var rescomphistoryArr = Array();
  var rescompcommonArr = Array();

  if (Object.keys(state.reservationhistory).length > 0) {
    if (state.reservationhistory[0].status === "ok") {
      reshistoryArr = state.reservationhistory[0].result_set;
      rescommonArr = state.reservationhistory[0].common;
    }
  }

  if (Object.keys(state.reservationcomphistory).length > 0) {
    if (state.reservationcomphistory[0].status === "ok") {
      rescomphistoryArr = state.reservationcomphistory[0].result_set;
      rescompcommonArr = state.reservationcomphistory[0].common;
    }
  }

  return {
    globalsettings: state.settings,
    activitycount: state.activitycount,
    reservationlist: reshistoryArr,
    reservationcommon: rescommonArr,
    reservationlistComp: rescomphistoryArr,
    reservationcommonComp: rescompcommonArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getReservationHistory: (limitTxt, orderStatus) => {
      dispatch({ type: GET_RESERVATIONHISTORY, limitTxt, orderStatus });
    },
    getReservationCompHistory: (limitTxt, orderStatus) => {
        dispatch({ type: GET_RESERVATIONCOMPHISTORY, limitTxt, orderStatus });
      },
  };
};

Myreservation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Myreservation));
