/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import fs1 from "../../common/images/OS1.jpg";
import fs2 from "../../common/images/OS2.jpg";
import fs3 from "../../common/images/OS3.jpg";
import fs4 from "../../common/images/OS4.jpg";
import fs5 from "../../common/images/OS5.jpg";
import fs6 from "../../common/images/OS6.jpg";
import Axios from "axios";
import $ from 'jquery'; 
import Slider from "react-slick";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showAlert,
  showCustomAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import { appId, apiUrlV2, deliveryId } from "../Helpers/Config";
import noimage from "../../common/images/no-img-product.png";
import cartLike from "../../common/images/cat-item-like-bw.png";
import cartLikeActive from "../../common/images/cat-item-like-bw-active.png";
import { GET_PRODUCT_LIST, GET_FAVOURITE } from "../../actions";
import { isNull } from "underscore";
var qs = require("qs");
var Parser = require("html-react-parser");

var returnvalue = '';

class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      loading: true,
      productCommon: [],
      productDetails: [],
      filter_Tag: [],
      filter_Brand_Tag: "",
      cartItemsLength: 0,
      catSlug: "",
      subcatSlug: "",
      sort_By_Price: "",
      sort_By_PriceName: "Sort By",
      sort_By_category: "",
      sort_By_categoryName: "Shop By",
      showShortByPrice: false,
      showShortByCategory: false,
      autoplayvalue: false,
      searchKeyWord: "",
      emptysearchKey: 0,
      productDetailsHtml: "",
      viewProductSlug: "",
      startProDetails: 0,
      scrollProduct: "",
      favproductID: [],
      favdeleteproductID: [],
      scrollvalue : '',
      customerId:
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId"),
    };
  }

  componentDidMount() {
    var currentthis = this;
    $(document).click(function (e) {
      if (!$(e.target).is(".filters-lhs, .filters-lhs * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".filters-rhs, .filters-rhs * ")) {
        currentthis.setState({ showShortByCategory: false });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (this.state.searchKeyWord !== nextProps.productState.searchKeyWord) {
      if (nextProps.productState.searchKeyWord !== "") {
        this.setState(
          { searchKeyWord: nextProps.productState.searchKeyWord },
          function () {
            showLoader("productlist-main-div", "class");
            this.productsubcatlist();
          }
        );
      }
    }
    if (this.state.emptysearchKey !== nextProps.productState.emptysearchKey) {
      this.setState(
        {
          emptysearchKey: nextProps.productState.emptysearchKey,
          searchKeyWord: "",
        },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("emptysearchKey", 0);
          this.setState({ emptysearchKey: 0 });
        }
      );
    }

    if (nextProps.productState.stopFilterPrice === "Yes") {
      this.sortByPrice(nextProps.productState.sort_By_Price);
      this.props.sateValChange("stopFilterPrice", "No");
    }
    if (nextProps.productState.startFilter === "Yes") {
      this.setState(
        {
          showShortByPrice: false,
          showShortByCategory: false,
          sort_By_Price: "",
          sort_By_PriceName: "Sort By",
          sort_By_category: "",
          sort_By_categoryName: "Shop By",
        },
        function () {
          this.props.sateValChange("startFilter", "No");
        }
      );
    }
    if (selectedNavigation !== this.state.selectedNavigation) {
      if (nextProps.match.path !== "/menu") {
        $("html, body").animate(
          { scrollTop: $(".productlist-main-div").offset().top - 30 },
          2000
        );
      }

      var catSlug = "";
      var subcatSlug = "";
      if (selectedNavigation !== "") {
        catSlug = selectedNavigation;
        subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.getProductList(catSlug, subcatSlug, "");
        $(".addcart_done_maindiv").hide();
        $(".smiple_product_lk").show();
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
        catSlug: catSlug,
        subcatSlug: subcatSlug,
      });
    }
    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }
    if (nextProps.productState.setFiltetBrandTag === "Yes") {
      this.setState(
        { filter_Brand_Tag: nextProps.productState.filterBrandTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetBrandTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (this.state.cartItemsLength !== nextProps.productState.cartItemsLength) {
      this.setState(
        { cartItemsLength: nextProps.productState.cartItemsLength },
        function () {
          this.productsubcatlist();
        }
      );
    }
  }

  onMouseEnter(galleryimage, index){
    let imageSource = this.props.productCommon.product_image_source;
    let productgalleryimagesource =
    this.props.productCommon.product_gallery_image_source;
    this.setState({scrollimages : galleryimage});
    const returnvalue =  galleryimage.map((images , indexs) => {
          return(<div className={"slider" + indexs} key={indexs}>
            <img className={"sliderimg" + indexs} src={productgalleryimagesource + "/" + images.pro_gallery_image} />
          </div>
          );
      })
      this.setState({ scrollvalue : returnvalue, mouseIndex : index, autoplayvalue : true },
      function () {
        this.productsubcatlist();
      }
    );
  }

  onMouseLeave(){
    this.setState({mouseIndex : -1, autoplayvalue : false},
      function () {
        this.productsubcatlist();
      }
    );
  }

  productsubcatlist() {
    
    var subcatlistArr = this.state.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      $("#no_product_error").hide();
      setTimeout(function () {}, 100);
      setInterval(function () {
        if ($(".productlist-main-div.loader-main-cls").length > 0) {
          hideLoader("productlist-main-div", "class");
        }
      }, 1000);
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          var subcatDesc =
            categories.pro_subcate_description !== ""
              ? stripslashes(categories.pro_subcate_description)
              : categories.pro_subcate_short_description !== ""
              ? stripslashes(categories.pro_subcate_short_description)
              : "";
          return (
            <div className="innerproduct" key={categoryIndex}>
              <h3 id={categories.pro_subcate_slug} className="sub_cate_title">
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              {subcatDesc !== "" && <p>{subcatDesc}</p>}
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.pro_cate_slug,
                  categories.pro_subcate_slug
                )}
              </ul>
            </div>
          );
        }
      });
      this.setState({ productDetails: productDetails }, function () {
        var totalProducts = 0;
        if ($(".innerproduct").length > 0) {
          $(".innerproduct").map(function () {
            if ($(this).find(".products-list-ulmain li").length > 0) {
              totalProducts++;
              $(this).find(".sub_cate_title").show(); 
              $(this).find(".sub_cate_description").show();
            } else {
              $(this).find(".sub_cate_title").hide();
              $(this).find(".sub_cate_description").hide();
            }
          });
          if (totalProducts > 0) {
            $("#no_product_error").hide();
          } else {
            $("#no_product_error").show();
          }
        }
        if (this.state.scrollProduct !== "") {
          if ($("#prolistproIndex-" + this.state.scrollProduct).length > 0) {
            $("html, body").animate(
              {
                scrollTop:
                  $("#prolistproIndex-" + this.state.scrollProduct).offset()
                    .top - 100,
              },
              500
            );
            this.setState({ scrollProduct: "" });
          }
        }
      });
      return productDetails;
    } else {
      if (this.state.loading === false) {
        setInterval(function () {
          if ($(".productlist-main-div.loader-main-cls").length > 0) {
            hideLoader("productlist-main-div", "class");
          }
        }, 1000);
        //$("#no_product_error").show();
      }

      return "";
    }
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }
    return returnText;
  }

  /* Products List */
  productList(list, pro_cate_slug, pro_subcate_slug) {
    
    const settings = {
      arrows: false,
      dots: false,
      pauseOnHover: false,
      infinite: true,
      speed: 100,
      autoplay: this.state.autoplayvalue,
      fade: !50,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: false,
      autoplaySpeed: 2000
    }
    var imageSource = this.props.productCommon.product_image_source;
    var productgalleryimagesource =
      this.props.productCommon.product_gallery_image_source;
    var totalProduct = 0;
    const listProduct = list.map((productDetail, index) => {
      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      var productName =
        productDetail.product_alias !== ""
          ? stripslashes(productDetail.product_alias)
          : stripslashes(productDetail.product_name);
      let filter = this.checkFilterTag(productDetail.product_tag);
      var existCart = this.checkExistCart(productDetail.product_id);
      let checkSeach = this.checkSeach(
        productName,
        productDetail.product_tag,
        productDetail.product_brand_tag
      );

      if (this.props.productCommon.main_category_slug !== "") {
        this.props.sateValChange(
          "selectedMainCat",
          this.props.productCommon.main_category_slug
        );
        this.props.sateValChange(
          "selectedMainCatId",
          this.props.productCommon.main_category_id
        );
      }

      var cart_label = "Order Now";
      checkSeach = 1;
      if (checkSeach === 1) {
        totalProduct++;
        return (
          <>
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock
            )}
            id={prodivId}
            key={index}
            onMouseEnter={this.onMouseEnter.bind(this, productDetail.image_gallery, index)} onMouseLeave={() => this.onMouseLeave()}
          >
            <div id={"prolist" + prodivId} className="product_list_sec">
              {productDetail.product_tag_info !== "" &&
                productDetail.product_tag_info !== null && (
                  <div className="ribbon">
                    <span>{productDetail.product_tag_info}</span>
                  </div>
                )}
              {this.state.customerId !== "" ? (
                <div>
                  {this.state.favdeleteproductID.length > 0 &&
                  this.state.favdeleteproductID.indexOf(
                    productDetail.product_primary_id
                  ) >= 0 ? (
                    <a
                      href={void 0}
                      className="like"
                      onClick={this.addFavourite.bind(
                        this,
                        productDetail.product_primary_id,
                        "Yes"
                      )}
                    >
                      <img src={cartLike} />
                      <img src={cartLikeActive} className="active-hart" />
                    </a>
                  ) : (!isNull(productDetail.fav_product_primary_id) &&
                      typeof productDetail.fav_product_primary_id !==
                        undefined &&
                      typeof productDetail.fav_product_primary_id !==
                        "undefined") ||
                    this.state.favproductID.indexOf(
                      productDetail.product_primary_id
                    ) >= 0 ? (
                    <a
                      href={void 0}
                      className="like active"
                      onClick={this.addFavourite.bind(
                        this,
                        productDetail.product_primary_id,
                        "No"
                      )}
                    >
                      <img src={cartLike} />
                      <img src={cartLikeActive} className="active-hart" />
                    </a>
                  ) : (
                    <a
                      href={void 0}
                      className="like"
                      onClick={this.addFavourite.bind(
                        this,
                        productDetail.product_primary_id,
                        "Yes"
                      )}
                    >
                      <img src={cartLike} />
                      <img src={cartLikeActive} className="active-hart" />
                    </a>
                  )}
                </div>
              ) : (
                <div>
                  <a
                    href={void 0}
                    className="like"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "Yes"
                    )}
                  >
                    <img src={cartLike} />
                    <img src={cartLikeActive} className="active-hart" />
                  </a>
                </div>
              )}

              <div className="products-image-div">
                {productDetail.product_thumbnail !== "" ? ( 
                  (index == this.state.mouseIndex ?
                    <Slider {...settings}>
                      {this.state.scrollvalue}
                    </Slider>
                     :
                       <img
                         src={imageSource + "/" + productDetail.product_thumbnail}
                       />
                  )) : (
                    <img src={noimage} />
                )}
                {productDetail.rating_reviews.rating > 0 && (
                  <fieldset className="pantry_rating">
                    <input type="radio" id="star5" name="rating" value="5" />
                    <label
                      className={
                        productDetail.rating_reviews.rating >= 5
                          ? "full custom_checked"
                          : "full"
                      }
                      for="star5"
                      title="5 stars"
                    ></label>
                    <input type="radio" id="star4" name="rating" value="4" />
                    <label
                      className={
                        productDetail.rating_reviews.rating >= 4
                          ? "full custom_checked"
                          : "full"
                      }
                      for="star4"
                      title="4 stars"
                    ></label>
                    <input type="radio" id="star3" name="rating" value="3" />
                    <label
                      className={
                        productDetail.rating_reviews.rating >= 3
                          ? "full custom_checked"
                          : "full"
                      }
                      for="star3"
                      title="3 stars"
                    ></label>
                    <input type="radio" id="star2" name="rating" value="2" />
                    <label
                      className={
                        productDetail.rating_reviews.rating >= 2
                          ? "full custom_checked"
                          : "full"
                      }
                      for="star2"
                      title="2 stars"
                    ></label>
                    <input type="radio" id="star1" name="rating" value="1" />{" "}
                    <label
                      className={
                        productDetail.rating_reviews.rating >= 1
                          ? "full custom_checked"
                          : "full"
                      }
                      for="star1"
                      title="1 star"
                    ></label>
                  </fieldset>
                )}
              </div>

              <div className="product-info-div">
                <div className="product-title-maindiv">
                  <div className="product-title">
                    <h3>
                      {productDetail.product_alias !== ""
                        ? stripslashes(productDetail.product_alias)
                        : stripslashes(productDetail.product_name)}
                    </h3>
                  </div>
                </div>
                <div className="products-ordernow-action">
                  <div className="product-price">
                    {productDetail.product_specialprice_applicable === "yes"
                      ? parseFloat(productDetail.product_price) > 0 && (
                          <span className="price_disc">
                            {showPriceValue(productDetail.product_price, "Y")}
                          </span>
                        )
                      : parseFloat(productDetail.product_cost) > 0 && (
                          <span className="price_disc">
                            {showPriceValue(productDetail.product_cost, "Y")}
                          </span>
                        )}
                    <h3>
                      {productDetail.product_specialprice_applicable === "yes"
                        ? showPriceValue(
                            productDetail.product_special_price,
                            "Y"
                          )
                        : showPriceValue(productDetail.product_price, "Y")}
                    </h3>
                  </div>

                  {productDetail.product_stock > 0 ||
                  productDetail.product_stock === null ? (
                    productDetail.product_type === "1" ||
                    productDetail.product_type === "5" ? (
                      <a
                        className="button order_nowdiv smiple_product_lk disbl_href_action"
                        href="/"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "initial"
                        )}
                      >
                        Order Now
                      </a>
                    ) : (
                      <a
                        href={
                          "/menu/" +
                          pro_cate_slug +
                          "/" +
                          pro_subcate_slug +
                          "/" +
                          productDetail.product_slug
                        }
                        onClick={(e) => {
                          showLoader(comboProId);
                        }}
                        /*  href="/"
                        onClick={this.viewProDetail.bind(
                          this,
                          productDetail,
                          pro_subcate_slug
                        )} */
                        title="Product Details"
                        id={comboProId}
                        className="button order_nowdiv compo_product_lk"
                      >
                        Order Now
                      </a>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      Sold Out
                    </a>
                  )}

                  <div className="addcart_row addcart_done_maindiv">
                    <div className="addcart-row-child">
                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "decr"
                          )}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value="1"
                        />
                        <span
                          className="qty_plus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "incr"
                          )}
                        >
                          +
                        </span>
                      </div>
                      <button
                        className="btn btn_black"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "done"
                        )}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.viewProductSlug === productDetail.product_slug &&
              this.state.productDetailsHtml}
          </li>
          </>
        );
      }
    });
    if (totalProduct === 0) {
      $("#" + pro_cate_slug).hide();
    } else {
      $("#" + pro_cate_slug).show();
    }
    return listProduct;
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>
        {item.pro_tag_image !== "" && (
          <img src={tagImageUrl + item.pro_tag_image} />
        )}
        {stripslashes(item.pro_tag_name)}
      </li>
    ));
  }

  checkExistCart(productID) {
    if (this.props.productState.cartList.length > 0) {
      var proExist = 0;
      this.props.productState.cartList.map((item) => {
        if (item.cart_item_product_id === productID) {
          proExist++;
        }
      });
      if (proExist > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addFavourite(productID, fav_flag) {
    var formData = {
      app_id: appId,
      product_id: productID,
      fav_flag: fav_flag,
      customer_id: cookie.load("UserId"),
    };
    var favproductID = this.state.favproductID;
    var favdeleteproductID = this.state.favdeleteproductID;
    if (fav_flag === "Yes") {
      favproductID.push(productID);
      const index1 = favdeleteproductID.indexOf(productID);
      if (index1 > -1) {
        favdeleteproductID.splice(index1, 1);
      }
    } else {
      const index = favproductID.indexOf(productID);
      if (index > -1) {
        favproductID.splice(index, 1);
      }
      favdeleteproductID.push(productID);
    }
    this.setState(
      { favproductID: favproductID, favdeleteproductID: favdeleteproductID },
      function () {
        this.productsubcatlist(
          this.state.OldupdateproductList,
          this.state.oldnavigateMenu
        );
      }
    );

    var postObject = qs.stringify(formData);
    this.props.getFavouriteproducts(postObject);
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }
  checkSeach(productName, tagList, brandTagList) {
    var searchKeyWord = this.state.searchKeyWord;
    var filter_Tag = this.state.filter_Tag;
    var filter_Brand_Tag = this.state.filter_Brand_Tag;
    var returnPro = 0;
    if (searchKeyWord !== "") {
      var proExistN = 0;
      if (productName.toLowerCase().indexOf(searchKeyWord.toLowerCase()) >= 0) {
        proExistN = 1;
      }
      if (proExistN == 0) {
        if (tagList !== "" && tagList !== null && tagList.length > 0) {
          tagList.map((item) => {
            if (
              item.pro_tag_name
                .toLowerCase()
                .indexOf(searchKeyWord.toLowerCase()) >= 0
            ) {
              proExistN++;
            }
          });
        }
      }
      if (proExistN == 1) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    } else {
      returnPro = 1;
    }

    if (returnPro === 1 && filter_Tag.length > 0) {
      let tagAvil = 0;

      tagList.map(function (item) {
        if (filter_Tag.indexOf(item.tag_id) >= 0) {
          tagAvil++;
        }
      });
      if (tagAvil > 0) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    }
    if (returnPro === 1 && filter_Brand_Tag.length > 0) {
      let tagAvil = 0;
      brandTagList.map(function (item) {
        if (filter_Brand_Tag.indexOf(item.pro_brand_tag_id) >= 0) {
          tagAvil++;
        }
      });
      if (tagAvil > 0) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    }
    return returnPro;
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === "" || avilablityIdTxt === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });

      location.href = baseUrl;
      return false;
    }

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });

      location.href = baseUrl;
      return false;
    }

    if (productDetail.product_type === "5") {
      /* if (
        cookie.load("orderDateTime") === "" ||
        typeof cookie.load("orderDateTime") === undefined ||
        typeof cookie.load("orderDateTime") === "undefined"
      ) {
        if (this.props.productState.pickup_outlet.length == 0) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else if (this.props.productState.pickup_outlet.length > 1) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else {
          this.props.sateValChange("openOutletTime", true);
        }
        return false;
      } */

      var IndexFlg = productDetail.product_primary_id;

      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });
      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });

      ///if (actionFlg === "initial") {
      //   $("#proIndex-" + IndexFlg).addClass("active");
      //   $("#proIndex-" + IndexFlg)
      //     .find(".smiple_product_lk")
      //     .hide();
      //   $("#proIndex-" + IndexFlg)
      //     .find(".addcart_done_maindiv")
      //     .show();
      //   return false;
      // } else {
      //     $.magnificPopup.open({
      //       items: {
      //         src: "#vouchergift-popup",
      //       },
      //       type: "inline",
      //     });
      //  this.setState({
      //   voucherProductDetail: productDetail,
      //   voucher_product_qty:proqtyQty,
      //   voucherIndexFlag:IndexFlg
      //  });

      //  return false;
      // }
    } else {
      if (
        cookie.load("orderDateTime") === "" ||
        typeof cookie.load("orderDateTime") === undefined ||
        typeof cookie.load("orderDateTime") === "undefined"
      ) {
        if (this.props.productState.pickup_outlet.length == 0) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else if (this.props.productState.pickup_outlet.length > 1) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else {
          this.props.sateValChange("openOutletTime", true);
        }
        return false;
      }

      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        return false;
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;
        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();

            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
          }
          return false;
        });
      }
    }
  }

  viewProDetail(productDetail, pro_cate_slug, pro_subcate_slug, event) {
    if (
      event !== "" &&
      typeof event !== "undefined" &&
      typeof event !== undefined
    ) {
      event.preventDefault();
    }

    var avilablityIdTxt = cookie.load("defaultAvilablityId");

    if (avilablityIdTxt === "" || avilablityIdTxt === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });

      location.href = baseUrl;
      return false;
    }
    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });

      location.href = baseUrl;
      return false;
    }

    var productSlug = productDetail.product_slug;

    if (productSlug !== "") {
      this.props.history.push(
        "/menu/" + pro_cate_slug + "/" + pro_subcate_slug + "/" + productSlug
      );
    }
    return false;
  }
  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.props.sateValChange("cartflg", value);
    }
    if (field === "closeprodetails" && value === "yes") {
      this.setState(
        { productDetailsHtml: "", viewProductSlug: "", proclose: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (field === "changenavslug" && value !== "") {
      var changeSlug = value.split("_");
      this.setState({ scrollProduct: changeSlug[1] });
      this.props.history.push("/menu/category/" + changeSlug[0]);
    }
  };

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }
  sortByPrice(sort, event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }

    var sort_By_PriceName = "";
    if (sort === "ASC") {
      sort_By_PriceName = "Price Low to High";
    } else {
      sort_By_PriceName = "Price High to Low";
    }
    this.setState({
      sort_By_Price: sort,
      sort_By_PriceName: sort_By_PriceName,
    });
    showLoader("productlist-main-div", "class");
    this.props.getProductList(
      this.state.catSlug,
      this.state.subcatSlug,
      "",
      "product_price",
      sort
    );
  }
  sortByCategories(categorySlug, categoryName, event) {
    event.preventDefault();

    var link = "/menu/category/" + categorySlug;
    this.props.history.push(link);

    showLoader("productlist-main-div", "class");
    this.setState(
      {
        sort_By_category: categorySlug,
        sort_By_categoryName: categoryName,
      },
      function () {
        this.props.sateValChange("categorySlug", categorySlug);
      }
    );
    var sortKey = "";
    var sortValue = "";
    if (this.state.sort_By_Price !== "") {
      sortKey = "product_price";
      sortValue = this.state.sort_By_Price;
    }
    this.props.getProductList(categorySlug, "", "", sortKey, sortValue);
  }

  showSortBy(type) {
    if (type === "price") {
      if (this.state.showShortByPrice === true) {
        this.setState({ showShortByPrice: false });
      } else {
        this.setState({ showShortByPrice: true, showShortByCategory: false });
      }
    } else if (type === "category") {
      if (this.state.showShortByCategory === true) {
        this.setState({ showShortByCategory: false });
      } else {
        this.setState({ showShortByCategory: true, showShortByPrice: false });
      }
    }
  }
  searchProKeyPress = (event) => {
    this.setState({ searchKeyWord: event.target.value }, function () {
      this.productsubcatlist();
    });
    if (event.target.value === "") {
      this.setState({ emptysearchKey: 1 });
    }
  };

  render() {
    return (
      <div className="productlist-main-div">
        <div className="mobile-pantry-search">
          <div className="input-sec">
            <input
              type="text"
              name="searchkey"
              className="productsearch"
              placeholder="Search..."
              onKeyUp={this.searchProKeyPress}
            />
            <a
              href="/"
              id="clearSearch"
              className="search_text_clear disbl_href_action"
            >
              X
            </a>
          </div>
        </div>
        <div className="product-filters">
          <div className="filters-lhs">
            <span>Sort By Price</span>
            <span
              className="filter-act"
              onClick={this.showSortBy.bind(this, "price")}
            >
              {this.state.sort_By_PriceName}
            </span>
            <ul
              className={this.state.showShortByPrice === true ? "active" : ""}
            >
              <li
                className={this.state.sort_By_Price === "ASC" ? "active" : ""}
              >
                <a href="#" onClick={this.sortByPrice.bind(this, "ASC")}>
                  Price Low to High
                </a>
              </li>
              <li
                className={this.state.sort_By_Price === "DESC" ? "active" : ""}
              >
                <a href="#" onClick={this.sortByPrice.bind(this, "DESC")}>
                  Price High to Low
                </a>
              </li>
            </ul>
          </div>

          <div className="filters-rhs">
            <span>Shop By Category</span>
            <span
              className="filter-act"
              onClick={this.showSortBy.bind(this, "category")}
            >
              {this.state.sort_By_categoryName}
            </span>
            {this.props.navigateMenu.length > 0 && (
              <ul
                className={
                  this.state.showShortByCategory === true ? "active" : ""
                }
              >
                {this.props.navigateMenu.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        this.state.sort_By_category === item.pro_cate_slug
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href="#"
                        onClick={this.sortByCategories.bind(
                          this,
                          item.pro_cate_slug,
                          item.menu_custom_title
                        )}
                      >
                        {item.menu_custom_title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        <div className="innerproduct_row">{this.state.productDetails}</div>
        <div
          className="no-product"
          id="no_product_error"
          style={{ display: "none" }}
        >
          <div className="container">
            <h2>No product found</h2>
            <p>
              Sorry, we can't find the type of products you are looking for. Try
              widening your search to see what’s available.
            </p>
          </div>
        </div>
        {/* <div className="product-load-more">
          <a href="#" className="">
            <span>LOAD</span> MORE
          </a>
        </div> */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (
      catslug,
      subcatslug,
      outletid,
      sortByField,
      sortByValue
    ) => {
      dispatch({
        type: GET_PRODUCT_LIST,
        catslug,
        subcatslug,
        outletid,
        sortByField,
        sortByValue,
      });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
