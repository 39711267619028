/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import OnlinePantry from "../../common/images/online.png";
import ThreeBarn from "../../common/images/three-barns.png";
import Philosopybanner from "../../common/images/our-philosophy.jpg";
import OurStoryOne from "../../common/images/our-story-two.jpg";
import OurStoryTwo from "../../common/images/our-story-one.jpg";
import Trust from "../../common/images/trust.png";
import QualityProduce from "../../common/images/quality-produce.png";
import VisitUs from "../../common/images/visit-us.png";
import { GET_STATIC_BLOCK } from "../../actions";

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".dvLoadrCls").hide();
    }, 2000);

    $(document).ready(function () {
      $(document).on("touchstart", ".carousel", function (event) {
        const xClick = event.originalEvent.touches[0].pageX;
        $(this).one("touchmove", function (event) {
          const xMove = event.originalEvent.touches[0].pageX;
          const sensitivityInPx = 5;

          if (Math.floor(xClick - xMove) > sensitivityInPx) {
            $(this).carousel("next");
          } else if (Math.floor(xClick - xMove) < -sensitivityInPx) {
            $(this).carousel("prev");
          }
        });
        $(this).on("touchend", function () {
          $(this).off("touchmove");
        });
      });
    });
  }

  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="outletList-main-div our-story-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Our Story</p>
          </div>
        </div>

        <div className="our-story-page">
          <div className="container-one cms-content">
            <div className="container">
              <section className="home-our-journey our-story-section">
                <div className="container">
                  <div className="hoj-inner our-story-inner our-story-first">
                    <div className="hoj-lhs">
                      <div className="our-story-head">
                        <div
                          id="myCarousel"
                          className="carousel slide our-story-slider our-story-slider-first"
                          data-interval="false"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#myCarousel"
                              data-slide-to="0"
                              className="active"
                            ></li>
                            <li
                              data-target="#myCarousel"
                              data-slide-to="1"
                            ></li>
                            <li
                              data-target="#myCarousel"
                              data-slide-to="2"
                            ></li>
                          </ol>

                          <div className="carousel-inner">
                            <div className="item active">
                              <img src={OurStoryOne} />
                            </div>
                            <div className="item">
                              <img src={OurStoryTwo} />
                            </div>
                          </div>

                          <div className="owl-nav">
                            <a
                              className="owl-prev"
                              href="#myCarousel"
                              data-slide="prev"
                            >
                              <span className="glyphicon glyphicon-chevron-left"></span>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="owl-next"
                              href="#myCarousel"
                              data-slide="next"
                            >
                              <span className="glyphicon glyphicon-chevron-right"></span>
                              <span className="sr-only">Next</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoj-rhs text-center our-story-rhs">
                      <h2>From farm-to-fork</h2>
                      <p>
                        Founded in 1998, Tigga Folks prides itself in providing
                        wholesome comfort food from sandwiches, and burgers to
                        pasta and pizzas. Made from the finest all natural
                        ingredients and products from all around the world.
                        Indulge in hearty meals and sip on fine Italian coffee
                        while enjoying the company of loved ones.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="our-philosopy-section">
                <img src={Philosopybanner} />
                <div className="our-philosopy-inner">
                  <div className="container common-top-div mt-mb-60">
                    <div className="our-philosopy-body">
                      <h4>Our Philosophy</h4>
                      <p>
                        {" "}
                        We are passionate in bringing you the finest,
                        all-natural ingredients and products from all over the
                        world with a focus on quality and affordability.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="home-our-journey our-story-section">
                <div className="container">
                  <div className="hoj-inner our-story-inner our-story-second">
                    <div className="hoj-lhs text-center our-story-second-lhs">
                      <h2>Bringing the world’s best to you</h2>
                      <p>
                        {" "}
                        Select from a large assortment of delicious treats at
                        our in-store Barn cupboard to accompany your meal.
                        Alternatively, shop for all-natural ingredients at our
                        online pantry and whip up a sumptuous meal at home. We
                        have carefully sourced and partnered with suppliers and
                        producers to bring you all the good stuff, from dairy
                        products, pasta, farina and meats.{" "}
                      </p>
                    </div>
                    <div className="hoj-rhs our-story-second-rhs">
                      <div className="our-story-head">
                        <div
                          id="myCarousel1"
                          className="carousel slide our-story-slider our-story-slider-second "
                          data-interval="false"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#myCarousel1"
                              data-slide-to="0"
                              className="active"
                            ></li>
                            <li
                              data-target="#myCarousel1"
                              data-slide-to="1"
                            ></li>
                            <li
                              data-target="#myCarousel1"
                              data-slide-to="2"
                            ></li>
                          </ol>

                          <div className="carousel-inner">
                            <div className="item active">
                              <img src={OurStoryOne} />
                            </div>
                            <div className="item">
                              <img src={OurStoryTwo} />
                            </div>
                          </div>

                          <div className="owl-nav">
                            <a
                              className="owl-prev"
                              href="#myCarousel1"
                              data-slide="prev"
                            >
                              <span className="glyphicon glyphicon-chevron-left"></span>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="owl-next"
                              href="#myCarousel1"
                              data-slide="next"
                            >
                              <span className="glyphicon glyphicon-chevron-right"></span>
                              <span className="sr-only">Next</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="home-order-now visit-us">
                <div class="container">
                  <h2 className="home-title">Visit Us</h2>
                  <p>
                    Our outlets are conveniently situated at 3 accessible
                    locations across Singapore. Drop us a visit to share a
                    hearty and wholesome meal with family and friends!
                  </p>
                  <ul class="desktop-home-order-now visit-us-inner">
                    <li>
                      <div class="">
                        <img src={VisitUs} />
                      </div>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="our-journey">
                <div className="container">
                  <div className="our-journey-inner">
                    <ul className="products-list-ulmain">
                      <li className="products-single-li">
                        <div className="products-image-div trust-li">
                          <img src={Trust} />
                        </div>
                        <div className="product-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>Food You Can Trust</h3>
                              <p>
                                We use natural and wholesome ingredients in all
                                our dishes.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="products-single-li">
                        <div className="products-image-div">
                          <img src={ThreeBarn} />
                        </div>
                        <div className="product-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>Our 3 Barns</h3>
                              <p>
                                Drop by and dine at any of our three cozy cafes.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="products-single-li">
                        <div className="products-image-div qlt-prod">
                          <img src={QualityProduce} />
                        </div>
                        <div className="product-info-div delivery-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>Quality Produce</h3>
                              <p>
                                Ingredients carefully sourced from all over the
                                world.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="products-single-li">
                        <div className="products-image-div onl-phantry">
                          <img src={OnlinePantry} />
                        </div>
                        <div className="product-info-div dine-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>Convenient Online Pantry</h3>
                              <p>
                                Shop online for groceries and we’ll deliver to
                                your doorstep.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return { staticblack: blacksArr };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

Aboutus.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Aboutus)
);
