/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

import {
  GET_CATERINGHISTORY,
  GET_ORDERRQUESTLIST,
  GET_CATERINPAYMENTDATA,
} from "../../actions";
import {
  appId,
  apiUrlV2,
  apiUrlCtrng,
  CountryTxt,
  stripeReference,
  stripeCurrency,
  stripeImage,
  stripeCompany,
} from "../Helpers/Config";
import {
  getTimeobject,
  showPriceValue,
  showPriceValueNew,
  stripslashes,
  addressFormat,
  timeToConv12,
  alphabet,
  numariconly,
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC,
  showLoader,
  hideLoader,
  showCustomAlert,
} from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import Moment from "moment";
import StripeCheckout from "react-stripe-checkout";

import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import paymentImg from "../../common/images/payment.png";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import scootyImg from "../../common/images/scooty.png";
import cartMpImg from "../../common/images/cart-map.png";
var qs = require("qs");
var base64 = require("base-64");
var Parser = require("html-react-parser");

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSettings: [],
      result_set: [],
      cartData: [],
      cartItems: [],
      final_total_amount: 0,
      cart_brktime_list: [],
      status: "loading",
      requestAmountArr: [],
      paymentList: [],
      requestAmount: 0,
      balanceAmount: 0,
      payment_error_txt: "",
      payment_action: "Yes",
      min_payment_inf: "No",
      paidAmount: 0,
      paydisable: true,
      validateimage: loadingImage,
      completingpaymentimage: loadingImage,
      payment_id: "",
      order_id: "",
      local_orderno: "",
      modalSwtich: false,
      omise_payment_enable: 0,
      omise_log_id: "",
      omisecodeerror: "",
      omiseyearerror: "",
      omisemontherror: "",
      omisecardrror: "",
      omisenameerror: "",
      omise_tokken_response: "",
      omise_tokken_card_id: "",
      omise_tokken_id: "",
      holdername: "",
      cardNumber: "",
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      cardImage: "",
      payment_ref_id: "",
    };

    var orderId =
      typeof this.props.match.params.orderId === "undefined"
        ? ""
        : this.props.match.params.orderId;
    var paymentId =
      typeof this.props.match.params.paymentId === "undefined"
        ? ""
        : this.props.match.params.paymentId;
    var cateringPay =
      typeof cookie.load("cateringPay") === "undefined"
        ? ""
        : cookie.load("cateringPay");
    var userIdTxt =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    if (cateringPay === "" && orderId !== "" && paymentId !== "") {
      cookie.save("orderIdTxt", orderId, { path: "/" });
      cookie.save("paymentIdTxt", paymentId, { path: "/" });
    } else if (cateringPay === "start") {
      orderId =
        typeof cookie.load("orderIdTxt") === "undefined"
          ? ""
          : cookie.load("orderIdTxt");
      paymentId =
        typeof cookie.load("paymentIdTxt") === "undefined"
          ? ""
          : cookie.load("paymentIdTxt");
    }

    if (orderId === "" || paymentId === "") {
      $(".invalid_order_error").trigger("click");
      this.props.history.push("/");
    }

    if (userIdTxt === "") {
      cookie.save("cateringPay", "start", { path: "/" });
      cookie.save("loginpopupTrigger", "Yes", { path: "/" });
      this.props.history.push("/");
    }

    if (userIdTxt !== "" && orderId !== "" && paymentId !== "") {
      var paymentId = base64.decode(paymentId);
      var orderId = base64.decode(orderId);
      var custmParm = "&order_id=" + orderId + "&customer_id=" + userIdTxt;
      this.props.getCateringPaymentHistory(custmParm);
      this.props.getOrderRequestlist(orderId, paymentId);
      this.props.getCateringPaymentDetails(orderId);
    }
  }

  componentWillReceiveProps(nxtProps) {
    var orderId =
      typeof this.props.match.params.orderId === "undefined"
        ? ""
        : this.props.match.params.orderId;
    var paymentId =
      typeof this.props.match.params.paymentId === "undefined"
        ? ""
        : this.props.match.params.paymentId;

    if (cookie.load("cateringPay") === "start") {
      orderId =
        typeof cookie.load("orderIdTxt") === "undefined"
          ? ""
          : cookie.load("orderIdTxt");
      paymentId =
        typeof cookie.load("paymentIdTxt") === "undefined"
          ? ""
          : cookie.load("paymentIdTxt");
    }

    if (this.state.globalSettings !== nxtProps.settingsArr) {
      let omisePayment,
        paymentmode = "";
      if (Object.keys(nxtProps.settingsArr).length > 0) {
        if (
          nxtProps.settingsArr.client_omise_enable == 1 &&
          nxtProps.settingsArr.client_omise_availability == 1
        ) {
          omisePayment = 1;
          if (paymentmode !== "Cash") {
            paymentmode = "Omise";
          }
        }
      }

      this.setState({
        globalSettings: nxtProps.settingsArr,
        omise_payment_enable: omisePayment,
        paymentmodevalue: paymentmode,
      });
    }

    if (
      this.state.cartData !== nxtProps.cateringOrderData ||
      this.state.requestAmountArr !== nxtProps.cateringRequestAmount ||
      this.state.paymentList !== nxtProps.cateringPaymentList
    ) {
      var paymentId = base64.decode(paymentId);
      var orderId = base64.decode(orderId);
      this.setState(
        {
          status: "ok",
          cartItems: nxtProps.cateringOrderItems,
          cartData: nxtProps.cateringOrderData,
          requestAmountArr: nxtProps.cateringRequestAmount,
          paymentList: nxtProps.cateringPaymentList,
          payment_id: paymentId,
          order_id: orderId,
        },
        function () {
          this.makeBrkTimeList();
        }.bind(this)
      );
    }

    if (nxtProps.cateringOrderStatus === "no") {
      $(".invalid_cateringorder_error").trigger("click");
      this.props.history.push("/");
    }

    if (nxtProps.cateringPayRequestStatus === "no") {
      $(".invalid_order_error").trigger("click");
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "https://cdn.omise.co/omise.js";
    // script.async = true;
    // document.body.appendChild(script);
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.cartData;
    var cartItems = this.state.cartItems;
    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      var breaktimeCount = cartDetailsSate.order_breaktime_count;
      var localOrderNo = cartDetailsSate.order_local_no;
      var grandTotalAmt = cartDetailsSate.order_total_amount;
      var balanceAmount = cartDetailsSate.order_total_amount;

      var paymentListArr = this.state.paymentList;
      if (Object.keys(paymentListArr).length > 0) {
        var temBlnc =
          Object.keys(paymentListArr.balanceamount).length > 0
            ? paymentListArr.balanceamount[0].balanceamount
            : 0;
        if (temBlnc !== "" && parseFloat(temBlnc) > 0) {
          balanceAmount = parseFloat(temBlnc);
        }
      }

      var paidAmount = this.state.paidAmount;
      if (parseFloat(grandTotalAmt) > parseFloat(balanceAmount)) {
        paidAmount = parseFloat(grandTotalAmt) - parseFloat(balanceAmount);
      }

      var requestAmountArr = this.state.requestAmountArr;
      var requestAmount = this.state.requestAmount;
      if (Object.keys(requestAmountArr).length > 0) {
        var tmpAmt = requestAmountArr.order_catering_request_amount;
        if (tmpAmt !== "" && parseFloat(tmpAmt) > 0) {
          requestAmount = parseFloat(tmpAmt);
        }
      }

      var payment_error_txt = "";
      var payment_action = this.state.payment_action;
      if (parseFloat(requestAmount) > parseFloat(balanceAmount)) {
        payment_error_txt =
          "Sorry!. You can not use this payment request, so please contact to administrator.";
        payment_action = "No";
      }

      var min_payment_inf = this.state.min_payment_inf;
      if (parseFloat(requestAmount) < 0.5) {
        min_payment_inf = "Yes";
      } else {
        min_payment_inf = "No";
      }

      this.setState({
        balanceAmount: balanceAmount,
        paidAmount: paidAmount,
        requestAmount: requestAmount,
        payment_error_txt: payment_error_txt,
        payment_action: payment_action,
        min_payment_inf: min_payment_inf,
        local_orderno: localOrderNo,
      });

      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = [];
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = [];
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].item_breaktime_indexflag,
              break_time_start: cartItems[key].item_breaktime_started,
              break_time_end: cartItems[key].item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });

    /*if(Object.keys(cartDetailsSate).length > 0 && Object.keys(cartItems).length > 0) {
		var localOrderNo = cartDetailsSate.order_local_no;
			var grandTotalAmt = cartDetailsSate.order_total_amount;
			var balanceAmount = cartDetailsSate.order_total_amount;
			
			var paymentListArr= this.state.paymentList;
			if(Object.keys(paymentListArr).length > 0) {
				var temBlnc = (Object.keys(paymentListArr.balanceamount).length > 0)?paymentListArr.balanceamount[0].balanceamount:0;
				if(temBlnc !== '' && parseFloat(temBlnc)>0) {
					balanceAmount = parseFloat(temBlnc);
				}
			}

			var paidAmount = this.state.paidAmount;
			if(parseFloat(grandTotalAmt) > parseFloat(balanceAmount)) {
				paidAmount = parseFloat(grandTotalAmt) - parseFloat(balanceAmount);
				
			}

			var requestAmountArr= this.state.requestAmountArr;
			var requestAmount= this.state.requestAmount;
			if(Object.keys(requestAmountArr).length > 0) {
				var tmpAmt = requestAmountArr.order_catering_request_amount;
				if(tmpAmt !== '' && parseFloat(tmpAmt)>0) {
					requestAmount = parseFloat(tmpAmt);
				}
			}
			
			var payment_error_txt = '';
			var payment_action = this.state.payment_action;
			if(parseFloat(requestAmount) > parseFloat(balanceAmount)) {
				payment_error_txt = 'Sorry!. You can not use this payment request, so please contact to administrator.';
				payment_action = 'No';
			}

			var min_payment_inf = this.state.min_payment_inf;
			if(parseFloat(requestAmount) < 0.50) {
				min_payment_inf = 'Yes';
			} else {
				min_payment_inf = 'No';
			}

			this.setState({balanceAmount: balanceAmount, paidAmount: paidAmount, requestAmount: requestAmount, payment_error_txt: payment_error_txt, payment_action: payment_action, min_payment_inf: min_payment_inf, local_orderno: localOrderNo});

			var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
			var breaktimeCount = cartDetailsSate.order_breaktime_count;
			if(breaktimeEnable === 'Yes' && parseInt(breaktimeCount)>0) {
				var tempKeyArr = Array();
				var tempKey = 0;
				for (var key in cartItems) {
					var chkKey = cartItems[key].item_breaktime_indexflag;
					if(!(chkKey in tempKeyArr)) {
						tempKeyArr[chkKey] = tempKey;
						var tempItemArr = Array();
						var tempItemLst = [];
						var intVl = tempKey + 1;
						var time_lable = "Break "+intVl;
						tempItemArr['cart_detaile'] = {
							'break_time_lable' 		: time_lable,
							'break_time_index' 		: cartItems[key].item_breaktime_indexflag,
							'break_time_start' 		: cartItems[key].item_breaktime_started,
							'break_time_end' 		: cartItems[key].item_breaktime_ended,
						};
						tempItemLst[0] = cartItems[key] 
						tempItemArr['cart_items'] = tempItemLst;
						cartBrkTmList[tempKey] = tempItemArr; 
						tempKey++;
					} else {
						
						var extKey = tempKeyArr[chkKey];
						
						cartBrkTmList[extKey]['cart_items'].push(cartItems[key]);
					}
				}
			}

			

		}*/
  }

  getCartDetList() {
    var cartDetailsSate = this.state.cartData;
    var cartBrktimeLst = this.state.cart_brktime_list;
    if (
      cartDetailsSate !== undefined &&
      Object.keys(cartDetailsSate).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      if (breaktimeEnable === "Yes" && Object.keys(cartBrktimeLst).length > 0) {
        return this.cartBrkTimeList();
      }
    }
  }

  handlerActTab(indx) {
    var ariaAxp = $("#headingTb" + indx)
      .find("a")
      .attr("aria-expanded");
    if (ariaAxp === "true") {
      $("#headingTb" + indx).addClass("act");
    } else {
      $("#headingTb" + indx).removeClass("act");
    }
    $(".panel-heading")
      .not($("#headingTb" + indx))
      .removeClass("act");
    $(".panel-collapse")
      .not($("#collapse" + indx))
      .removeClass("in");
  }

  cartListOnly() {
    var cartItems = this.state.cartItems;
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading act"
          role="tab"
          id="headingTb0"
          onClick={this.handlerActTab.bind(this, 0)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="true"
              href="#collapse0"
              className=""
            >
              Order Item Details
            </a>
          </h4>
        </div>
        <div
          id="collapse0"
          className="panel-collapse collapse mCustomScrollbar in"
          aria-expanded="true"
          href="#collapse0"
        >
          <div className="panel-body">{this.cartItemlist(cartItems)}</div>
        </div>
      </div>
    );
  }

  cartBrkTimeList() {
    var cartBrktimeLst = this.state.cart_brktime_list;
    var index = 0;
    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => {
      var count = indx + 1;
      return (
        <div>
          <div className="order-details-with-clear">
            <h5>Order Details {count}</h5>
          </div>
          <div className="order_card_body">
            <div className="event_timing">
              <div className="form-group">
                <div className="input_field">
                  <label>Event Start Time</label>
                  <div className="text_right">
                    {cartBrk["cart_detaile"].break_time_start}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input_field">
                  <label>Event End Time</label>
                  <div className="text_right">
                    {cartBrk["cart_detaile"].break_time_end}
                  </div>
                </div>
              </div>
            </div>

            <div className="products_info">
              {this.cartItemlist(cartBrk["cart_items"], index)}
            </div>
          </div>
        </div>
      );
      index++;
    });

    return cartBrktmhtml;
  }

  loadModifierItems(
    itemType,
    modifiers,
    combo,
    cart_item_qty,
    cart_item_unit_price,
    cart_item_id,
    cart_item_product_id,
    cart_item_min_qty
  ) {
    var len = modifiers.length;
    if (combo !== undefined) {
      var comboLen = combo.length;
    }
    var html =
      '<div class="products_info_options"><div class="products_info_options_wrap">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          modName +
          ":</b></p> <p>" +
          modval +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        var this_value = this;

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "</p> ";
        } else {
          html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "</p> ";
        }
      }
      html += "</div></div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["menu_product_qty"]) > 0) {
          var comboPro = combos[r]["menu_product_name"];
          var comboQty = combos[r]["menu_product_qty"];
          var comboPrice = combos[r]["menu_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
          if (combos[r].modifiers.length > 0) {
            html += this.showComboModifiersValues(combos[r]);
          }
        }
      }
      return html;
    }
    return "";
  }
  showComboModifiersValues(product_details) {
    var mod_length = product_details.modifiers.length;
    var html = " ";
    for (var m = 0, leng = mod_length; m < leng; m++) {
      var cart_mod_name = product_details.modifiers[m].order_modifier_name;
      var mod_val_leng = product_details.modifiers[m].modifiers_values.length;
      html +=
        "<div class='modifier_values_list_wrap'><p>" + cart_mod_name + "</p>";
      html += "<div class='modifier_values_list'>";
      for (var v = 0, lenth = mod_val_leng; v < lenth; v++) {
        var cart_modifier_name =
          product_details.modifiers[m].modifiers_values[v].order_modifier_name;
        var cart_modifier_price =
          product_details.modifiers[m].modifiers_values[v].order_modifier_price;
        html +=
          "<p>" +
          cart_modifier_name +
          "&nbsp;(" +
          cart_modifier_price +
          ")</p>";
      }

      html += "</div></div>";
    }

    return html;
  }

  cartItemlist(itemLst) {
    if (itemLst !== undefined && Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1} className="cart-package-info">
          {/* package div start */}
          <div className="products_info_item product-details">
            <div className="cart_left">
              <h4>{stripslashes(cartLst.item_name)}</h4>
              <div className="products_info_options">
                <div className="products_info_options_wrap">
                  {this.loadModifierItems(
                    "Component",
                    cartLst.modifiers,
                    cartLst.set_menu_component,
                    cartLst.item_qty,
                    cartLst.item_unit_price,
                    cartLst.item_id,
                    cartLst.item_product_id,
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="cart_right text-right">
              <span
                class="cart_indvl_price"
                data-carttotl={cartLst.cart_item_unit_price}
              >
                {showPriceValueNew(cartLst.item_total_amount)}
              </span>
            </div>
          </div>
        </div>
      ));
      return cartlsthtml;
    }
  }

  showModifiers(mdfLst) {
    if (Object.keys(mdfLst).length > 0) {
      const mdflsthtml = mdfLst.map((mdfLt, indx3) => (
        <div key={indx3} className="package-list">
          <p className="one">{mdfLt.order_modifier_name}</p>
          <p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
        </div>
      ));
      return mdflsthtml;
    }
  }

  showMdfValues(mdfValLst) {
    var mdfValueText = "";
    if (Object.keys(mdfValLst).length > 0) {
      var tempVl = Object.keys(mdfValLst).length - 1;
      for (var key in mdfValLst) {
        var cartmdfprice =
          parseFloat(mdfValLst[key].order_modifier_price) > 0
            ? " ( " + showPriceValue(mdfValLst[key].order_modifier_price) + " )"
            : "";
        mdfValueText +=
          parseInt(tempVl) === parseInt(key)
            ? stripslashes(mdfValLst[key].order_modifier_name) + cartmdfprice
            : stripslashes(mdfValLst[key].order_modifier_name) +
              cartmdfprice +
              ", ";
      }
    }
    return mdfValueText;
  }

  addonsSetupLst(cartLstDat) {
    var addonsSet = cartLstDat.addons_setup;
    const addonhtml = addonsSet.map((addonSt, indx4) => (
      <div key={indx4} className="row addon_list">
        <div className="col-sm-6 col-xs-9">
          <h5>
            <a href="javascript:void(0)">
              <span className="title">{addonSt.as_setup_title}</span>
            </a>
          </h5>
          {this.addonsSetupValues(addonSt.addons_setup_values)}
        </div>
        <div className="col-sm-6 col-xs-3">
          {/*<div className="amt">
										  <span>{showPriceValue(addonSt.as_setup_amount)}</span>
									</div>*/}
        </div>
      </div>
    ));
    return addonhtml;
  }

  addonsSetupValues(addonsVal) {
    if (Object.keys(addonsVal).length > 0) {
      const addonValhtml = addonsVal.map((addonVl, indx5) => (
        <p key={indx5}>
          {addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X{" "}
          {parseFloat(addonVl.asv_setup_val_price) > 0
            ? showPriceValue(addonVl.asv_setup_val_price)
            : ""}
          )
        </p>
      ));
      return addonValhtml;
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].os_setup_type;
      setupPrice += parseFloat(setupDt[key].os_setup_total_price);
    }

    return (
      <div className="buffet_setup">
        <div className="row">
          <div className="col-sm-6 col-xs-9">
            <h5>
              <a href="javascript:void(0)">
                <span className="title">{setupLbl} Setup</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-3">
            {/*<div className="amt">
							<span>{showPriceValue(setupPrice)}</span>
					</div>*/}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">{this.setupList(setupDt)}</div>
        </div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {setupVl.os_setup_name} : {setupVl.os_setup_description}{" "}
          {parseFloat(setupVl.os_setup_total_price) > 0
            ? " ( " + showPriceValue(setupVl.os_setup_total_price) + " )"
            : ""}
        </p>
      ));
      return setupDathtml;
    }
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.item_specification;

    return (
      <div className="buffet_setup" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a href="">
                <span className="title">Special Notes</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">
            <p>{special_not}</p>
          </div>
        </div>
      </div>
    );
  }

  cartHallData() {
    var cartDetailsMn = this.state.cartData;
    if (Object.keys(cartDetailsMn).length > 0) {
      if (cartDetailsMn.order_venue_type === "hall") {
        return (
          <div className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              id="headingTbH1"
              onClick={this.handlerActTab.bind(this, "H1")}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="false"
                  href="#collapseH1"
                  className="collapsed"
                >
                  Catering Hall Details
                </a>
              </h4>
            </div>
            <div
              id="collapseH1"
              className="panel-collapse fixed-height-cls collapse mCustomScrollbar"
              aria-expanded="false"
              href="#collapseH1"
            >
              <div className="panel-body">
                <div className="buffet_setup" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 col-xs-8">
                      <h5>
                        <a href="javascript:void(0)">
                          <span className="title">
                            {cartDetailsMn.order_hall_name}
                          </span>
                        </a>
                      </h5>
                    </div>
                    <div className="col-sm-6 col-xs-4">
                      <div className="amt">
                        <span>
                          {showPriceValue(cartDetailsMn.order_hall_charges)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  getCartTotalDet() {
    var cartDeta = this.state.cartData;
    if (Object.keys(cartDeta).length > 0) {
      var promotionApplied = cartDeta.order_discount_applied;
      var promotionAmount =
        cartDeta.order_discount_amount !== ""
          ? parseFloat(cartDeta.order_discount_amount)
          : 0;
      var paymentAction = this.state.payment_action;
      var minPaymentInf = this.state.min_payment_inf;
      return (
        <div className="cart_footer tnk_cart_footer">
          <div className="cart_row">
            <p className="text-uppercase">Subtotal</p>
            <span>{showPriceValueNew(cartDeta.order_sub_total)}</span>
          </div>
          {promotionApplied === "Yes" && (
            <div className="cart_row">
              <p className="text-uppercase">Discount Amount</p>
              <span>{showPriceValueNew(promotionAmount)}</span>
            </div>
          )}
          <div className="cart_row">
            <p className="text-uppercase">Total</p>
            <span>{showPriceValueNew(cartDeta.order_total_amount)}</span>
          </div>
          <div className="cart_row">
            <p className="text-uppercase">Paid Amount</p>
            <span>{showPriceValueNew(this.state.paidAmount)}</span>
          </div>
          <div className="cart_row">
            <p className="text-uppercase">Balance Amount</p>
            <span>{showPriceValueNew(this.state.balanceAmount)}</span>
          </div>
          <div className="cart_row grant-total-cls">
            <p className="text-uppercase">Payable Amount</p>
            <span>{showPriceValueNew(this.state.requestAmount)}</span>
          </div>
          {paymentAction === "Yes" && minPaymentInf === "Yes" ? (
            <div
              className="cart_row"
              style={{ fontSize: "12px", textAlign: "right", color: "red" }}
            >
              * Minimum Payable Amount : S$0.50
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }

  showUnitNumber(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  setActiveTab = (tabid) => {
    cookie.save("fromOtherPageFlg", tabid, { path: "/" });
    this.props.history.push("/");
  };

  closeAlrt() {
    $(".customized_pick_msgdiv").hide();
  }

  /* post stripe account */
  onToken = (token) => {
    window.$.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
    });

    /*  load process html */
    var postObject = {};
    postObject = {
      app_id: appId,
      token: token.id,
      stripe_envir: this.state.globalSettings.stripe_envir,
      stripe_key: this.state.globalSettings.stripe_public_key,
      customer_id: cookie.load("UserId"),
      paid_amount: this.state.requestAmount,
      outlet_name: "",
      payment_reference: stripeReference,
    };

    axios
      .post(apiUrlV2 + "payment/stripeTokenPay", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureData = res.data.result_set;
          if (Object.keys(captureData).length > 0) {
            this.setState({ validateimage: tickImage });
            this.makePayment(captureData, "Stripe");
          } else {
            window.$.magnificPopup.close();
            showCustomAlert("error", "Stripe response was empty.");
            $(".stripe_empty_error").trigger("click");
            //showCustomAlert('error', 'Stripe response was empty.');
          }
        } else if (res.data.status === "error") {
          window.$.magnificPopup.close();
          showCustomAlert(
            "error",
            "Sorry!, getting error response from stripe."
          );
          $(".payment_error").trigger("click");
        }
      });
  };

  /* show online payment mode loading */
  onlinePaymentLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.validateimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Validating card information.</p>
        </div>
      </div>
    );
  }
  /* show online payment mode loading */
  amountCaptureLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.completingpaymentimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Completing your online payment.</p>
        </div>
      </div>
    );
  }
  /* stripe description  */
  stripeDescription() {
    //userName
    if (
      typeof cookie.load("UserLname") !== "undefined" &&
      cookie.load("UserLname") !== ""
    ) {
      return cookie.load("UserFname") + " " + cookie.load("UserLname");
    } else {
      return cookie.load("UserLname");
    }
  }

  /* this fuction used to post order to biz panel */
  makePayment(captureData = "", payGetWayType = "") {
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      this.props.history.push("/");
      return false;
    }

    var paymentDetails = {};
    paymentDetails = {
      payment_type: "stripe",
      payment_status: "Success",
      payment_reference_1: captureData.payment_reference_1,
      payment_transaction_amount: captureData.payment_transaction_amount,
      payment_reference_2: captureData.payment_reference_2,
      payment_date: captureData.payment_date,
      payment_currency: captureData.payment_currency,
    };

    /*  load process html */
    var postObject = {};
    postObject = {
      app_id: appId,
      request_tableid: this.state.payment_id,
      order_id: this.state.order_id,
      payment_details: JSON.stringify(paymentDetails),
    };

    if (this.state.payment_id !== "" && this.state.order_id !== "") {
      axios
        .post(
          apiUrlV2 + "cateringorders/request_catering",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ placingorderimage: tickImage });
            this.deletePaymentCookie();
            const { history } = this.props;
            setTimeout(function () {
              window.$.magnificPopup.close();
              history.push("/payment/thankyou");
            }, 1000);
          } else if (res.data.status === "error") {
            showAlert("Error", res.data.message);
            $(".request_catering").trigger("click");
          }
        });
    } else {
      showAlert("Error", "Payment id was null.");
      $(".paymentIdnull").trigger("click");
    }
  }

  deletePaymentCookie() {
    var localOrderNo = this.state.local_orderno;
    var requestAmount = this.state.requestAmount;
    cookie.save("payOrderid", localOrderNo, { path: "/" });
    cookie.save("payAmount", requestAmount, { path: "/" });

    cookie.remove("cateringPay", { path: "/" });
    cookie.remove("orderIdTxt", { path: "/" });
    cookie.remove("paymentIdTxt", { path: "/" });
  }

  orderPayValidaion() {
    // $.magnificPopup.open({
    //   items: {
    //     src: "#pay-omiseconf-popup",
    //   },
    //   type: "inline",
    //   closeOnBgClick: false,
    // });

    $.magnificPopup.open({
      items: {
        src: "#pay-conf-popup",
      },
      type: "inline",
    });
  }

  inValidCard() {
    swal("Error!", "Sorry!. Invalid card details.", "error");
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/;
    if (item == "cardNumber") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value);
        var cardnumberLenght = cardnumber.length;
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber });
          this.cardValidation(cardnumber);
        }
      }
    } else if (
      item == "expiration_month" ||
      item == "expiration_year" ||
      item == "security_code"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
    } else {
      this.setState({ [item]: event.target.value });
    }

    this.setState({
      omisenameerror: "",
      omisecardrror: "",
      omisemontherror: "",
      omiseyearerror: "",
      omisecodeerror: "",
    });
  };

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  cardValidation(carnumber) {
    var imagename = "";
    if (carnumber != "") {
      var single = carnumber.substring(0, 1);
      var double = carnumber.substring(0, 2);

      if (single == 4) {
        imagename = "visa";
      } else if (double == 34 || double == 37) {
        imagename = "american";
      } else if (double >= 51 && double <= 55) {
        imagename = "master";
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = "discover";
      }
    }

    this.setState({ cardImage: imagename });
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.holdername == "") {
      errorFlg++;
      omisenameerror = "omise-form-error";
    }
    if (this.state.cardNumber == "") {
      errorFlg++;
      omisecardrror = "omise-form-error";
    }
    if (this.state.expiration_month == "") {
      errorFlg++;
      omisemontherror = "omise-form-error";
    }
    if (this.state.expiration_year == "") {
      errorFlg++;
      omiseyearerror = "omise-form-error";
    }
    if (this.state.security_code == "") {
      errorFlg++;
      omisecodeerror = "omise-form-error";
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror,
    });
    console.log(errorFlg, this.state.security_code, "errorFlg");

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");
      Omise.setPublicKey(this.state.globalSettings.omise_public_key);
      var current = this;
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false,
      };
      Omise.createToken("card", card, function (statusCode, response) {
        hideLoader("omise-pay-btn", "class");
        if (statusCode === 200) {
          if (
            response.object == "error" ||
            !response.card.security_code_check
          ) {
            var msgArr = Array();
            msgArr["message"] =
              response.object == "error"
                ? response.message
                : "Invalid card details.";
            current.showOmiselert(msgArr);
          } else {
            $.magnificPopup.close();
            /*current.onProcessOmiseToken(response);*/
            current.setState(
              {
                omise_tokken_response: response,
                omise_tokken_card_id: response.card.id,
                omise_tokken_id: response.id,
              },
              () => {
                current.onProcessOmiseToken(response);
              }
            );
          }
        } else {
          var msgArr = Array();
          msgArr["message"] =
            response.message !== ""
              ? response.message
              : "Sorry!. Error from omise.";
          current.showOmiselert(msgArr);
        }
      });
    } else {
      return false;
    }
  }

  showOmiselert(msgArr) {
    var dataTimeoutId = $(".omise-error-info-div").attr("data-timeoutid");
    clearTimeout(dataTimeoutId);
    $(".omise-error-info-div").hide();
    $(".omise-error-msg").html(msgArr["message"]);
    $("#omise-error-info-div").fadeIn();
    var timeoutid = setTimeout(function () {
      $(".omise-error-info-div").hide();
    }, 6000);
    $(".omise-error-info-div").attr("data-timeoutid", timeoutid);
  }

  /* post stripe account */
  onProcessOmiseToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var cartDeta = this.state.cartData;
    var calc_grand_total = parseFloat(cartDeta.order_total_amount);
    var orderDetail = this.state.cartData;
    calc_grand_total = calc_grand_total.toFixed(2);
    const current = this;
    /*  load process html */
    var postObject = {};
    postObject = {
      app_id: appId,
      token: token.id,
      customer_id: cookie.load("UserId"),
      paid_amount: this.state.requestAmount,
      outlet_name: orderDetail.outlet_name + "-" + orderDetail.order_local_no,
    };
    axios
      .post(apiUrlCtrng + "payment/omiseTokenPay", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.result_set.payment_reference_1;
          var omiseLogId = res.data.result_set.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.captureOmiseAmount(res.data.result_set, "Omise");
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          if (res.data.message == "token was already used") {
            this.omise_search_history();
          } else {
            $.magnificPopup.close();
            var errerTxt =
              res.data.message !== ""
                ? res.data.message
                : "Error code: 1001 Oops! Something went wrong! Please try again.";
            this.paymentFail("Error", errerTxt);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        } else {
          var currents = this;
          setTimeout(
            function () {
              currents.omise_search_history();
            }.bind(this),
            2000
          );
        }
      })
      .catch((error) => {
        var currentcatch = this;
        setTimeout(
          function () {
            currentcatch.omise_search_history();
          }.bind(this),
          2000
        );
      });
  };

  /* Omise Capture Payment */
  captureOmiseAmount(captureData) {
    var paymentDetails = {};
    paymentDetails = {
      payment_type: "omise",
      payment_status: "Success",
      payment_reference_1: captureData.payment_reference_1,
      payment_transaction_amount: captureData.payment_transaction_amount,
      payment_reference_2: captureData.payment_reference_2,
      payment_date: captureData.payment_date,
      payment_currency: captureData.payment_currency,
    };

    let payment_id = this.state.payment_id;

    var postObject = {};
    postObject = {
      app_id: appId,
      request_tableid: payment_id,
      order_id: this.state.order_id,
      payment_details: JSON.stringify(paymentDetails),
    };
    axios
      .post(
        apiUrlV2 + "cateringorders/request_catering",
        qs.stringify(postObject)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.setState({ placingorderimage: tickImage });
              this.deletePaymentCookie();
              const { history } = this.props;
              setTimeout(function () {
                $.magnificPopup.close();
                history.push("/catering/payment/thankyou/");
              }, 1000);
            }.bind(this)
          );
        } else if (captureRes.data.status === "pending") {
          this.retrieve_charge_details(
            captureData,
            localOrderNo,
            orderPrimaryId
          );
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : "Your order was not successful.";
          showAlert("Error", omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          /*Get Status of Charge ID*/
          this.retrieve_charge_details(
            captureData,
            localOrderNo,
            orderPrimaryId
          );
        }
      })
      .catch((error) => {
        this.captureOmiseAmount(captureData, orderPrimaryId, localOrderNo);
      });
  }

  omise_search_history = () => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      app_id: appId,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(apiUrl + "paymentv1/omise_search_details", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.captureID;
          var omiseLogId = res.data.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo);
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        }
      });
  };

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "paymentv1/retrieve_charge_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : "Your order was not successful.";
          showAlert("Error", msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };

  render() {
    const acceptedPayCard = ["visa", "mastercard", "jcb", "american express"];

    var orderDetail = this.state.cartData;
    var custAdrr =
      typeof orderDetail.order_customer_address_line1 !== "undefined"
        ? orderDetail.order_customer_address_line1
        : "";
    custAdrr = custAdrr.replace("Singapore", "");

    var stripePkKey =
      this.state.globalSettings !== undefined &&
      Object.keys(this.state.globalSettings).length > 0
        ? this.state.globalSettings.stripe_public_key
        : "";
    return (
      <>
        <Header />

        <div className="innersection_wrap tnk-you popup_orders">
          <div className="mainacc_toptext tick make-pay-div">
            <h2>Make Your Payment</h2>
            <h4>
              Requested Payment Amount -{" "}
              {showPriceValueNew(this.state.requestAmount)}
            </h4>
          </div>

          {/* order-detail-maindiv - start */}
          <div className="thank-order-detaildiv">
            <div className="tnk-detail text-center">
              <h2>YOUR ORDER DETAILS</h2>
              <div className="tnk-order order_number text_center ">
                <h3>Order No - {orderDetail.order_local_no}</h3>
                <p>
                  Order placed at :{" "}
                  {Moment(orderDetail.order_created_on).format(
                    "DD-MM-YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>
            <div className="tnk-delivery">
              <div className="delivery-cart-div">
                <div className="cart-direction">
                  <img className="cart-direction-left" src={scootyImg} />
                  <img className="cart-direction-right" src={cartMpImg} />
                </div>
                <div className="cart_row tnkorder-first">
                  <div className="order-hlhs text-left">
                    <h5>Order Handling By</h5>
                    <p>
                      {orderDetail.outlet_name !== "" &&
                        orderDetail.outlet_name !== undefined && (
                          <p>{stripslashes(orderDetail.outlet_name)}</p>
                        )}
                      {addressFormat(
                        orderDetail.outlet_unit_number1,
                        orderDetail.outlet_unit_number2,
                        orderDetail.outlet_address_line1,
                        orderDetail.outlet_address_line2,
                        orderDetail.outlet_postal_code
                      )}
                    </p>
                  </div>
                  <div className="order-hrhs text-right">
                    <h5>Catering Location</h5>
                    {orderDetail.order_hall_name !== "" ? (
                      <p>{stripslashes(orderDetail.order_hall_name)}</p>
                    ) : (
                      ""
                    )}
                    <p>{custAdrr}</p>

                    <p>
                      {this.showUnitNumber(
                        orderDetail.order_customer_billing_unit_no1,
                        orderDetail.order_customer_billing_unit_no2
                      )}{" "}
                      {orderDetail.order_customer_billing_postal_code}
                    </p>
                  </div>
                </div>
              </div>
              <div className="delivery_total delivery_total_number delivery_datetime_div">
                <div className="delivery_total_left">
                  <h2>Delivery Date</h2>
                  <h4 className="checkoutDate">
                    {Moment(orderDetail.order_date).format("DD/MM/YYYY")}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>Delivery Time</h2>
                  <h4 className="checkoutDate">
                    {Moment(orderDetail.order_date).format("hh:mm A")}
                  </h4>
                </div>
              </div>
              <div className="orderitem_body_div">
                <div className="overall-parent">{this.getCartDetList()}</div>
              </div>
              {this.getCartTotalDet()}
            </div>

            <div class="order-popup-inner">
              <div className="chk-payment mk-catering-payment">
                {/*<div className="form-group custom-tarms-div"></div>*/}

                <div className="chk-payment-row">
                  <span className="text-lbl">Payment Method</span>
                  <div className="chk-payment-col">
                    {/* <ul className="chk-payment-col-radio clear_ul">
                      <li>
                        <div className="radio_con">
                          <img src={paymentImg} />
                        </div>
                      </li>
                    </ul> */}
                    <ul className="chk-payment-col-radio">
                      <li>
                        <input
                          type="radio"
                          id="card"
                          name="payment-mode"
                          value="2"
                          defaultChecked
                        />
                        <label></label>
                        <div className="radio_con">
                          <img src={paymentImg} />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="tnk-chk-order">
                    {this.state.payment_action === "Yes" &&
                    stripePkKey !== "" ? (
                      <a
                        href="javascript:;"
                        onClick={this.orderPayValidaion.bind(this)}
                        className="button"
                        title="Continue"
                      >
                        Continue
                      </a>
                    ) : (
                      <a
                        className="button"
                        href="javascript:void(0);"
                        disabled={this.state.paydisable}
                      >
                        Continue{" "}
                      </a>
                    )}
                    {/* {this.state.payment_action === "Yes" ? (
                  <a
                    href="javascript:;"
                    onClick={this.orderPayValidaion.bind(this)}
                    className="button btn_green btn_lg btn_fjalla btn_big btn_upp btn_fill "
                    title="Continue"
                  >
                    Pay
                  </a>
                ) : (
                  <a
                    className="button btn_fill btn_md btn_upp btn_green"
                    href="javascript:void(0);"
                    disabled={this.state.paydisable}
                  >
                    Continue{" "}
                  </a>
                )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Payment confirm popup Start*/}
          <div
            id="pay-conf-popup"
            className="white-popup mfp-hide popup_sec pay-conf-popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header">Alert!</div>
                  <div className="alert_body">
                    <p>Awesome ! You are all set</p>
                    <div className="alt_btns">
                      {this.state.payment_action === "Yes" &&
                        stripePkKey !== "" && (
                          <StripeCheckout
                            name={stripeCompany}
                            image={stripeImage}
                            description={this.stripeDescription()}
                            token={this.onToken}
                            stripeKey={stripePkKey}
                            amount={this.state.requestAmount * 100}
                            email={cookie.load("UserEmail")}
                            currency={stripeCurrency}
                          >
                            {" "}
                            <a
                              href="javascript:;"
                              className="button"
                              title="Proceed To Payment"
                            >
                              Proceed To Payment
                            </a>
                          </StripeCheckout>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*payment confirm popup - End*/}

          {/*Payment omise confirm popup Start*/}
          <div
            id="pay-omiseconf-popup"
            className="white-popup mfp-hide popup_sec pay-omiseconf-popup"
          >
            <div
              className="omise-error-info-div"
              id="omise-error-info-div"
              data-timeoutid=""
              style={{ display: "none" }}
            >
              <div className="container">
                <span className="omise-error-icon">
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                  ></i>
                </span>
                <a
                  href="#"
                  className="omise_alert_close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={(evt) => {
                    evt.preventDefault();
                  }}
                >
                  {" "}
                  <span aria-hidden="true">×</span>{" "}
                </a>
                <p className="omise-error-msg">Something went wrong</p>
              </div>
            </div>

            {this.state.globalSettings.omise_envir === "dev" && (
              <div className="omisepay-mode-option">Test Mode</div>
            )}

            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="omise_pay_header">
                    <span className="omise-pay-img">
                      <img className="makisan-logo-img" src={stripeImage} />
                    </span>
                    <span className="omise-pay-title">Tigga Folks</span>
                  </div>
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="form_sec">
                        <div
                          className={"uname-box " + this.state.omisenameerror}
                        >
                          <i className="fa fa-user" data-unicode="f007"></i>
                          <input
                            type="text"
                            id="holdername"
                            className="form-control"
                            placeholder="Card Holder Name"
                            value={this.state.holdername}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "holdername"
                            )}
                          />
                        </div>

                        <div
                          className={
                            "card-numbr-div " + this.state.omisecardrror
                          }
                        >
                          <i
                            className="fa fa-credit-card"
                            data-unicode="f09d"
                          ></i>
                          <input
                            type="text"
                            className={"form-control " + this.state.cardImage}
                            placeholder="Card Number"
                            value={this.state.cardNumber}
                            id="omise_card_number"
                            value={this.state.cardNumber}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "cardNumber"
                            )}
                          />
                          <span className="card"></span>
                        </div>

                        <div className="form_expire_row">
                          <div className="expire_row_inner">
                            <div
                              className={
                                "expire_left " + this.state.omisemontherror
                              }
                            >
                              <i
                                className="fa fa-calendar-o"
                                data-unicode="f133"
                              ></i>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="2"
                                id="expiration_month"
                                placeholder="MM"
                                value={this.state.expiration_month}
                                onChange={this.handleChangeTxt.bind(
                                  this,
                                  "expiration_month"
                                )}
                              />
                            </div>

                            <div
                              className={
                                "expire_mdl " + this.state.omiseyearerror
                              }
                            >
                              <input
                                type="text"
                                placeholder="YY"
                                className="form-control"
                                maxLength="4"
                                id="expiration_year"
                                value={this.state.expiration_year}
                                onChange={this.handleChangeTxt.bind(
                                  this,
                                  "expiration_year"
                                )}
                              />
                            </div>

                            <div
                              className={
                                "expire_right " + this.state.omisecodeerror
                              }
                            >
                              <i className="fa fa-lock" data-unicode="f023"></i>
                              <input
                                type="password"
                                maxLength="3"
                                className="form-control"
                                id="security_code"
                                placeholder="CVV"
                                value={this.state.security_code}
                                onChange={this.handleChangeTxt.bind(
                                  this,
                                  "security_code"
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="alt_btns">
                          {this.state.globalSettings.omise_public_key &&
                            this.state.paymentmodevalue == "Omise" &&
                            this.state.omise_payment_enable === 1 && (
                              <button
                                onClick={this.getOmiseToken.bind(this)}
                                className="button omise-pay-btn"
                              >
                                Pay {stripeCurrency}{" "}
                                {parseFloat(this.state.requestAmount).toFixed(
                                  2
                                )}
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*payment omise confirm popup - End*/}

          {/* popup */}
          <div
            id="processing-popup"
            className="white-popup mfp-hide popup_sec processing"
          >
            <div className="pouup_in">
              <h3 className="title1 text-center">
                We Are Processing Your Order
              </h3>
              <div className="process_inner">
                {this.onlinePaymentLoading()}
                {/* this.orderBCLoading() */}
                {this.amountCaptureLoading()}
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var resultSetArr = Array();
  var orderStatus = "";
  var orderData = Array();
  var orderItems = Array();
  if (
    state.cateringhistory !== undefined &&
    Object.keys(state.cateringhistory).length > 0
  ) {
    var resultSetArr = !("result_set" in state.cateringhistory[0])
      ? Array()
      : state.cateringhistory[0].result_set;
    if (
      state.cateringhistory[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      orderData = resultSetArr[0];
      orderItems = resultSetArr[0]["items"];
      orderStatus = "yes";
    } else {
      orderStatus = "no";
    }
  }

  var requestAmountArr = Array();
  var requestStatus = "";

  if (
    state.orderrequestlist !== undefined &&
    Object.keys(state.orderrequestlist).length > 0
  ) {
    var requestAmountArr = !("result_set" in state.orderrequestlist[0])
      ? Array()
      : state.orderrequestlist[0].result_set;
    if (
      state.orderrequestlist[0].status === "ok" &&
      Object.keys(requestAmountArr).length > 0
    ) {
      requestStatus = "yes";
    } else {
      requestStatus = "no";
    }
  }

  var paymentLstArr = Array();
  if (
    state.paymentlist !== undefined &&
    Object.keys(state.paymentlist).length > 0
  ) {
    if (state.paymentlist[0].status === "ok") {
      paymentLstArr = state.paymentlist[0].result_set;
    }
  }

  var globalSettings = Array();
  if (state.settings !== undefined && Object.keys(state.settings).length > 0) {
    if (state.settings[0].status == "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  return {
    cateringOrderData: orderData,
    cateringOrderItems: orderItems,
    cateringOrderStatus: orderStatus,
    cateringRequestAmount: requestAmountArr,
    cateringPayRequestStatus: requestStatus,
    cateringPaymentList: paymentLstArr,
    settingsArr: globalSettings,
    socialsettingData: state.socialsettingData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCateringPaymentHistory: (historyparams) => {
      dispatch({ type: GET_CATERINGHISTORY, historyparams });
    },
    getOrderRequestlist: (orderId, paymentId) => {
      dispatch({ type: GET_ORDERRQUESTLIST, orderId, paymentId });
    },
    getCateringPaymentDetails: (orderId) => {
      dispatch({ type: GET_CATERINPAYMENTDATA, orderId });
    },
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Payment));
